import { Resources } from "./resources.ts";
import { Simulation } from "./simulation.ts";
import { InteractiveCanvas } from "./audio_visual/interactive_canvas.ts";
import { WebBrowserClient } from "./webBrowserClient.ts";
import { AuthorInterface } from "./sceneAuthorInterface/authorInterface.ts";
import { NullWebAudio } from "./audio_visual/audio/NullWebAudio.ts";
import { ExternalModules } from "./modules/ExternalModules.ts";
import { InternalModules } from "./modules/internalModules.ts";
import { SceneModules } from "./modules/sceneModules.ts";
import { InternalAuth } from "./auth/InternalAuth.ts";
import { StaticRouter } from "./routing/StaticRouter.ts";
import { WebApplication } from "./webApplication.ts";
import { VisualElements } from "./audio_visual/visual/VisualElements.ts";
import { WebApplicationServer } from "./webApplicationServer.ts";
import { WebApplicationState } from "./WebApplicationState.ts";
import { MeidaSourcePlayerComponent } from "./audio_visual/visual/MediaSourcePlayerComponent.ts";
import { WebAudioComponent } from "./audio_visual/audio/WebAudioComponent.ts";
import { NullWebAudioComponent } from "./audio_visual/audio/NullWebAudioComponent.ts";
import { setPlaylistConfig } from "./audio_visual/audio/playlistAPI/Radio";
import { RemoteHandler } from "./remote/RemoteHandler";

let versionNumber = "0.4.1";

export async function startClient(
    state: WebApplicationState,
    newWebApplication: (withState: WebApplicationState) => WebApplication,
    newServer: (withState: WebApplicationState) => WebApplicationServer,
    hasExternalModules = true,
    hasAuth = true,
) {
    state.libraryVersionNumber = versionNumber;

    state.application = newWebApplication(state);
    state.application.initialize();

    state.resources = new Resources(state.application);
    await state.resources.initialize();
    state.application.resources = state.resources;

    state.server = newServer(state);
    await state.server.initialize();

    state.browser = new WebBrowserClient(state.resources, state.application, state.server);
    state.browser.initialize();
    state.server.setBrowser(state.browser);

    state.application.setServer(state.server);

    state.account = await state.server.getCustomerAccount();
    state.account.initialize();
    state.server.setClientCustomerAccount(state.account);

    state.canvas = new InteractiveCanvas(state);
    state.canvas.initialize();
    state.application.platform_canvas = state.canvas.platformCanvas;

    state.author = new AuthorInterface(state.resources, state.canvas, state.account);
    state.author.initialize();

    if (
        state.application.settings.getSetting(WebApplication.IsMSVideoEnabledSettingName) &&
        MeidaSourcePlayerComponent.isSupportedByPlatform()
    ) {
        state.msePlayer = new MeidaSourcePlayerComponent(state);
        await state.msePlayer.initialize();
        state.canvas.addComponent(state.msePlayer);
    }

    /* Initialize Audio */
    await setPlaylistConfig();
    let hasAudio = state.application.getSetting(WebApplication.IsAudioEnabledSettingName);
    state.audio = hasAudio
        ? new WebAudioComponent(state.resources, state.account)
        : new NullWebAudioComponent();
    state.audio.initialize();
    state.canvas.addComponent(state.audio);

    VisualElements.initializeResourceTypes(state.application);

    state.simulation = new Simulation(
        state.resources,
        state.canvas,
        state.account,
        state.server,
        state.audio,
        state,
    );
    await state.simulation.initialize();
    state.canvas.addComponent(state.simulation);

    if (hasExternalModules) {
        state.externalModules = new ExternalModules(
            state,
            state.resources,
            state.canvas,
            state.account,
            state.server,
            state.audio.player,
        );
        state.application.initializeExternalModules(state);
        state.externalModules.initialize();
        state.internalModules = new InternalModules(state.externalModules);
        // state.application.initializeInternalModules(state);
        state.internalModules.initialize();
    }
    state.sceneModules = new SceneModules();
    state.application.initializeSceneModules(state);
    state.sceneModules.initialize();

    state.author.components.push(state.browser);
    state.author.components.push(state.application);
    state.author.components.push(state.audio);
    state.author.components.push(state.account);
    //state.author.components.push(state.simulation);
    state.author.components.push(state.server);

    if (hasExternalModules) {
        state.author.components.push(state.externalModules);
        //state.author.components.push(state.internalModules);
        state.externalModules.authoringInterface = state.author;
        state.internalModules.authoringInterface = state.author;
    }

    //state.author.components.push(state.sceneModules);
    state.sceneModules.authoringInterface = state.author;

    state.canvas.addComponent(state.author);
    state.author.diagnostics_overlay.set_profile(state.canvas.platformCanvas.name);
    state.author.diagnostics_overlay.set_brand(state.canvas.platformCanvas.brand);

    hasAuth = hasAuth && state.application.getSetting(WebApplication.IsAuthEnabledSettingName);
    state.auth = new InternalAuth(state);
    await state.auth.initialize();

    state.router = new StaticRouter(state);
    state.router.initialize();

    state.remoteHandler = new RemoteHandler(state);

    document.addEventListener("visibilitychange", () => {
        if (document.visibilityState === "hidden") {
            state.saveState();
        }
    });

    await state.canvas.start();
    return;
}
