import { VideoFileToElement } from "./VideoFileToElement.ts";

export class VideoDisplayToElement {
    video_file_to_element: VideoFileToElement;

    constructor(video_file_to_element: VideoFileToElement) {
        this.video_file_to_element = video_file_to_element;
    }
    get element() {
        return /** @type {HTMLMediaElement} */ this.video_file_to_element.element;
    }

    get icanvas() {
        return this.video_file_to_element.icanvas;
    }

    get resource() {
        return this.video_file_to_element.resource;
    }

    addedToInteractiveCanvas() {}

    removedFromInteractiveCanvas() {}

    draw() {}

    onCanvasResized() {}

    validate() {}
}
