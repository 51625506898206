import { Stem } from "../../audio_visual/audio/stem";
import { WebAudio } from "../../audio_visual/audio/webAudio";
import { Action } from "../actions/action";
import { Scene } from "../scene";
import { SceneObjectInterface, SceneObjectInterfaceExtensions } from "../SceneObjectInterface";

export class PlayAudioAction extends Action {
    value;
    value_context;

    constructor(verb: string, name: string | undefined, json?: any) {
        super(verb, name);
        this.value = json["value"];
        this.value_context = json["value_context"];
    }

    newSoundEffectStem(obj: Scene, json: any) {
        if (json.isLoop === undefined) {
            json.isLoop = false;
        }

        if (json.layer === undefined) {
            json.layer = WebAudio.effectslayerName;
        }

        if (json.isListenerAreaLocal === undefined) {
            json.isListenerAreaLocal = false;
        }

        return new Stem(json, obj.getResourcePathFunction());
    }

    run(interactive: SceneObjectInterface | undefined) {
        let stems = [];
        const scene = SceneObjectInterfaceExtensions.sceneAsScene(interactive);
        if (!scene) return;

        if (Array.isArray(this.value)) {
            this.value.forEach((element) => {
                stems.push(this.newSoundEffectStem(scene, element));
            });
        } else {
            stems.push(this.newSoundEffectStem(scene, this.value));
        }

        scene.simulation.audio.player.playSoundEffects(stems);
        scene.onPlaySoundEffects(stems);
    }
}
