import { InteractiveEvent } from "./sceneGraph/InteractiveEvent.ts";

export class InteractiveMouseEvent extends InteractiveEvent {
    e: MouseEvent;
    x: number;
    y: number;

    constructor(e: MouseEvent) {
        super();
        this.e = e;
        this.x = e.offsetX;
        this.y = e.offsetY;
    }
}
