import { InteractivePlatformCanvas } from "./InteractivePlatformCanvas.ts";

import { InteractiveCanvas } from "./interactive_canvas.ts";

export class InteractivePCCanvas extends InteractivePlatformCanvas {
    static isPC() {
        return true;
    }
    constructor() {
        super();
    }

    isPlatform() {
        return InteractivePCCanvas.isPC();
    }

    isTouchCapable() {
        return super.isTouchCapable();
    }

    uninitialize() {}

    initialize(interactiveCanvas: InteractiveCanvas) {
        super.initialize(interactiveCanvas);
        this.name = "PC";
    }

    configure_visual_elements() {}
    apply_to_default_settings() {
        super.apply_to_default_settings();
        // this.interactiveCanvas.state.application.setDefaultSetting(WebApplication.isShowConsoleLogOverlaySettingName, false);
        //this.interactiveCanvas.state.application.setDefaultSetting(WebApplication.isShowMemoryDiagnosticsOverlaySettingName, false);
        // this.interactiveCanvas.state.application.setDefaultSetting(WebApplication.IsSBVideoEnabledSettingName, false);
        // this.interactiveCanvas.state.application.setDefaultSetting(WebApplication.IsPSVideoEnabledSettingName, true);
    }
}
