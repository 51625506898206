import { DownloadDiagnostics } from "./DownloadDiagnostics";
import { DebugOverlay } from "./sceneAuthorInterface/DebugOverlay";

interface DownloadElement {
    [key: string]: HTMLParagraphElement;
}

interface ElementMap {
    [key: string]: HTMLParagraphElement;
}
declare global {
    interface Navigator {
        deviceMemory?: number;
    }
    interface PerformanceMemory {
        jsHeapSizeLimit: number;
        totalJSHeapSize: number;
        usedJSHeapSize: number;
    }
    interface Performance {
        memory?: PerformanceMemory;
    }
}

export class MemoryDiagnostics {
    item_set_count = 0;
    item_count = 0;
    total_bytes_downloaded: number;
    elements: ElementMap = {};
    memory_enabled = false;
    diagnostics_container: HTMLElement | null = null;
    application: any;
    downloads: DownloadDiagnostics[];
    maxDownloadHistory: number;
    downloadElements: DownloadElement;

    constructor(application: any) {
        this.application = application;
        this.downloads = [];
        this.maxDownloadHistory = 8;
        this.downloadElements = {};
        this.total_bytes_downloaded = 0;
    }

    setupElements(application: any) {
        this.memory_enabled = true;

        this.diagnostics_container = document.getElementById(
            DebugOverlay.diagnostics_container_name,
        );

        if (this.diagnostics_container === undefined) {
            return;
        }

        let element = document.createElement("p");
        element.style.margin = "1px";
        element.classList.add("diagnostics-item");
        this.diagnostics_container?.appendChild(element);
        this.elements["device_memory"] = element;

        element = document.createElement("p");
        element.style.margin = "1px";
        element.classList.add("diagnostics-item");
        this.diagnostics_container?.appendChild(element);
        this.elements["application_memory"] = element;
    }

    cleanupElements(application: any) {
        this.memory_enabled = false;

        Object.keys(this.elements).forEach((key) => {
            const value = this.elements[key];
            this.diagnostics_container?.removeChild(value);
        });

        this.diagnostics_container = null;
    }

    update() {
        if (!this.memory_enabled) {
            return;
        }
        this.drawDeviceDiagnostics();
        this.drawBrowserDiagnostics();
    }

    drawDeviceDiagnostics() {
        const element = this.elements["device_memory"];

        if (!element) {
            return;
        }

        let memory = "-1";
        let browserMemory_gb = "-1";

        try {
            if (performance.memory) {
                memory = (navigator.deviceMemory || -1).toFixed(1);
                browserMemory_gb = (
                    performance.memory.jsHeapSizeLimit /
                    (1024 * 1024 * 1024)
                ).toFixed(1);
            }
        } catch (error) {
            console.warn(error);
        }

        const system_message = `Device Memory: ${browserMemory_gb}GiB of ${memory}GiB total`;

        element.textContent = system_message;
    }

    drawBrowserDiagnostics() {
        const element = this.elements["application_memory"];

        if (!element) {
            return;
        }
        let browserMemory = {
            jsHeapSizeLimit: -1,
            totalJSHeapSize: -1,
            usedJSHeapSize: -1,
        };

        try {
            if (performance.memory) {
                browserMemory.jsHeapSizeLimit = performance.memory.jsHeapSizeLimit * 0.000001;
                browserMemory.totalJSHeapSize = performance.memory.totalJSHeapSize * 0.000001;
                browserMemory.usedJSHeapSize = performance.memory.usedJSHeapSize * 0.000001;
            }
        } catch (error) {
            console.warn(error);
        }

        const browser_message = `Heap Memory: ${browserMemory.usedJSHeapSize.toFixed(3)}MB / ${browserMemory.totalJSHeapSize.toFixed(3)}MB`;

        element.textContent = browser_message;
    }

    increment_item_set() {
        this.item_set_count += 1;
        this.item_count = 0;
    }

    increment_item() {
        this.item_count += 1;
    }

    get kilobytes_downloaded() {
        return this.total_bytes_downloaded / 1024;
    }

    get megabytes_downloaded() {
        return this.total_bytes_downloaded / (1024 * 1024);
    }

    on_download_changed() {
        const downloadsContainer = document.getElementById("downloads-container");
        if (!downloadsContainer) {
            return;
        }
        let totalDownloadElement = this.downloadElements["total"];
        if (!totalDownloadElement) {
            totalDownloadElement = document.createElement("p");
            totalDownloadElement.style.margin = "1px";
            totalDownloadElement.classList.add("downloads-item");
            downloadsContainer.appendChild(totalDownloadElement);
            this.downloadElements["total"] = totalDownloadElement;
        }
        totalDownloadElement.textContent = `Download Total: ${parseFloat(this.kilobytes_downloaded.toFixed(2)).toLocaleString()} KB`;

        const existingDownloadNames = new Set();

        this.downloads.forEach((download, index) => {
            existingDownloadNames.add(download.name);

            let downloadElement = this.downloadElements[download.name];
            if (!downloadElement) {
                downloadElement = document.createElement("p");
                downloadElement.style.margin = "1px";
                downloadElement.classList.add("downloads-item");
                if (index === 0) {
                    downloadsContainer.insertBefore(downloadElement, downloadsContainer.firstChild);
                } else {
                    const previousDownload = this.downloads[index - 1];
                    const previousElement = this.downloadElements[previousDownload.name];
                    downloadsContainer.insertBefore(downloadElement, previousElement.nextSibling);
                }
                this.downloadElements[download.name] = downloadElement;
            }

            let downloadText = `${download.item_set_count}-${download.item_count} ${download.type} - ${download.name}: ${parseFloat(download.kilobytes.toFixed(2)).toLocaleString()} KB`;

            if (download.totalBytes > 0) {
                downloadText += ` / ${parseFloat(download.kilobytes.toFixed(2)).toLocaleString()} KB`;
            }

            if (download.finished) {
                downloadText += " - Finished";
            } else if (download.started) {
                downloadText += " - In Progress";
            }

            downloadElement.textContent = downloadText;
        });

        downloadsContainer.prepend(totalDownloadElement);
        for (const downloadName in this.downloadElements) {
            if (downloadName !== "total" && !this.downloads.find((d) => d.name === downloadName)) {
                downloadsContainer.removeChild(this.downloadElements[downloadName]);
                delete this.downloadElements[downloadName];
            }
        }
    }

    start_download(
        type: string,
        resource_request: any,
        mediaElement: HTMLElement,
        startTime: number = Date.now(),
    ) {
        if (this.downloads.length >= this.maxDownloadHistory) {
            const oldestDownload = this.downloads.pop();
            oldestDownload?.removeEventHandlers();
        }

        const download = new DownloadDiagnostics(type, resource_request, startTime, this);
        download.setupEventHandlers(mediaElement as HTMLMediaElement);
        download.item_set_count = this.item_set_count;
        download.item_count = this.item_count;
        this.item_count += 1;
        this.downloads.unshift(download);
        return download;
    }

    start_fetch_download(
        type: string,
        resource_request: any,
        total_size_bytes: number,
        startTime: number = Date.now(),
    ) {
        if (this.downloads.length >= this.maxDownloadHistory) {
            const oldestDownload = this.downloads.pop();
            oldestDownload?.removeEventHandlers();
        }

        const download = new DownloadDiagnostics(type, resource_request, startTime, this);
        download.totalBytes = total_size_bytes;
        download.item_set_count = this.item_set_count;
        download.item_count = this.item_count;
        this.item_count += 1;
        this.downloads.unshift(download);

        download.handleLoadStart();
        return download;
    }
}
