import { v4 as uuidv4 } from "uuid";
import { UserInterfaceElement } from "./UserInterfaceElement.ts";

export class TextboxUserInterfaceElement extends UserInterfaceElement {
    content: any;
    element: HTMLInputElement;

    constructor(elements: any, json: any) {
        super(elements, json);
    }

    addToDOM(): void {
        const id = uuidv4();

        this.element = Object.assign(document.createElement("input"), {
            id: id,
        }) as HTMLInputElement;

        if (this.json.margin) {
            this.element.style.margin = this.json.margin;
        }
        this.elements.div.appendChild(this.element);
    }

    value_string(): string {
        return this.element.value;
    }
}
