export class DownloadDiagnostics {
    type: string;
    name: string;
    resource_request: any;
    memoryDiagnostics: any;
    bytes: number = 0;
    totalBytes: number = 0;
    startTime: number;
    finished: boolean = false;
    started: boolean = false;
    isError: boolean = false;
    mediaElement?: HTMLMediaElement;
    item_set_count: number = 0;
    item_count: number = 0;

    constructor(type: string, resource_request: any, startTime: number, memoryDiagnostics: any) {
        this.type = type;
        this.name = resource_request.name;
        this.resource_request = resource_request;
        this.memoryDiagnostics = memoryDiagnostics;
        this.startTime = startTime;
        this.handleLoadStart = this.handleLoadStart.bind(this);
        this.handleProgress = this.handleProgress.bind(this);
        this.handleLoadedData = this.handleLoadedData.bind(this);
        this.handleLoadedDataWithSize = this.handleLoadedDataWithSize.bind(this);
    }

    get kilobytes(): number {
        return this.bytes / 1024;
    }

    get megabytes(): number {
        return this.bytes / (1024 * 1024);
    }

    setupEventHandlers(mediaElement: HTMLMediaElement): void {
        this.mediaElement = mediaElement;
        this.handleLoadStart();
        const url = this.resource_request.toUrlPath();
        const file_info = this.memoryDiagnostics.application.server.server_file_cache.findFile(url);
        this.totalBytes = file_info ? file_info.size : 0;

        if (mediaElement instanceof HTMLImageElement) {
            if (mediaElement.complete) {
                this.handleLoadedDataWithSize();
            } else {
                mediaElement.addEventListener("load", this.handleLoadedDataWithSize);
            }
        } else if (
            mediaElement instanceof HTMLVideoElement ||
            mediaElement instanceof HTMLAudioElement
        ) {
            if (mediaElement.readyState > 0) {
                this.update_stream_bytes();
            } else {
                mediaElement.addEventListener("loadstart", this.handleLoadStart);
            }
            mediaElement.addEventListener("progress", this.handleProgress);
            mediaElement.addEventListener("loadeddata", this.handleLoadedData);
        }
    }

    update_stream_bytes(): void {
        if (
            this.mediaElement instanceof HTMLVideoElement ||
            this.mediaElement instanceof HTMLAudioElement
        ) {
            if (this.mediaElement.buffered.length > 0) {
                const bufferedTime = this.mediaElement.buffered.end(0);
                const progress = bufferedTime / this.mediaElement.duration;
                const previous = this.bytes;
                this.bytes = Math.round(progress * this.totalBytes);
                this.memoryDiagnostics.total_bytes_downloaded += this.bytes - previous;
            }
        }
    }

    handleLoadStart(): void {
        this.started = true;
        this.memoryDiagnostics.on_download_changed();
    }

    removeEventHandlers(): void {
        if (this.mediaElement) {
            if (this.mediaElement instanceof HTMLImageElement) {
                this.mediaElement.removeEventListener("load", this.handleLoadedDataWithSize);
            } else if (
                this.mediaElement instanceof HTMLVideoElement ||
                this.mediaElement instanceof HTMLAudioElement
            ) {
                this.mediaElement.removeEventListener("loadstart", this.handleLoadStart);
                this.mediaElement.removeEventListener("progress", this.handleProgress);
                this.mediaElement.removeEventListener("loadeddata", this.handleLoadedData);
            }
            this.mediaElement = undefined;
        }
    }

    handleProgress(): void {
        this.update_stream_bytes();
        this.memoryDiagnostics.on_download_changed();
    }

    handleDataError(): void {
        this.isError = true;
        this.memoryDiagnostics.on_download_changed();
    }

    handleFetchedData(bytes_downloaded: number): void {
        this.finished = true;
        this.bytes = bytes_downloaded;
        this.memoryDiagnostics.total_bytes_downloaded += bytes_downloaded;
        this.memoryDiagnostics.on_download_changed();
    }

    handleLoadedData(): void {
        this.finished = true;
        this.update_stream_bytes();
        this.memoryDiagnostics.on_download_changed();
    }

    handleLoadedDataWithSize(): void {
        this.finished = true;
        this.bytes = this.totalBytes;
        this.memoryDiagnostics.total_bytes_downloaded += this.bytes;
        this.memoryDiagnostics.on_download_changed();
    }
}
