export class VideoData {
    static newFromJson(json: any) {
        let result = new VideoData();
        result.event_playedOnce = json["video.event.playedOnce"];
        if (json["video.loop"] !== undefined) {
            result.isLoop = json["video.loop"];
        }
        if (json["video.muted"] !== undefined) {
            result.isMuted = json["video.muted"];
        }
        if (json["video.autoplay"] !== undefined) {
            result.isAutoPlay = json["video.autoplay"];
        }
        if (json["video.play_immediate"] !== undefined) {
            result.isPlayImmediate = json["video.play_immediate"];
        }
        return result;
    }
    video: ArrayBuffer;
    name?: string;
    event_playedOnce: any;
    isLoop = true;
    isMuted = false;
    isAutoPlay = true;
    isPlayImmediate = true;
    onPlayedOnce: (() => void) | undefined;

    clone(): VideoData {
        return Object.create(this);
    }
}
