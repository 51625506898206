//https://sinclairdigital.atlassian.net/wiki/spaces/DL/pages/574390466/UX+Data+Loading+-+For+TV

import { InteractiveCanvas } from "../audio_visual/interactive_canvas";
import { Actions } from "../sceneGraph/actions/actions";
import { InteractiveEvent } from "../sceneGraph/InteractiveEvent";
import { InteractiveInput } from "../sceneGraph/InteractiveInput";
import { Simulation } from "../simulation";
import { WebApplicationSettings } from "../WebApplicationSettings";
import { InputCapability } from "./InputCapability";
import { KeyCodes } from "./KeyCodes";

export class SelectionMode {
    static noSelectionModePropertyName = "replace_selection_mode_transition_with_action";
    simulation: Simulation;
    keybindings: any;
    ux_image_path: string;
    key_down_bindings: any[] = [];
    key_up_bindings: any[] = [];
    //key_hold_down_bindings = [];
    //key_hold_times = {};
    input_capability = InputCapability.wasd_okay_back_home;

    constructor() {}

    helpText() {
        return "";
    }
    initialize(simulation: Simulation) {
        this.simulation = simulation;
        this.simulation.icanvas.platformCanvas?.modifySelectionMode(this);
    }

    transitionState(new_state: SelectionMode) {
        this.simulation.selectionMode.stopState();
        this.simulation.selectionMode = new_state;
        this.simulation.selectionMode.initialize(this.simulation);
        this.simulation.selectionMode.startState();
    }

    stopState() {
        // var uxScene = this.simulation.sceneGraphNode.findSceneByName("ux-scene");
        // var icon = uxScene.findInteractiveByName(this.ux_image_path, false);
        // icon?.area.geometry.setScale(0);
    }

    startState() {
        // var uxScene = this.simulation.sceneGraphNode.findSceneByName("ux-scene");
        // var icon = uxScene.findInteractiveByName(this.ux_image_path, false);
        // if (icon?.area.geometry) {
        //     icon.area.geometry.setScale(1.0);
        // }
    }

    onInteractiveInputKeydown(ievent: InteractiveEvent, interactiveInput: InteractiveInput) {
        if (this.keybindings === undefined) {
            return;
        }

        let keybindings_verb = this.keybindings[interactiveInput.verb];
        if (keybindings_verb !== undefined) {
            let keys = keybindings_verb[interactiveInput.verb_value];

            let key = ievent.e_keyboardevent?.keyCode;

            console.log(`Key pressed: ${key}`);

            for (let each in keys) {
                if (keys[each] === key) {
                    interactiveInput.interactive.onInteractiveInput(ievent, interactiveInput);
                    ievent.preventDefaultAndStopPropagation();
                    break;
                }
            }
        }
    }

    onInteractiveInputKeyup(ievent: InteractiveEvent, interactiveInput: InteractiveInput) {}

    isSceneInteractionMode() {
        return false;
    }

    check_keybindings(array: any[], ievent: InteractiveEvent) {
        for (const each_item of array) {
            let each_key = each_item[0];
            let each = each_item[1];

            if (Array.isArray(each_key)) {
                for (const each_key_array_item of each_key) {
                    if (ievent.e_keyboardevent?.keyCode === each_key_array_item) {
                        ievent.preventDefaultAndStopPropagation();
                        each();
                        this.simulation.getUXScene()?.sceneInteractiveLayer.onActivity(false);
                        return true;
                    }
                }
            } else {
                if (ievent.e_keyboardevent?.keyCode === each_key) {
                    ievent.preventDefaultAndStopPropagation();
                    each();
                    this.simulation.getUXScene()?.sceneInteractiveLayer.onActivity(false);
                    return true;
                }
            }
        }
        return false;
    }

    keyup(icanvas: InteractiveCanvas, ievent: InteractiveEvent) {
        this.check_keybindings(this.key_up_bindings, ievent);

        // let whenDown = this.key_hold_times[ievent.e.keyCode];

        // if (whenDown !== undefined) {
        //     let hold_duration = 600;
        //     let now = Date.now();
        //     let delta = now - whenDown ;
        //   //  console.log(`${} ${}`);
        //     if (delta> hold_duration) {
        //         let found = this.check_keybindings(this.key_hold_down_bindings, ievent);
        //     }

        //     this.key_hold_times[ievent.e.keyCode] = undefined;
        // }
    }

    keydown(icanvas: InteractiveCanvas, ievent: InteractiveEvent) {
        this.check_keybindings(this.key_down_bindings, ievent);

        // if(this.key_hold_times[ievent.e.keyCode]===undefined){
        // this.key_hold_times[ievent.e.keyCode] = Date.now();

        // }
    }
}

export class NavigationMode extends SelectionMode {
    constructor() {
        super();
        this.ux_image_path = "icon-navigation-mode";
    }

    change_to_interaction_mode() {
        let scene = this.simulation.getActiveScene();
        if (!scene) return;

        let override_action = scene?.json[NavigationMode.noSelectionModePropertyName];

        if (override_action) {
            let actions = new Actions();
            actions.addActionFromJson(override_action, this.simulation.application);
            actions.runActions(scene);
        } else {
            this.transitionState(new SceneInteractionMode());
        }
    }

    helpText() {
        let r = `Navigation mode
  up: move forward
  left: turn left
  down: move backward
  right: turn right
`;

        if (this.input_capability === InputCapability.wasd_okay_back_home) {
            r += `  okay: interactive mode
  back: previous scene
`;
        } else {
            r += `  back: interactive mode`;
        }

        return r;
    }

    initialize(simulation: Simulation) {
        super.initialize(simulation);

        this.keybindings = {
            rotate: {
                left: [KeyCodes.a, KeyCodes.ArrowLeft],
                right: [KeyCodes.d, KeyCodes.ArrowRight],
            },
            move: {
                forward: [KeyCodes.w, KeyCodes.ArrowUp],
                back: [KeyCodes.s, KeyCodes.ArrowDown],
            },
        };

        if (this.input_capability === InputCapability.wasd_okay_back_home) {
            this.key_down_bindings.push([
                KeyCodes.Home,
                () => {
                    let actions = new Actions();
                    actions.addActionFromJson(
                        Actions.newJsonFromVerb("home"),
                        this.simulation.application,
                    );
                    actions.runActions(this.simulation.getActiveScene());
                },
            ]);

            this.key_down_bindings.push([
                KeyCodes.Enter,
                () => {
                    this.change_to_interaction_mode();
                },
            ]);
            this.key_down_bindings.push([
                KeyCodes.Backspaces,
                () => {
                    let actions = new Actions();
                    actions.addActionFromJson(
                        Actions.newJsonFromVerb("back"),
                        this.simulation.application,
                    );
                    actions.runActions(this.simulation.getActiveScene());
                },
            ]);
        } else {
            this.key_down_bindings.push([
                KeyCodes.Backspaces,
                () => {
                    this.change_to_interaction_mode();
                },
            ]);

            // this.key_hold_down_bindings.push([KeyCodes.Downs, () => {
            //     this.change_to_interaction_mode();
            // }]);
        }
    }
}

export class SceneInteractionMode extends SelectionMode {
    constructor() {
        super();
        this.ux_image_path = "icon-interactive-mode";
    }

    run_selected_actions() {
        let selected = this.simulation.getSelectedSceneInteractive();
        selected?.runActions();
    }
    exit_mode() {
        this.transitionState(new NavigationMode());
    }
    helpText() {
        let r = `Interaction mode
`;

        if (this.input_capability === InputCapability.wasd_okay_back_home) {
            r += `  up: select previous
  left: select previous
  down: select next
  right: select next
  okay: activate selection`;
        } else {
            r += `  up: activate selection
  left: select previous
  down: select next
  right: select next`;
        }
        //  back: navigation mode

        return r;
    }
    initialize(simulation: Simulation) {
        super.initialize(simulation);

        this.key_down_bindings.push([
            KeyCodes.RightsDowns,
            () => {
                if (this.simulation.isUXFadedOut()) {
                    return;
                }
                this.simulation.selectNextSceneInteractive(1);
            },
        ]);

        if (this.input_capability === InputCapability.wasd_okay_back_home) {
            this.key_down_bindings.push([
                KeyCodes.Home,
                () => {
                    let actions = new Actions();
                    actions.addActionFromJson(
                        Actions.newJsonFromVerb("home"),
                        this.simulation.application,
                    );
                    actions.runActions(this.simulation.getActiveScene());
                },
            ]);

            this.key_down_bindings.push([
                KeyCodes.Backspaces,
                () => {
                    let actions = new Actions();

                    if (
                        simulation.application.settings.getSetting(
                            WebApplicationSettings.isBackButtonRefresh_SettingName,
                        )
                    ) {
                        actions.addActionFromJson(
                            Actions.newJsonFromVerb("refresh_page"),
                            this.simulation.application,
                        );
                    } else {
                        actions.addActionFromJson(
                            Actions.newJsonFromVerb("back"),
                            this.simulation.application,
                        );
                    }

                    actions.runActions(this.simulation.getActiveScene());
                    //this.exit_mode();
                },
            ]);

            this.key_down_bindings.push([
                KeyCodes.Enter,
                () => {
                    if (this.simulation.isUXFadedOut()) {
                        return;
                    }
                    this.run_selected_actions();
                },
            ]);

            this.key_down_bindings.push([
                KeyCodes.LeftsUps,
                () => {
                    if (this.simulation.isUXFadedOut()) {
                        return;
                    }
                    this.simulation.selectNextSceneInteractive(-1);
                },
            ]);
        } else if (this.input_capability === InputCapability.wasd) {
            this.key_down_bindings.push([
                KeyCodes.Ups,
                () => {
                    this.run_selected_actions();
                },
            ]);

            // this.key_down_bindings.push([KeyCodes.Downs, () => {
            //     this.exit_mode();
            // }]);

            // this.key_down_bindings.push([KeyCodes.Rights, () => {

            //     this.simulation.selectNextSceneInteractive(1);
            // }]);

            this.key_down_bindings.push([
                KeyCodes.Lefts,
                () => {
                    if (this.simulation.isUXFadedOut()) {
                        return;
                    }
                    this.simulation.selectNextSceneInteractive(-1);
                },
            ]);

            this.key_down_bindings.push([
                KeyCodes.Backspaces,
                () => {
                    //this.exit_mode();
                },
            ]);
        }
    }

    stopState() {
        super.stopState();
        this.simulation.sceneGraphNode.selectNone();
    }

    startState() {
        super.startState();
        this.simulation.sceneGraphNode.selectNone();

        let scene = this.simulation.getActiveScene();
        let interactives = scene?.sceneInteractiveLayerInteractives;
        interactives?.selectDefaultOrFirst();
    }

    isSceneInteractionMode() {
        return true;
    }
}
