import { ConsoleLogger } from "../../../ConsoleLogger.ts";
import { hideElement, isElementHidden, showElement } from "../../../htmlHelper.ts";
import { IConsoleLogger } from "../../../IConsoleLogger.ts";
import { VideoData } from "../VideoData.ts";
export const MIME_TYPE_MP4 = "video/mp4";
const MIME_CODEC = 'video/mp4; codecs="avc1.42E01E"';
// const MIME_CODEC = 'video/mp4; codecs="avc1.640028"';

/** tag for console.log() */
const TAG = "MediaSourcePlayer:";

enum PlayerState {
    INITIALIZING = "INITIALIZING",
    IDLE = "IDLE",
    LOADING = "LOADING",
    PLAYING = "PLAYING",
    PAUSED = "PAUSED",
    ENDED = "ENDED",
    ERROR = "ERROR",
}

export interface VideoPlayer {
    videoElement: HTMLVideoElement;
    // @TODO: This should be changed so that video data objects are used exculsively
    isHidden: Boolean;
    play(videoData: VideoData): void;
    pause(): void;
    initialize(): void;
    cleanUp(): void;
    // the following should be moved into a new interface and combined into a seperate one
    resumeOnGesture(): void;
    setVideoSize(width: number, height: number): void;
    tryShow(): void;
    hide(clearBufferOnHide: boolean): void;
}
type EventType = "ready";

interface eventEmitter {
    addEventListener(eventType: EventType, callback: Function): void;
}

/**
 * MediaSource Player
 */
export class MediaSourcePlayer implements VideoPlayer, eventEmitter {
    static getMimeType(arrayBuffer: ArrayBuffer) {
        const uint8Array = new Uint8Array(arrayBuffer);

        if (
            uint8Array[4] === 0x66 &&
            uint8Array[5] === 0x74 &&
            uint8Array[6] === 0x79 &&
            uint8Array[7] === 0x70
        ) {
            return MIME_TYPE_MP4;
        }
        return "unknown";
    }
    videoElement: HTMLVideoElement;
    private isWaitingForGesture = false;
    private highFrequencyConsoleLogger: IConsoleLogger;
    private ready = true;
    private state: PlayerState;
    //isWaitUntilReadyOnPlay=true;
    private lastVideoDataAppendedToBuffer?: VideoData;
    private loopingVideo?: ArrayBuffer;
    private mediaSource: MediaSource;
    private bufferStart = 0;
    private lastSegmentStartTime = 0;
    private listeners: any = {};
    private videoQueue: VideoData[] = [];
    private sourceBuffer: SourceBuffer;
    /** Threshold in seconds before the end to reenqueue */
    private loopThreshold = 1;
    /** Threshold in seconds before notifying that the video has played once */
    private endedThreshold = 0.3;

    constructor(
        videoElement: HTMLVideoElement,
        highFrequencyConsoleLogger: IConsoleLogger | undefined = undefined,
    ) {
        if (videoElement === null) {
            throw new Error("VideoElement is Null");
        }
        this.state = PlayerState.INITIALIZING;
        this.videoElement = videoElement;
        this.highFrequencyConsoleLogger = highFrequencyConsoleLogger ?? new ConsoleLogger();
    }

    get isHidden() {
        return isElementHidden(this.videoElement);
    }

    /**
     *
     */
    pause() {
        this.changeState(PlayerState.PAUSED);
    }

    resumeOnGesture() {
        if (this.isWaitingForGesture) {
            console.log(TAG + "resume on gesture");

            this.isWaitingForGesture = false;

            if (this.state === PlayerState.ERROR) {
                this.changeState(PlayerState.PLAYING);
            }
        }
    }

    setVideoSize(width: number, height: number) {
        this.videoElement.width = width;
        this.videoElement.height = height;
    }

    /**
     *
     */
    tryShow() {
        if (this.isHidden) {
            showElement(this.videoElement);
            //this.#resumeFromClearBuffer();
        }
    }

    /**
     *
     */
    hide(clearBufferOnHide = false) {
        hideElement(this.videoElement);
        if (clearBufferOnHide) {
            this.clearBuffer();
        }
    }

    initialize() {
        this.mediaSource = new MediaSource();
        // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/srcObject
        // NOTE: wrapping conditional removed to appease TypeScript compiler
        try {
            this.videoElement.srcObject = this.mediaSource;
        } catch (err: any) {
            if (err.name !== "TypeError") {
                throw err;
            }
            // Even if they do, they may only support MediaStream
            this.videoElement.src = URL.createObjectURL(this.mediaSource);
        }
        this.mediaSource.addEventListener("sourceopen", this.onSourceOpen.bind(this));
        this.mediaSource.addEventListener("sourceended", () => this.changeState(PlayerState.ENDED));
        this.videoElement.addEventListener("error", (err) => {
            console.error(TAG, err?.message, err.lineno, err.filename);
            console.error(TAG, this.videoElement.error?.code, this.videoElement.error?.message);
            return true;
        });
        this.videoElement.onended = (event) => {
            console.error(
                TAG,
                "Video ended:",
                "\nreadyState:",
                this.videoElement.readyState,
                "\ncurrentTime:",
                this.videoElement.currentTime,
                "\nduration:",
                this.videoElement.duration,
                "\npaused:",
                this.videoElement.paused,
            );
        };

        // The waiting event is fired when playback has stopped because of a temporary lack of data.
        this.videoElement.addEventListener("waiting", (event) => {
            console.info(TAG + ":NOT-READY");
            this.ready = false;
        });
        // The loadeddata event is fired when the frame at the current playback position of the media has finished loading; often the first frame.
        this.videoElement.addEventListener("loadeddata", (event) => {});

        // The canplaythrough event is fired when the user agent can play the media, and estimates that enough data has been loaded to play the media up to its end without having to stop for further buffering of content.
        this.videoElement.addEventListener("canplaythrough", (event) => {
            this.ready = true;
        });
        console.info("new:" + TAG + "(" + this.videoElement + ")");
    }

    getBufferEnd() {
        return this.sourceBuffer.buffered.end(this.sourceBuffer.buffered.length - 1);
    }

    enqueueVideoData(videoData: VideoData) {
        this.videoQueue.push(videoData);
        this.processQueue();
    }

    /**
     * add the callback to listeners during initialization, otherwise call the callback
     */
    // TODO Complete Edge case handling
    addEventListener(event: EventType, callback: Function) {
        if (!this.listeners[event]) {
            this.listeners[event] = [];
        }
        this.listeners[event].push(callback);
        this.emitEvent(event);
        // if ready event listener
    }

    cleanUp() {
        for (const listener in this.listeners) {
            this.mediaSource.removeEventListener(listener, this.listeners[listener]);
        }
        this.listeners = {};
    }
    /**
     * Adds a video segment to the player and plays that video when ready
     * @param {VideoData} videoData
     *
     */
    async play(videoData: VideoData) {
        try {
            console.info(TAG, "playing", videoData.name, videoData.video.byteLength);
            // console.info(TAG+":MIME type support:", this.videoElement.canPlayType(MIME_CODEC))
            // console.info(TAG+":ANALYZING MIME HEADERS", this.getMimeType(video))
            this.enqueueVideoData(videoData);
            if (videoData.isPlayImmediate) {
                console.log(TAG, "isPlayImmediate");
                this.skipToLastAppendedSegment();
                console.log(TAG, "waiting for ready");
                await this.waitForReady();
                console.log(TAG, "done waiting for ready");
            }
            // this.clearStaleBuffer()
        } catch (err: any) {
            this.changeState(PlayerState.ERROR, {
                ...err,
                message: `${TAG} play() ${err.message}`,
            });
        }
    }

    private emitEvent(event: EventType) {
        switch (event) {
            case "ready":
                if (this.state !== PlayerState.INITIALIZING) {
                    this.listeners[event].forEach((cb: Function) => cb());
                }
                break;
            default:
                break;
        }
    }

    private clearBuffer() {
        try {
            if (this.sourceBuffer) {
                const bufferEnd = this.getBufferEnd();
                if (bufferEnd > 0) {
                    console.log(TAG, "removing previous buffer,", this.bufferStart, "-", bufferEnd);
                    this.sourceBuffer.remove(this.bufferStart, bufferEnd);
                }
            }
        } catch (err: any) {
            this.changeState(PlayerState.ERROR, {
                ...err,
                message: `${TAG} clearBuffer() ${err.message}`,
            });
        }
    }

    private processQueue() {
        try {
            if (this.sourceBuffer && !this.sourceBuffer.updating && this.videoQueue.length) {
                const videoData = this.videoQueue.shift();
                if (!videoData) {
                    throw new Error("retrieved undefined video from queue");
                }
                this.appendToBuffer(videoData.video);
                if (!this.videoQueue.length) {
                    this.lastVideoDataAppendedToBuffer = videoData;
                    if (videoData.isLoop) {
                        this.loopingVideo = videoData.video;
                    } else {
                        this.loopingVideo = undefined;
                    }
                }
            }
        } catch (err: any) {
            this.changeState(PlayerState.ERROR, {
                ...err,
                message: `${TAG} processQueue() ${err.message}`,
            });
        }
    }

    private skipToLastAppendedSegment(isSkipToEnd = false) {
        try {
            if (
                this.sourceBuffer?.buffered !== undefined &&
                this.sourceBuffer.buffered.length > 0
            ) {
                // Set currentTime to the start of the last appended segment
                let bufferEnd = this.getBufferEnd();
                this.videoElement.currentTime = isSkipToEnd ? bufferEnd : this.lastSegmentStartTime;
            } else {
                console.warn("No buffered data to skip to.");
            }
        } catch (err: any) {
            this.changeState(PlayerState.ERROR, {
                ...err,
                message: `${TAG} skipToLastAppendedSegment() ${err.message}`,
            });
        }
    }

    private appendToBuffer(video: ArrayBuffer) {
        if (!this.sourceBuffer) {
            console.error(TAG, "no source buffer found");
            this.changeState(PlayerState.ERROR);
            return;
        }

        try {
            if (this.sourceBuffer.buffered && this.sourceBuffer.buffered.length > 0) {
                this.sourceBuffer.timestampOffset = this.getBufferEnd();
                this.lastSegmentStartTime = this.sourceBuffer.timestampOffset;
            }
            this.sourceBuffer.appendBuffer(video);
        } catch (err: any) {
            this.changeState(PlayerState.ERROR, {
                ...err,
                message: `${TAG} appendToBuffer() ${err.message}`,
            });
        }
    }

    private checkForLoop() {
        if (this.videoElement.currentTime === 0) {
        }
        try {
            if (
                !this.sourceBuffer ||
                !this.sourceBuffer.buffered ||
                this.sourceBuffer.buffered.length === 0
            )
                return;

            const bufferedEnd = this.getBufferEnd();
            const currentTime = this.videoElement.currentTime;
            const timeLeft = bufferedEnd - currentTime;
            this.highFrequencyConsoleLogger.log(TAG, timeLeft);

            // Check if we are within the threshold near the end of the buffer
            if (timeLeft < this.loopThreshold) {
                // Re-enqueue the last segment if the queue is empty (i.e., the last video was played)
                if (
                    this.videoQueue.length === 0 &&
                    this.loopingVideo &&
                    this.lastVideoDataAppendedToBuffer
                ) {
                    console.log(
                        TAG,
                        `Re-enqueueing last video segment for looping. name = ${this.lastVideoDataAppendedToBuffer.name}`,
                    );

                    this.enqueueVideoData(this.lastVideoDataAppendedToBuffer); // Re-add the video to the queue to loop
                    return;
                }
            }
        } catch (err: any) {
            this.changeState(PlayerState.ERROR, {
                ...err,
                message: `${TAG} checkForLoop() ${err.message}`,
            });
            // console.error(TAG, err)
            // console.error(TAG, this.videoElement.error?.code, this.videoElement.error?.message)
        }
    }

    private checkForPlayedOnce() {
        if (this.videoElement.currentTime === 0) {
        }
        try {
            if (
                !this.sourceBuffer ||
                !this.sourceBuffer.buffered ||
                this.sourceBuffer.buffered.length === 0
            )
                return;

            const bufferedEnd = this.getBufferEnd();
            const currentTime = this.videoElement.currentTime;
            const timeLeft = bufferedEnd - currentTime;

            if (timeLeft < this.endedThreshold) {
                // console.log(TAG, `last video segment past end threshold. name = ${this.lastVideoDataAppendedToBuffer?.name}`);

                this.tryCallOnPlayedOnce();
            }
        } catch (err: any) {
            this.changeState(PlayerState.ERROR, {
                ...err,
                message: `${TAG} checkForPlayedOnce() ${err.message}`,
            });
        }
    }

    private tryCallOnPlayedOnce() {
        if (
            this.videoQueue.length === 0 &&
            !this.loopingVideo &&
            this.lastVideoDataAppendedToBuffer
        ) {
            console.log(
                TAG,
                `last video segment played once. name = ${this.lastVideoDataAppendedToBuffer?.name}`,
            );

            this.lastVideoDataAppendedToBuffer?.onPlayedOnce?.();
        }
    }

    private onSourceOpen() {
        try {
            this.sourceBuffer = this.mediaSource.addSourceBuffer(MIME_CODEC);
            // https://developer.mozilla.org/en-US/docs/Web/API/SourceBuffer/mode
            this.sourceBuffer.mode = "sequence";

            this.changeState(PlayerState.IDLE);
            if (this.listeners["ready"]) {
            }

            this.sourceBuffer.addEventListener("updatestart", () =>
                this.changeState(PlayerState.LOADING),
            );
            this.sourceBuffer.addEventListener("updateend", () => {
                this.changeState(PlayerState.PLAYING);
                this.processQueue();
            });

            this.videoElement.addEventListener("timeupdate", this.checkForLoop.bind(this));
            this.videoElement.addEventListener("timeupdate", this.checkForPlayedOnce.bind(this));

            this.videoElement.addEventListener("seeked", () => {
                if (this.videoElement.currentTime === 0) {
                }
            });

            this.videoElement.addEventListener("loadeddata", () => console.log(TAG, " Loaded"));
        } catch (err: any) {
            this.changeState(PlayerState.ERROR, {
                ...err,
                message: `${TAG} onSourceOpen() ${err.message}`,
            });
            // console.error(TAG, error)
            // console.error(TAG, this.videoElement.error?.code, this.videoElement.error?.message)
        }
    }

    private changeState(newState: PlayerState, error?: any) {
        if (this.state === newState) return;
        this.state = newState;
        switch (this.state) {
            case PlayerState.PLAYING:
                this.videoElement.play().catch((err) => {
                    if (err.name === "NotAllowedError") {
                        console.log("video delay:" + err.name);
                        this.isWaitingForGesture = true;
                    }
                    this.changeState(PlayerState.ERROR, err);
                    //console.warn("warning: on play()", err);
                });
                break;
            case PlayerState.LOADING:
                break;
            case PlayerState.ENDED:
                console.info(TAG, "MEDIA SOURCE", this.state);
                break;
            case PlayerState.IDLE:
                console.info(TAG, "MEDIA SOURCE", this.state);
                this.emitEvent("ready");
                console.log(TAG, "listeners called", this.listeners);
                break;
            case PlayerState.ERROR:
                console.error(TAG, error, error?.message);
                if (this.videoElement.error) {
                    console.error(
                        TAG,
                        this.videoElement.error.code,
                        this.videoElement.error.message,
                    );
                }
                break;
            case PlayerState.PAUSED:
                console.info(TAG, "MEDIA SOURCE", this.state);
                this.videoElement.pause();
                break;
            default:
                console.info(TAG, `unknown state ${this.state}`);
                break;
        }
    }

    private waitForReady() {
        return new Promise<void>((res) => {
            const id = setInterval(() => {
                if (this.ready) {
                    // console.info(TAG+":READY")
                    clearInterval(id);
                    res();
                }
            }, 100);
        });
    }
}
