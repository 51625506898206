//@ts-check

import { SceneObjectInterface } from "../SceneObjectInterface";

/**
 *
 */
export class Action {
    static typename = "action";

    verb: string;
    name?: string;

    constructor(verb: string, name?: string) {
        this.verb = verb;
        this.name = name;
    }

    canRun(param: SceneObjectInterface | undefined) {
        return true;
    }

    run(param: SceneObjectInterface | undefined) {}
}

export class ActionWithValue extends Action {
    value: any;
    value_context: any;

    constructor(verb: string, name: string | undefined, json: any) {
        super(verb, name);
        this.value = json["value"];
        this.value_context = json["value_context"];
    }
}
