import { InteractiveArea } from "./InteractiveArea";
import { InteractiveInput } from "./InteractiveInput";
import { Action } from "./actions/action";
import { Actions, newActionFromJson } from "./actions/actions";
import { InteractiveLayers } from "./InteractiveLayers";
import { UserInterfaceElements } from "../view/userInterfaceElements";
import { InteractivePointOfInterest } from "./InteractivePointOfInterest";
import { CustomerAccount } from "../customerAccount";
import { Resources } from "../resources";
import { InteractiveCanvas } from "../audio_visual/interactive_canvas";
import { WebApplication } from "../webApplication";
import { InteractiveEvent } from "./InteractiveEvent";
import { DragDropEvent } from "./DragDropEvent";
import { ScenePath } from "./ScenePath";
import { SceneGraph } from "./scene_graph";
import { RectangleGeometry } from "../geometry/RectangleGeometry";
import { SceneObjectInterface } from "./SceneObjectInterface";
import { GettingReadyCallbackCollector } from "./GettingReadyCallbackCollector";
import { Interactives } from "./interactives";
let c2 = require("c2.js");

export class Interactive implements SceneObjectInterface {
    static typename = "interactive";

    static getNameFromFilename(file: any) {
        return file.name;
    }
    static newJsonFromFile(file: any) {
        return {
            name: this.getNameFromFilename(file),
            "interactive.area": {
                "geometry.rectangle": [0.0, 0.0, 0.05, 0.05],
            },
        };
    }
    parent: Interactives;
    json: any;
    area?: InteractiveArea;
    inputs: InteractiveInput[] = [];
    actions: Action[] = [];
    ui: UserInterfaceElements;
    points_of_interest: InteractivePointOfInterest[];
    isSelected: boolean;
    visual_geometry: RectangleGeometry;

    constructor(parent: Interactives, json: any) {
        this.parent = parent;
        this.json = json;
    }

    hasPointOfInterest() {
        return this.points_of_interest !== undefined;
    }

    clone() {
        return new Interactive(this.parent, { ...this.json });
    }

    isSelectable() {
        return this.area !== undefined && this.area.isSelectable() && !this.getHidden();
    }

    setSelected(value: boolean) {
        if (this.isSelected === value) {
            return;
        }

        if (value) {
            for (const each of this.points_of_interest || []) {
                each.start();

                if (each.json["area.visible"] === false) {
                    this.area?.visual_element?.setHidden(true);
                }
            }
        } else {
            for (const each of this.points_of_interest || []) {
                each.stop();

                if (each.json["area.visible"] === false) {
                    this.area?.visual_element?.setHidden(false);
                }
            }
        }

        this.isSelected = value;
        this.icanvas.invalidate();
    }

    initializeFromJson() {
        if (this.json.layer === undefined) {
            this.json.layer = InteractiveLayers.DefaultLayerJson.name;
        }

        for (let each in this.json) {
            if (each === Action.typename) {
                this.addActionFromJson(this.json[each], this.scene.application);
            } else if (each === InteractiveInput.typename) {
                let i = new InteractiveInput(this, this.json[each]);
                this.inputs.push(i);
            } else if (each === InteractiveArea.typename) {
                this.area = new InteractiveArea(this, this.json[each]);
            } else if (each === Actions.typename) {
                for (let each_action in this.json[each]) {
                    this.addActionFromJson(this.json[each][each_action], this.scene.application);
                }
            } else if (each === "ui") {
                this.ui = new UserInterfaceElements(
                    () => {
                        return this.getScreenSpaceAreaRect();
                    },
                    this.json[each],
                    this.scene.application,
                );
            } else if (each === "isHidden") {
                this.setHidden(this.json[each]);
            }
        }

        if (this.json[InteractivePointOfInterest.typename]) {
            this.points_of_interest = [
                new InteractivePointOfInterest(
                    this,
                    this.json[InteractivePointOfInterest.typename],
                ),
            ];
        }
        if (this.json[InteractivePointOfInterest.array_typename]) {
            this.points_of_interest = [];
            for (const each of this.json[InteractivePointOfInterest.array_typename]) {
                this.points_of_interest.push(new InteractivePointOfInterest(this, each));
            }
        }

        if (this.area) {
            this.area.initializeFromJson();
        }
        for (const each of this.points_of_interest || []) {
            each.initializeFromJson();
        }
    }

    getHidden() {
        return this.json["isHidden"] === true || this.isConditionalFalse();
    }

    isConditionalFalse() {
        if (
            this.json.if_setting &&
            this.parent.scene.application.getSetting(this.json.if_setting) === false
        ) {
            return true;
        }
        return false;
    }

    setHidden(value: boolean) {
        this.json["isHidden"] = value;
    }

    get isTemporary() {
        return this.json.isTemporary;
    }

    set isTemporary(v: boolean) {
        this.json.isTemporary = v;
    }

    get name() {
        return this.json.name;
    }

    set name(v: string) {
        this.json.name = v;
    }

    get account() {
        return this.scene.sceneGraph.account;
    }

    get resources() {
        return this.scene.resources;
    }

    get icanvas() {
        return this.scene.icanvas;
    }

    get scene() {
        return this.parent.scene;
    }

    getScreenSpaceAreaRect() {
        if (this.area) {
            return this.area.getScreenSpaceAreaRect();
        }
        return new c2.Rect(0, 0, 0, 0);
    }
    setAreaReletivePosition(point: any) {
        this.area?.setAreaReletivePosition(point);
    }

    addActionFromJson(j: any, application: WebApplication) {
        let a = newActionFromJson(j, application);
        if (a) {
            this.actions.push(a);
        }
    }

    getResourcePath() {
        return this.json.resourcePath || this.parent.getResourcePath();
    }

    isVisualContentReady() {
        if (this.area && !this.area.isVisualContentReady()) {
            return false;
        }
        return true;
    }

    startVisualContentGetReady(gettingReadyCallbackCollector: GettingReadyCallbackCollector) {
        this.area?.startVisualContentGetReady(gettingReadyCallbackCollector);

        if (this.json["points_of_interest.preload"] === true) {
            for (const each of this.points_of_interest || []) {
                each.startVisualContentGetReady(gettingReadyCallbackCollector);
            }
        }
    }

    start() {
        this.area?.start();

        this.ui?.addToDOM();
    }

    stop() {
        this.area?.stop();
        this.ui?.removeFromDOM();

        for (const each of this.points_of_interest || []) {
            each.stop();
        }
    }

    drawFrame(icanvas: InteractiveCanvas) {
        if (this.getHidden() === true) {
            return;
        }

        this.area?.drawFrame(icanvas);
        this.ui?.updateDOM();
    }

    isIEventOnInteractiveArea(ievent: InteractiveEvent) {
        if (this.getHidden()) {
            return false;
        }
        return this.area?.isIEventOnInteractiveArea(ievent) || false;
    }

    mousedown(ievent: InteractiveEvent) {
        if (!this.isSelectable()) {
            return;
        }
        this.area?.mousedown(ievent);
    }

    mouseup(ievent: InteractiveEvent) {
        if (!this.isSelectable()) {
            return;
        }
        this.area?.mouseup(ievent);
    }

    mousemove(ievent: InteractiveEvent) {
        if (!this.isSelectable()) {
            return;
        }
        this.area?.mousemove(ievent);
    }

    onMouseUp(ievent: InteractiveEvent, area: InteractiveArea) {
        if (!this.isSelectable()) {
            return;
        }
        ievent.isStopPropagation = true;

        this.runActions();
    }

    onInteractiveInput(ievent: InteractiveEvent, iinput: InteractiveInput) {
        ievent.isStopPropagation = true;
        this.runActions();
    }

    get visual_element() {
        return undefined;
    }

    isFadedOut() {
        return this.scene.isFadedOut();
    }

    toScenePath() {
        return this.scene.toScenePath();
    }

    get sceneGraph() {
        return this.scene.sceneGraph;
    }

    get server_file_cache() {
        return this.scene.server_file_cache;
    }

    get application() {
        return this.scene.application;
    }

    get geometry() {
        let r = new RectangleGeometry();
        r.initialize(0, 0, 0, 0);
        return r;
    }

    get visualElements() {
        return this.scene.visualElements;
    }

    get simulation() {
        return this.scene.simulation;
    }

    get draw_order() {
        return this.scene.draw_order;
    }

    runActions() {
        for (let each in this.actions) {
            this.actions[each].run(this);
        }
    }

    runAction(action: Action) {
        const actionStore = this.actions;
        this.actions = [action];
        for (let each in this.actions) {
            this.actions[each].run(this);
        }
        this.actions = actionStore;
    }

    apply(command: any) {
        if (command instanceof Object) {
        }
    }

    keydown(ievent: InteractiveEvent) {
        for (let each in this.inputs) {
            this.inputs[each].keydown(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }
    }

    keyup(ievent: InteractiveEvent) {
        if (this.getHidden()) {
            return;
        }
        for (let each in this.inputs) {
            this.inputs[each].keyup(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }
    }

    activate(ievent: InteractiveEvent) {
        if (ievent.activate_value === "interactive.input") {
            for (let each in this.inputs) {
                this.inputs[each].activate(ievent);
                if (ievent.isStopPropagation) {
                    break;
                }
            }
        }
    }

    onTouchTap(ievent: InteractiveEvent) {
        if (!this.isSelectable()) {
            return;
        }
        this.area?.onTouchTap(ievent);
    }

    onTouchPan(ievent: InteractiveEvent) {
        if (!this.isSelectable()) {
            return;
        }
    }

    onTouchSwipe(ievent: InteractiveEvent) {
        if (!this.isSelectable()) {
            return;
        }
        for (let each in this.inputs) {
            this.inputs[each].onTouchSwipe(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }
    }

    onTouchDistance(ievent: InteractiveEvent) {
        if (!this.isSelectable()) {
            return;
        }
    }

    onTouchRotate(ievent: InteractiveEvent) {
        if (!this.isSelectable()) {
            return;
        }
    }

    onTouchGesture(ievent: InteractiveEvent) {
        if (!this.isSelectable()) {
            return;
        }
    }

    file_dropped(ievent: DragDropEvent) {
        if (!this.isSelectable()) {
            return;
        }
    }

    drag_file(ievent: DragDropEvent) {
        if (!this.isSelectable()) {
            return;
        }
    }

    runActionJson(action: any) {
        let actions = new Actions();
        actions.parseJson(action, this.application);
        actions.runActions(this);
    }

    getVideoFileInfoForStem(video_stem: any) {
        return this.scene.getVideoFileInfoForStem(video_stem);
    }
}
