import { Range } from "./Range.ts";

export type ActionT<T> = (params: T) => void;
export type PredicateT<T> = (params: T) => boolean;
export type NewObject<T> = () => T;

export class RangeAssociatedObject<T> {
    range: Range;
    associatedObject: T;
    /**
     *
     */
    constructor(range: Range, associatedObject: T) {
        this.range = range;
        this.associatedObject = associatedObject;
    }
}

export class SortedRangeAssociatedList<T> {
    /**
     * @type {Array}
     */
    list: RangeAssociatedObject<T>[];
    constructor() {
        this.list = [];
    }
    findLastAssociatedObjectWhere(predicate: PredicateT<T>) {
        for (let i = this.list.length - 1; i >= 0; i--) {
            if (predicate(this.list[i].associatedObject)) {
                return this.list[i].associatedObject;
            }
        }
        return null; // or undefined, depending on your preference
    }
    findLastAssociatedObjectWhereWithRange(predicate: PredicateT<T>) {
        for (let i = this.list.length - 1; i >= 0; i--) {
            if (predicate(this.list[i].associatedObject)) {
                return {
                    associatedObject: this.list[i].associatedObject,
                    range: this.list[i].range,
                };
            }
        }
        return null; // or undefined, depending on your preference
    }
    insertItemAt(associatedObject: T, startAt: number, endAt: number, isAvoidCollision = false) {
        let retryCount = 0;

        if (isAvoidCollision) {
            let retryLimit = 5;
            while (this.hasCollision(startAt, endAt) && retryCount < retryLimit) {
                startAt = endAt;
                endAt = startAt + (endAt - startAt);
                retryCount++;
            }
            if (retryCount === retryLimit) {
                return undefined;
            }
        }
        const newRange = new Range(startAt, endAt);
        this.insertRange(newRange, associatedObject);
        return newRange;
    }

    hasCollision(startAt: number, endAt: number) {
        const index = this.findInsertionIndex(startAt);
        const prevItem = this.list[index - 1];
        const nextItem = this.list[index];
        if (prevItem && prevItem.range.end > startAt) {
            return true;
        }
        if (nextItem && nextItem.range.start < endAt) {
            return true;
        }
        return false;
    }

    firstAssociatedObjectWhere(predicate: PredicateT<T>) {
        for (const item of this.list) {
            if (predicate(item.associatedObject)) {
                return item.associatedObject;
            }
        }
        return null;
    }
    deleteItemsUnlessAssociatedObject(predicate: PredicateT<T>) {
        this.list = this.list.filter((item) => predicate(item.associatedObject));
    }
    deleteItemsBeforeFirstAssociatedObjectFoundWhere(predicate: PredicateT<T>) {
        let indexToDeleteUpTo = -1;
        for (let i = 0; i < this.list.length; i++) {
            if (predicate(this.list[i].associatedObject)) {
                indexToDeleteUpTo = i;
                break;
            }
        }
        if (indexToDeleteUpTo > 0) {
            this.list = this.list.slice(indexToDeleteUpTo);
        }
    }
    getFirstAssociatedObject() {
        return this.list.length > 0 ? this.list[0].associatedObject : null;
    }
    getLastAssociatedObject() {
        return this.list.length > 0 ? this.list[this.list.length - 1].associatedObject : null;
    }
    getNextAssociatedObject(associatedObject: T) {
        for (let i = 0; i < this.list.length - 1; i++) {
            if (this.list[i].associatedObject === associatedObject) {
                return this.list[i + 1].associatedObject;
            }
        }
        return null;
    }
    appendLengthWithAssociatedObject(length: number, associatedObject: T, defaultStart = 0) {
        const lastRange = this.list.length > 0 ? this.list[this.list.length - 1].range : null;
        const start = lastRange ? lastRange.end : defaultStart;
        const newRange = new Range(start, start + length || 0);
        this.addRange(newRange, associatedObject);
        return newRange;
    }
    addRange(range: Range, associatedObject: T) {
        this.list.push({ range, associatedObject });
        this.list.sort((a, b) => a.range.start - b.range.start);
        return range;
    }
    insertRange(range: Range, associatedObject: T) {
        const newItem = { range, associatedObject };
        const index = this.findInsertionIndex(range.start);
        this.list.splice(index, 0, newItem);
        return range;
    }
    findInsertionIndex(start: number) {
        let low = 0;
        let high = this.list.length;
        while (low < high) {
            const mid = Math.floor((low + high) / 2);
            if (this.list[mid].range.start < start) {
                low = mid + 1;
            } else {
                high = mid;
            }
        }
        return low;
    }
    removeRange(range: Range) {
        this.list = this.list.filter(
            (item) => item.range.start !== range.start || item.range.end !== range.end,
        );
    }
    findRange(range: Range) {
        return this.list.find(
            (item) => item.range.start === range.start && item.range.end === range.end,
        );
    }
    getAssociatedObject(range: Range) {
        const item = this.findRange(range);
        return item ? item.associatedObject : null;
    }
    getOverlappingRanges(range: Range) {
        return this.list.filter((item) => item.range.overlapsWith(range));
    }
    getAllRanges() {
        return this.list;
    }

    getRangesContainingPoint(point: number) {
        return this.list.filter((item) => item.range.start <= point && item.range.end >= point);
    }

    getRangesWithinRange(range: Range) {
        return this.list.filter((item) => item.range.isWithin(range));
    }

    mergeOverlappingRanges() {
        if (this.list.length === 0) return;

        this.list.sort((a, b) => a.range.start - b.range.start);
        const mergedList = [this.list[0]];

        for (let i = 1; i < this.list.length; i++) {
            const lastMerged = mergedList[mergedList.length - 1];
            const current = this.list[i];

            if (
                lastMerged.range.overlapsWith(current.range) ||
                lastMerged.range.end >= current.range.start
            ) {
                lastMerged.range.end = Math.max(lastMerged.range.end, current.range.end);
            } else {
                mergedList.push(current);
            }
        }

        this.list = mergedList;
    }
    getRangesByAssociatedObject(associatedObject: T) {
        return this.list.filter((item) => item.associatedObject === associatedObject);
    }

    clear() {
        this.list = [];
    }

    updateAssociatedObject(range: Range, newAssociatedObject: T) {
        const item = this.findRange(range);
        if (item) {
            item.associatedObject = newAssociatedObject;
        }
    }

    getNonOverlappingRanges() {
        const nonOverlapping = [];
        for (let i = 0; i < this.list.length; i++) {
            let isOverlapping = false;
            for (let j = 0; j < this.list.length; j++) {
                if (i !== j && this.list[i].range.overlapsWith(this.list[j].range)) {
                    isOverlapping = true;
                    break;
                }
            }
            if (!isOverlapping) {
                nonOverlapping.push(this.list[i]);
            }
        }
        return nonOverlapping;
    }
    getRangeCount() {
        return this.list.length;
    }
    getTotalRangeLength() {
        return this.list.reduce((total, item) => total + (item.range.end - item.range.start), 0);
    }
    getRangesStartingBefore(point: number) {
        return this.list.filter((item) => item.range.start < point);
    }
    getRangesEndingAfter(point: number) {
        return this.list.filter((item) => item.range.end > point);
    }
    getRangesByLength(minLength: number, maxLength: number) {
        return this.list.filter((item) => {
            const length = item.range.end - item.range.start;
            return length >= minLength && length <= maxLength;
        });
    }
    getRangesIntersectingWith(range: Range) {
        return this.list.filter((item) => item.range.intersects(range));
    }
    getRangesOutsideRange(range: Range) {
        return this.list.filter(
            (item) => item.range.end < range.start || item.range.start > range.end,
        );
    }
    getRangesByPredicate(predicate: PredicateT<Range>) {
        return this.list.filter((item) => predicate(item.range));
    }

    getRangesByAssociatedObjectProperty<K extends keyof T>(
        property: K,
        value: T[K],
    ): RangeAssociatedObject<T>[] {
        return this.list.filter((item) => item.associatedObject[property] === value);
    }
    // getRangesByCustomComparator(comparator) {
    //     return this.list.sort(comparator);
    // }
    getStartOfFirstRange() {
        if (this.list.length === 0) return null;
        return this.list[0].range.start;
    }
    getEndOfLastRange() {
        if (this.list.length === 0) return null;
        return this.list[this.list.length - 1].range.end;
    }
    updateStartOfFirstRange(newStart: number) {
        if (this.list.length === 0) return;
        this.list[0].range.start = newStart;
    }
    updateEndOfLastRange(newEnd: number) {
        if (this.list.length === 0) return;
        this.list[this.list.length - 1].range.end = newEnd;
    }
    updateRangeEnd(
        indexOrInstance: number | RangeAssociatedObject<T>,
        newEnd: number,
        propagate = false,
    ) {
        const rangeIndex =
            typeof indexOrInstance === "number"
                ? indexOrInstance
                : this.list.indexOf(indexOrInstance);
        if (rangeIndex < 0 || rangeIndex >= this.list.length) return;
        const rangeToUpdate = this.list[rangeIndex].range;
        const delta = newEnd - rangeToUpdate.end;
        rangeToUpdate.end = newEnd;
        if (propagate) {
            for (let i = rangeIndex + 1; i < this.list.length; i++) {
                this.list[i].range.start += delta;
                this.list[i].range.end += delta;
            }
        }
    }
    updateRangeStart(
        indexOrInstance: number | RangeAssociatedObject<T>,
        newStart: number,
        propagate = false,
    ) {
        const rangeIndex =
            typeof indexOrInstance === "number"
                ? indexOrInstance
                : this.list.indexOf(indexOrInstance);
        if (rangeIndex < 0 || rangeIndex >= this.list.length) return;
        const rangeToUpdate = this.list[rangeIndex].range;
        const delta = newStart - rangeToUpdate.start;
        rangeToUpdate.start = newStart;
        if (propagate) {
            for (let i = rangeIndex + 1; i < this.list.length; i++) {
                this.list[i].range.start += delta;
                this.list[i].range.end += delta;
            }
        }
    }
    addToEndOfRange(
        indexOrInstance: number | RangeAssociatedObject<T>,
        value: number,
        propagate = false,
    ) {
        const rangeIndex =
            typeof indexOrInstance === "number"
                ? indexOrInstance
                : this.list.indexOf(indexOrInstance);
        if (rangeIndex < 0 || rangeIndex >= this.list.length) return;
        const rangeToUpdate = this.list[rangeIndex].range;
        rangeToUpdate.end += value;
        if (propagate) {
            for (let i = rangeIndex + 1; i < this.list.length; i++) {
                this.list[i].range.start += value;
                this.list[i].range.end += value;
            }
        }
    }
    addToStartOfRange(
        indexOrInstance: number | RangeAssociatedObject<T>,
        value: number,
        propagate = false,
    ) {
        const rangeIndex =
            typeof indexOrInstance === "number"
                ? indexOrInstance
                : this.list.indexOf(indexOrInstance);
        if (rangeIndex < 0 || rangeIndex >= this.list.length) return;
        const rangeToUpdate = this.list[rangeIndex].range;
        rangeToUpdate.start += value;
        if (propagate) {
            for (let i = rangeIndex + 1; i < this.list.length; i++) {
                this.list[i].range.start += value;
                this.list[i].range.end += value;
            }
        }
    }
    shiftRange(
        indexOrInstance: number | RangeAssociatedObject<T>,
        delta: number,
        propagate = false,
    ) {
        const rangeIndex =
            typeof indexOrInstance === "number"
                ? indexOrInstance
                : this.list.indexOf(indexOrInstance);
        if (rangeIndex < 0 || rangeIndex >= this.list.length) return;
        const rangeToUpdate = this.list[rangeIndex].range;
        rangeToUpdate.start += delta;
        rangeToUpdate.end += delta;
        if (propagate) {
            for (let i = rangeIndex + 1; i < this.list.length; i++) {
                this.list[i].range.start += delta;
                this.list[i].range.end += delta;
            }
        }
    }
    scaleRange(
        indexOrInstance: number | RangeAssociatedObject<T>,
        factor: number,
        propagate = false,
    ) {
        const rangeIndex =
            typeof indexOrInstance === "number"
                ? indexOrInstance
                : this.list.indexOf(indexOrInstance);
        if (rangeIndex < 0 || rangeIndex >= this.list.length) return;
        const rangeToUpdate = this.list[rangeIndex].range;
        const length = rangeToUpdate.end - rangeToUpdate.start;
        const newLength = length * factor;
        const delta = newLength - length;
        rangeToUpdate.end += delta;
        if (propagate) {
            for (let i = rangeIndex + 1; i < this.list.length; i++) {
                this.list[i].range.start += delta;
                this.list[i].range.end += delta;
            }
        }
    }
    isNonOverlapping(newRange: Range) {
        return this.list.every(
            ({ range }) => newRange.end <= range.start || newRange.start >= range.end,
        );
    }
    addNonOverlappingRange(newRange: Range, associatedObject: T) {
        if (this.isNonOverlapping(newRange)) {
            this.addRange(newRange, associatedObject);
            return true;
        }
        return false;
    }
    findNonOverlappingStart(length: number, searchRange: Range) {
        const { start: searchStart, end: searchEnd } = searchRange;
        if (this.list.length === 0) return searchStart;
        for (let i = 0; i < this.list.length - 1; i++) {
            const currentEnd = this.list[i].range.end;
            const nextStart = this.list[i + 1].range.start;
            if (
                currentEnd >= searchStart &&
                nextStart <= searchEnd &&
                nextStart - currentEnd >= length
            ) {
                return currentEnd;
            }
        }
        const lastEnd = this.list[this.list.length - 1].range.end;
        if (lastEnd + length <= searchEnd) {
            return lastEnd;
        }
        return null;
    }
    findNonOverlappingStarts(length: number, searchRange: Range) {
        const { start: searchStart, end: searchEnd } = searchRange;
        const nonOverlappingStarts = [];
        if (this.list.length === 0) {
            if (searchEnd - searchStart >= length) nonOverlappingStarts.push(searchStart);
            return nonOverlappingStarts;
        }
        for (let i = 0; i < this.list.length - 1; i++) {
            const currentEnd = this.list[i].range.end;
            const nextStart = this.list[i + 1].range.start;
            if (
                currentEnd >= searchStart &&
                nextStart <= searchEnd &&
                nextStart - currentEnd >= length
            ) {
                nonOverlappingStarts.push(currentEnd);
            }
        }
        const lastEnd = this.list[this.list.length - 1].range.end;
        if (lastEnd + length <= searchEnd) {
            nonOverlappingStarts.push(lastEnd);
        }
        return nonOverlappingStarts;
    }

    addNonOverlappingLength(
        length: number,
        searchRange: Range,
        associatedObject: T,
        isRandom = false,
    ) {
        const nonOverlappingStarts = this.findNonOverlappingStarts(length, searchRange);
        if (nonOverlappingStarts.length === 0) return false;
        const start = isRandom
            ? nonOverlappingStarts[Math.floor(Math.random() * nonOverlappingStarts.length)]
            : nonOverlappingStarts[0];
        const end = start + length;
        const newRange = new Range(start, end);
        if (this.isNonOverlapping(newRange)) {
            this.addRange(newRange, associatedObject);
            return true;
        }
        return false;
    }
    findGaps(searchRange?: Range): [Range, Range | null, Range | null][] {
        const gaps: [Range, Range | null, Range | null][] = [];
        const ranges = this.list.map((item) => item.range);
        if (ranges.length === 0) return gaps;
        const totalStart = ranges[0].start;
        const totalEnd = ranges[ranges.length - 1].end;
        const effectiveRange = searchRange || new Range(totalStart, totalEnd);
        let previousEnd = effectiveRange.start;
        for (let i = 0; i < ranges.length; i++) {
            const currentStart = ranges[i].start;
            if (currentStart > previousEnd) {
                gaps.push([
                    new Range(previousEnd, currentStart),
                    ranges[i - 1] || null,
                    ranges[i] || null,
                ]);
            }
            previousEnd = ranges[i].end;
        }
        if (previousEnd < effectiveRange.end) {
            gaps.push([
                new Range(previousEnd, effectiveRange.end),
                ranges[ranges.length - 1] || null,
                null,
            ]);
        }
        return gaps;
    }

    fillGapWithWithLengthAndUpdate(
        gapRange: Range,
        length: number,
        newAssociatedObject: NewObject<T>,
    ) {
        // const newRanges = [];
        let currentStart = gapRange.start;
        while (currentStart + length <= gapRange.end) {
            const newRange = new Range(currentStart, currentStart + length);
            let associatedObject = newAssociatedObject();
            //newRanges.push({ range: newRange, associatedObject: associatedObject });
            this.addRange(newRange, associatedObject);
            currentStart += length;
        }

        // this.list = this.list.map(item => {
        //     if (item.range.start >= gapRange.end) {
        //         item.range.start += length;
        //         item.range.end += length;
        //     }
        //     return item;
        // });
        // newRanges.forEach(({ range, associatedObject }) => this.addRange(range, associatedObject));

        if (currentStart < gapRange.end) {
            let extraDelta = gapRange.end - currentStart;
            return new Range(gapRange.end - extraDelta, gapRange.end);
        }
        return undefined;
    }
    shrinkGapToZeroAndUpdate(gapRange: Range) {
        const gapLength = gapRange.end - gapRange.start;

        for (let index = 0; index < this.list.length; index++) {
            const item = this.list[index];
            if (item.range.start >= gapRange.end) {
                item.range.start -= gapLength;
                item.range.end -= gapLength;
            }
        }
        // this.list = this.list.map(item => {
        //     if (item.range.start >= gapRange.end) {
        //         item.range.start -= gapLength;
        //         item.range.end -= gapLength;
        //     }
        //     return item;
        // });
        return gapRange.start;
    }

    // findFirstGap() {
    //     for (let i = 0; i < this.list.length - 1; i++) {
    //         if (this.list[i].range.end < this.list[i + 1].range.start) {
    //             return new Range(this.list[i].range.end, this.list[i + 1].range.start);
    //         }
    //     }
    //     return null;
    // }
    findFirstGap() {
        if (this.list.length === 0) {
            return null;
        }
        // Check for a gap from zero to the start of the first item
        if (this.list[0].range.start > 0) {
            return new Range(0, this.list[0].range.start);
        }
        // Check for gaps between items
        for (let i = 0; i < this.list.length - 1; i++) {
            if (this.list[i].range.end < this.list[i + 1].range.start) {
                return new Range(this.list[i].range.end, this.list[i + 1].range.start);
            }
        }
        return null;
    }
}
