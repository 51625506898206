import { Action } from "../actions/action.ts";
import { SceneObjectInterface, SceneObjectInterfaceExtensions } from "../SceneObjectInterface.ts";

export class ToggleInteractiveVisibility extends Action {
    value;
    constructor(verb: string, name: string | undefined, json?: any) {
        super(verb, name);
        this.value = json["value"];
    }

    run(interactive: SceneObjectInterface | undefined) {
        console.log("action:ToggleInteractiveVisibility");
        const scene = SceneObjectInterfaceExtensions.sceneAsScene(interactive);

        const found = scene?.findInteractiveByName(this.value, false);

        if (found) {
            found.setHidden(!found.getHidden());
            scene?.icanvas.invalidate();
        }
    }
}
