export class TimeUtils {
    static MILLISECONDS_PER_SECOND = 1000;
    static MILLISECONDS_PER_MINUTE = 60000;
    static MILLISECONDS_PER_HOUR = 3600000;
    static MILLISECONDS_PER_DAY = 86400000;

    /**
     * Converts seconds to milliseconds.
     * @param {number} seconds - The number of seconds.
     * @returns {number} The equivalent milliseconds.
     */
    static secondsToMilliseconds(seconds: number) {
        return seconds * 1000;
    }
    static secondsToWholeMilliseconds(seconds: number) {
        return Math.floor(seconds * 1000);
    }
    /**
     * Converts milliseconds to seconds.
     * @param {number} milliseconds - The number of milliseconds.
     * @returns {number} The equivalent seconds.
     */
    static millisecondsToSeconds(milliseconds: number) {
        return milliseconds / 1000;
    }
    /**
     * Converts minutes to milliseconds.
     * @param {number} minutes - The number of minutes.
     * @returns {number} The equivalent milliseconds.
     */
    static minutesToMilliseconds(minutes: number) {
        return minutes * 60 * 1000;
    }
    /**
     * Converts minutes to milliseconds.
     * @param {number} minutes - The number of minutes.
     * @returns {number} The equivalent milliseconds.
     */
    static minutesToWholeMilliseconds(minutes: number) {
        return Math.floor(minutes * 60 * 1000);
    }
    /**
     * Converts hours to milliseconds.
     * @param {number} hours - The number of hours.
     * @returns {number} The equivalent milliseconds.
     */
    static hoursToMilliseconds(hours: number) {
        return hours * 60 * 60 * 1000;
    }
}
