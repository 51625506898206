export class InteractiveLayerJson {
    json: any;

    constructor(json: any) {
        this.json = json;
    }

    clone() {
        return new InteractiveLayerJson(this.json);
    }
}
