import { v4 as uuidv4 } from "uuid";
import { Actions } from "../../sceneGraph/actions/actions.ts";
import { UserInterfaceElement } from "./UserInterfaceElement.ts";

export class ButtonUserInterfaceElement extends UserInterfaceElement {
    content: string;
    element: HTMLButtonElement;
    actions: Actions = new Actions();

    constructor(elements: any, json: any) {
        super(elements, json);
        this.content = json.content;
        this.actions.parseJson(json, this.elements.application);
    }

    addToDOM(): void {
        const id = uuidv4();

        this.element = Object.assign(document.createElement("button"), {
            id: id,
        }) as HTMLButtonElement;
        this.element.innerHTML = this.content;

        if (this.json.margin) {
            this.element.style.margin = this.json.margin;
        }

        this.element.addEventListener("click", () => {
            this.onClick();
        });

        this.elements.div.appendChild(this.element);
    }

    onClick(): void {
        this.actions.runActions(this.elements);
    }
}
