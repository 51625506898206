import { WebApplication } from "../webApplication.ts";
import { ConsoleMessageElement } from "./ConsoleMessageElement.ts";
import { HTMLScreenOverlay } from "./HTMLScreenOverlay.ts";

export class ConsoleLogItem {
    level: string;
    message: string;
}

export class ConsoleOverlay extends HTMLScreenOverlay {
    static MessageCountLimit = 12;
    item_set_count = 0;
    item_count = 0;
    isInitializedListeners = false;
    elements: ConsoleMessageElement[];
    isListenToGlobalConsole: boolean;

    constructor(container_name: string, isListenToGlobalConsole = true) {
        super();
        this.container_names = [container_name];
        this.isListenToGlobalConsole = isListenToGlobalConsole;
    }

    initialize(application: WebApplication) {
        this.enabled_setting = WebApplication.isShowConsoleLogOverlaySettingName;
        this.enabled_timeout_setting = WebApplication.isConsoleLogOverlayTimeoutSettingName;

        super.initialize(application);
        this.elements = [];

        this.setupElements();
        super.setupFadeout();

        this.onEnabledChanged(this.enabled);
    }

    onEnabled() {
        super.onEnabled();

        this.initializeListeners();
    }

    initializeListeners() {
        if (this.isListenToGlobalConsole && !this.isInitializedListeners) {
            this.listenToGlobalUncaughtExceptions();
            this.listenToGlobalConsoleLogMessages();
            this.isInitializedListeners = true;
        }
    }

    listenToGlobalUncaughtExceptions() {
        window.addEventListener("error", (event: ErrorEvent) => {
            this.handleUncaughtException(event);
        });

        // Listen for unhandled promise rejections
        window.addEventListener("unhandledrejection", (event: PromiseRejectionEvent) => {
            this.handleUncaughtRejection(event);
            //console.error("Unhandled promise rejection:", event.reason);

            // Similar error handling as in handleUncaughtException
        });
    }

    listenToGlobalConsoleLogMessages(): void {
        ["log", "error", "warn", "info", "debug"].forEach((method) => {
            const originalMethod = console[method as keyof Console] as (...args: any[]) => void;
            (console as any)[method] = (...args: any[]): void => {
                // Call the original console method
                originalMethod.apply(console, args);
                // Pass the message to our custom handler
                const logItem: ConsoleLogItem = { level: method, message: args.join(" ") };
                this.handleConsoleMessage(logItem);
            };
        });
    }

    increment_item_set() {
        this.item_set_count += 1;
        this.item_count = 0;
    }

    addElementForMessage() {
        let container = document.getElementById(this.container_names[0]);

        if (container === null) {
            return undefined;
        }

        let element = document.createElement("p");
        element.style.margin = "1px";
        element.classList.add("consoleLog-item");
        //container.appendChild(element);

        let element_item = new ConsoleMessageElement();
        element_item.html_element = element;
        element_item.item_set_count = this.item_set_count;
        element_item.item_count = this.item_count;
        this.item_count += 1;

        container.insertBefore(element, container?.firstChild);
        this.elements.push(element_item);

        if (this.elements.length > ConsoleOverlay.MessageCountLimit) {
            let first = this.elements.shift();
            if (first) container.removeChild(first.html_element);
        }

        return element_item;
    }

    getLatestMessage() {
        if (this.elements === undefined || this.elements.length === 0) {
            return undefined;
        }

        return this.elements[this.elements.length - 1];
    }

    writeConsoleMessage(level: string, message_string: string) {
        let element_item = this.addElementForMessage();

        if (element_item === undefined) {
            return;
        }

        let message = {
            level: level,
            message: message_string,
        };

        element_item.console_message_data = message;
        element_item.updateElement();
    }

    handleConsoleMessage(message: ConsoleLogItem) {
        let latest = this.getLatestMessage();
        if (latest?.isSameAsConsoleLog(message)) {
            latest.incrementDuplicateCount();
            return;
        }

        let element_item = this.addElementForMessage();

        if (element_item === undefined) {
            return;
        }

        element_item.console_message_data = message;
        element_item.updateElement();
    }

    handleUncaughtException(event: ErrorEvent) {
        let element_item = this.addElementForMessage();

        if (element_item === undefined) {
            return;
        }

        element_item.uncaught_exception_data = event;
        element_item.updateElement();
    }

    handleUncaughtRejection(event: PromiseRejectionEvent) {
        let element_item = this.addElementForMessage();

        if (element_item === undefined) {
            return;
        }

        element_item.uncaught_rejection_data = event;
        element_item.updateElement();
    }

    setupElements() {}

    update() {}
}
