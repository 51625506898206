import { TimeUtils } from "../../TileUtils";
import { WebApplicationSettings } from "../../WebApplicationSettings";
import { SceneGraphAudioVisualStateBaseComponent } from "./SceneGraphAudioVisualStateBaseComponent";
import { SceneGraphAudioVisualStateEventComponent } from "./SceneGraphAudioVisualStateEventComponent";
import { SceneGraphAudioVisualState } from "./SceneGraphAudioVisualState";
import { AudioVisualPlaybackQueue } from "../../audio_visual/visual/AudioVisualPlaybackQueue";
import { UpdateContext } from "../../update";

export class AudioVisualStateEventsScheduler {
    static ScheduleTimeAheadDurationMS = TimeUtils.secondsToWholeMilliseconds(15);

    state: SceneGraphAudioVisualState;
    firstRefreshTime?: number;
    lastRefreshTime?: number;

    constructor(state: SceneGraphAudioVisualState) {
        this.state = state;
    }

    refresh(playbackQueue: AudioVisualPlaybackQueue, update_context: UpdateContext): void {
        const now_time = update_context.time;

        if (
            playbackQueue.application.settings.getSetting(
                WebApplicationSettings.isAudioVideoScriptLoggingEnabled_SettingName,
            )
        ) {
            // console.log(`SceneGraphAudioVisualState::refreshPlaybackQueueFromComponentsAndEvents ${now_time}`)
        }

        let scheduleStart = 0;

        if (this.firstRefreshTime === undefined) {
            this.firstRefreshTime = now_time;
        }

        const relative_now_time = now_time - this.firstRefreshTime;

        if (playbackQueue.item_queue.list.length === 0) {
            // No items in queue
        } else {
            scheduleStart = playbackQueue.getEndOfLastItem() || 0;
        }

        if (
            scheduleStart - relative_now_time >
            AudioVisualStateEventsScheduler.ScheduleTimeAheadDurationMS
        ) {
            return;
        }

        this.scheduleEvents(relative_now_time, playbackQueue, scheduleStart);

        const components = this.state.base_component;

        this.fillGaps(playbackQueue, components);

        const scheduledTo =
            scheduleStart + AudioVisualStateEventsScheduler.ScheduleTimeAheadDurationMS;

        this.fillToAheadOfTime(playbackQueue, scheduledTo, components);

        this.lastRefreshTime = now_time;
    }

    fillToAheadOfTime(
        playbackQueue: AudioVisualPlaybackQueue,
        scheduledTo: number,
        components: SceneGraphAudioVisualStateBaseComponent,
    ): void {
        const startAtFillToAheadOfTime = playbackQueue.getEndOfLastItem() || 0;

        if (startAtFillToAheadOfTime < scheduledTo) {
            const fillWith = components.medium;
            const fileWithInfo = playbackQueue.sceneObject.getVideoFileInfoForStem(fillWith);
            if (fileWithInfo === undefined || fileWithInfo.duration === undefined) {
                return;
            }
            const duration = TimeUtils.secondsToWholeMilliseconds(fileWithInfo.duration);

            const delta = scheduledTo - startAtFillToAheadOfTime;
            let latestScheduled;

            if (delta >= duration) {
                const limit = Math.floor(delta / duration);

                if (limit > 0) {
                    for (let index = 0; index < limit; index++) {
                        const item = this.state.createPlaybackQueueItemFromJsonObject(
                            fillWith,
                            false,
                            false,
                            components.name,
                            SceneGraphAudioVisualStateBaseComponent.QueueItemType,
                        );
                        latestScheduled = playbackQueue.pushItem(item);
                    }

                    if (
                        playbackQueue.application.settings.getSetting(
                            WebApplicationSettings.isAudioVideoScriptLoggingEnabled_SettingName,
                        )
                    ) {
                        // console.log(`av_script:event scheduled #${limit} time=${new TimeRange(startAtFillToAheadOfTime, latestScheduled.end)} video.name=${fillWith.video}`);
                    }
                }
            }
        }
    }

    fillGaps(
        playbackQueue: AudioVisualPlaybackQueue,
        components: SceneGraphAudioVisualStateBaseComponent,
    ): void {
        const fillGapCountLimit = 5;

        for (let index = 0; index < fillGapCountLimit; index++) {
            const gap = playbackQueue.item_queue.findFirstGap();
            if (gap === null) {
                break;
            }
            const fillWith = components.medium;
            const fileWithInfo = playbackQueue.sceneObject.getVideoFileInfoForStem(fillWith);
            if (fileWithInfo === undefined || fileWithInfo.duration === undefined) {
                return;
            }
            const duration = TimeUtils.secondsToWholeMilliseconds(fileWithInfo.duration);

            const extraGap = playbackQueue.item_queue.fillGapWithWithLengthAndUpdate(
                gap,
                duration,
                () => {
                    const item = this.state.createPlaybackQueueItemFromJsonObject(
                        fillWith,
                        false,
                        false,
                        components.name,
                        SceneGraphAudioVisualStateBaseComponent.QueueItemType,
                    );
                    return item;
                },
            );

            if (extraGap) {
                if (
                    playbackQueue.application.settings.getSetting(
                        WebApplicationSettings.isAudioVideoScriptLoggingEnabled_SettingName,
                    )
                ) {
                    // console.log(`av_script:event scheduled time=${new TimeRange(gap.start, extraGap.start)} video.name=${fillWith.video}`);
                }

                if (
                    playbackQueue.application.settings.getSetting(
                        WebApplicationSettings.isAudioVideoScriptLoggingEnabled_SettingName,
                    )
                ) {
                    // console.log(`av_script:event un-scheduled time=${extraGap}`);
                }
                playbackQueue.item_queue.shrinkGapToZeroAndUpdate(extraGap);
            } else {
                if (
                    playbackQueue.application.settings.getSetting(
                        WebApplicationSettings.isAudioVideoScriptLoggingEnabled_SettingName,
                    )
                ) {
                    // console.log(`av_script:event scheduled time=${gap} video.name=${fillWith.video}`);
                }
            }
        }
    }

    scheduleEvents(
        now_time: number,
        playbackQueue: AudioVisualPlaybackQueue,
        scheduleStart: number,
    ): void {
        const eventList = this.state.eventsSortedByMostRare();

        for (const each of eventList) {
            if (each.hasLatestOccurrence(playbackQueue)) {
                const lastOccurrence = each.getLatestOccurrence(playbackQueue) || 0;
                const timeAgo = now_time - lastOccurrence;
                if (timeAgo < each.occurrenceIntervalMilliseconds) {
                    continue;
                }
            }

            const variation = each.nextRandomVariation();
            const item = this.state.createPlaybackQueueItemFromJsonObject(
                variation,
                false,
                false,
                each.name,
                SceneGraphAudioVisualStateEventComponent.QueueItemType,
            );

            const duration = TimeUtils.secondsToWholeMilliseconds(
                playbackQueue.getVideoStemFileInfoFor(item)?.duration || 0,
            );
            const randomStart = Math.floor(
                Math.random() * (each.occurrenceIntervalMilliseconds - duration),
            );
            const scheduleAt = scheduleStart + randomStart;

            const scheduled = playbackQueue.item_queue.insertItemAt(
                item,
                scheduleAt,
                scheduleAt + duration,
                true,
            );

            if (scheduled) {
                if (
                    playbackQueue.application.settings.getSetting(
                        WebApplicationSettings.isAudioVideoScriptLoggingEnabled_SettingName,
                    )
                ) {
                    // console.log(`av_script:event scheduled time=${scheduled} video.name=${item.video_stem.video}`);
                }
            } else {
                if (
                    playbackQueue.application.settings.getSetting(
                        WebApplicationSettings.isAudioVideoScriptLoggingEnabled_SettingName,
                    )
                ) {
                    // console.warn(`av_script:event not-scheduled  video.name=${item.video_stem.video}`);
                }
            }
        }
    }
}
