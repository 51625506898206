import { CustomerAccountTokenRewards } from "./customerAccount/CustomerAccountTokenRewards";
import { Resources } from "./resources";
import { Treeview } from "./view/treeview";
import { WebApplication } from "./webApplication";
import { DataLocation, WebApplicationServer } from "./webApplicationServer";

export class CustomerAccount {
    static guest_account_id = "guest";

    static fromServerData(
        resources: Resources,
        application: WebApplication,
        server: WebApplicationServer,
        data: any,
    ) {
        let result = new CustomerAccount(resources, application, server, data.name);
        result.json = data;
        result.location = DataLocation.Cloud;

        if (
            /*application.getSetting("isLoadLocalStorage") &&*/ application.getSetting(
                "isLoadAccountLocalStorage",
            )
        ) {
            if (result.json.name === CustomerAccount.guest_account_id) {
                let from_storage = result.resources.getLocalStorageJsonItem(
                    result.stroageItemName(),
                );
                if (from_storage) {
                    result.json = from_storage;
                }
            }
        }

        return result;
    }

    static fromGuest(
        resources: Resources,
        application: WebApplication,
        server: WebApplicationServer,
    ) {
        let result = new CustomerAccount(
            resources,
            application,
            server,
            CustomerAccount.guest_account_id,
        );
        result.json = {
            name: CustomerAccount.guest_account_id,
        };
        result.location = DataLocation.Client;

        if (
            /*application.getSetting("isLoadLocalStorage") &&*/ application.getSetting(
                "isLoadAccountLocalStorage",
            )
        ) {
            if (result.json.name === CustomerAccount.guest_account_id) {
                let storage_name = result.stroageItemName();
                let from_storage = result.resources.getLocalStorageJsonItem(storage_name);
                if (from_storage) {
                    result.json = from_storage;
                }
            }
        }
        return result;
    }

    resources: Resources;
    name: string;
    json: any; // TODO: create type for this
    server: WebApplicationServer;
    application: WebApplication;
    location: number;
    token_rewards: CustomerAccountTokenRewards[] = [];

    constructor(
        resources: Resources,
        application: WebApplication,
        server: WebApplicationServer,
        name: string,
    ) {
        this.resources = resources;
        this.name = name;
        this.application = application;
        this.server = server;
        this.location = DataLocation.None;
    }

    get json_player() {
        let result = this.json.player;
        if (result === undefined) {
            result = this.json.player = {};
        }
        return result;
    }

    get json_simulation() {
        let result = this.json.simulation;
        if (result === undefined) {
            result = this.json.simulation = {};
        }
        return result;
    }

    addTokenRewardSource(name: string) {
        let result = new CustomerAccountTokenRewards(this, name);
        this.token_rewards.push(result);
        return result;
    }

    stroageItemName() {
        return this.resources.combineJsonResourceNameFromArray([
            this.application.name,
            this.name,
            "account.storage",
        ]);
    }

    initialize() {
        if (this.application.getSetting("isLoadAccountLocalStorage")) {
            if (this.json === undefined) {
                this.json = this.resources.getLocalStorageJsonItem(this.stroageItemName());
                this.location = DataLocation.Client;
            }
        }

        this.saveState();
    }

    saveState() {
        if (this.location === DataLocation.Cloud) {
            this.server.updateCustomerAccount(this.name, this.json);
        }
        if (this.application.getSetting("isSaveAccountLocalStorage")) {
            if (this.location === DataLocation.Client || this.location === DataLocation.Cloud) {
                this.resources.setLocalStorageItemAsJson(this.stroageItemName(), this.json);
            }
        }
    }

    shutdown() {
        this.saveState();
    }

    getAuthorInterfaceName() {
        return "Account";
    }

    createAuthorInterfaceElement() {
        let layout = new Treeview();
        layout.buildElementFromJson(this.json, 1);
        return layout.element;
    }

    addAuthorInterfaceElementToTreeview(treeview: Treeview) {
        let elm = this.createAuthorInterfaceElement();
        treeview.addItem(this.getAuthorInterfaceName(), elm, true);
    }
}
