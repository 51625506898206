import { ScenePathInterface } from "../../sceneGraph/ScenePath.ts";

/**
 *
 */
export class AudioListenerScope {
    static fromScenePath(path: ScenePathInterface, area: any = undefined, region: any = undefined) {
        let r = new AudioListenerScope(path.sceneGraphName, path.sceneName);
        r.area = area;
        r.region = region;
        return r;
    }
    regionName: string | undefined;

    areaName: string | undefined;

    region: any;

    area: any;

    gain: number = 1;

    isPlayingRegion: boolean = false;

    isPlayingArea: boolean = false;

    constructor(
        regionName: string | undefined,
        areaName: string | undefined,
        area: any = undefined,
        region: any = undefined,
    ) {
        this.regionName = regionName;
        this.areaName = areaName;
        this.area = area;
        this.region = region;
    }
}
