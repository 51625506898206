// @ts-check

import { InteractiveCanvas } from "../audio_visual/interactive_canvas";
import { VisualElement } from "../audio_visual/visual/visualElement";
import { Geometry } from "../geometry";

//let c2 = require("c2.js");
import { Rect } from "c2.js";
export interface VisualObjectInterface {
    visual_element?: VisualElement;
}

export interface HasGeometryInterface {
    geometry: RectangleGeometry;
}

export class RectangleGeometry extends Geometry {
    static createGeometryFromJson_Relative(
        json: any,
        visual_obj?: VisualObjectInterface,
        relativeTo?: HasGeometryInterface,
        letterboxedFrom?: HasGeometryInterface,
        pointCoordinatesType = Geometry.CoordinatesType.RELATIVE,
        sizeCoordinatesType = Geometry.CoordinatesType.RELATIVE,
        defaultAbsoluteSize: number[] | undefined = undefined,
    ) {
        let result = new RectangleGeometry();
        result.relativeTo = relativeTo;

        let as_rect = json["geometry.rectangle"];
        if (as_rect) {
            result.initialize(
                as_rect[0],
                as_rect[1],
                as_rect[2],
                as_rect[3],
                pointCoordinatesType,
                sizeCoordinatesType,
            );
        } else {
            let as_point = json["geometry.point"];
            if (as_point) {
                result.letterboxedFrom = letterboxedFrom;

                let w = 0;
                let h = 0;

                let file_info = visual_obj?.visual_element?.get_first_file_info();
                if (file_info && file_info.width !== undefined && file_info.height !== undefined) {
                    w = file_info.width;
                    h = file_info.height;
                    sizeCoordinatesType = Geometry.CoordinatesType.ABSOLUTE;

                    // if (relativeTo) {
                    //   let relativeToRect = relativeTo.geometry.get_absolute_rect_shape();
                    //   w /= relativeToRect.w;
                    //   h /= relativeToRect.h;
                    // } else {
                    //   sizeCoordinatesType = Geometry.CoordinatesType.ABSOLUTE;
                    // }
                }

                result.initialize(
                    as_point[0],
                    as_point[1],
                    w,
                    h,
                    pointCoordinatesType,
                    sizeCoordinatesType,
                );
            } else {
                let w = 0;
                let h = 0;

                if (defaultAbsoluteSize) {
                    w = defaultAbsoluteSize[0];
                    h = defaultAbsoluteSize[1];
                    sizeCoordinatesType = Geometry.CoordinatesType.ABSOLUTE;
                } else {
                    let file_info = visual_obj?.visual_element?.get_first_file_info();
                    if (
                        file_info &&
                        file_info.width !== undefined &&
                        file_info.height !== undefined
                    ) {
                        w = file_info.width;
                        h = file_info.height;
                        sizeCoordinatesType = Geometry.CoordinatesType.ABSOLUTE;
                    }
                }

                result.initialize(0, 0, w, h, pointCoordinatesType, sizeCoordinatesType);
            }
        }
        return result;
    }

    constructor() {
        super();
    }

    initialize(
        x = 0,
        y = 0,
        w = 0,
        h = 0,
        pointCoordinatesType = Geometry.CoordinatesType.ABSOLUTE,
        sizeCoordinatesType = Geometry.CoordinatesType.ABSOLUTE,
    ) {
        //https://c2js.org/docs/classes/geometry.rect.html#constructor
        this.shape = new Rect(x, y, w, h);
        this.geometry = this;
        this.coordinatesTypes = [pointCoordinatesType, sizeCoordinatesType];
    }

    setPosition(point: any) {
        this.shape.p = point.copy();
    }

    draw(icanvas: InteractiveCanvas, drawScope: number) {
        let absolute_shape = this.get_absolute_shape();
        icanvas.draw_point(absolute_shape.p, 3, drawScope);
        icanvas.draw_rect(absolute_shape, drawScope, this.border_size);
    }

    get_absolute_shape(): Rect {
        let result = this.shape.copy();

        if (this.coordinatesTypes[0] === Geometry.CoordinatesType.RELATIVE) {
            if (this.relativeTo) {
                let relativeToRect = this.relativeTo.geometry.get_absolute_rect_shape();
                result.p.x = relativeToRect.p.x;
                result.p.y = relativeToRect.p.y;

                result.p.x += this.shape.p.x * relativeToRect.w;
                result.p.y += this.shape.p.y * relativeToRect.h;
            }
        }

        if (this.coordinatesTypes[1] === Geometry.CoordinatesType.RELATIVE) {
            if (this.relativeTo) {
                let relativeToRect = this.relativeTo.geometry.get_absolute_rect_shape();
                result.w *= relativeToRect.w;
                result.h *= relativeToRect.h;
            }
        } else {
            if (this.letterboxedFrom) {
                let ratio = this.letterboxedFrom.geometry.get_letterboxIntoRatio();
                result.w *= ratio;
                result.h *= ratio;
            }
        }

        if (this.letterboxInto) {
            let into_ratio = this.get_letterboxIntoRatio();
            result.w *= into_ratio;
            result.h *= into_ratio;

            // return new c2.Rect(0, 0, defaultW * ratio, defaultH * ratio);
        }

        if (this.scale !== 1) {
            result.w *= this.scale;
            result.h *= this.scale;
        }

        return result;
    }

    get_letterboxIntoRatio() {
        if (this.letterboxInto) {
            let letterboxIntoRect = this.letterboxInto.geometry.get_absolute_rect_shape();
            let hRatio = letterboxIntoRect.w / this.getAbsoluteWidth();
            let vRatio = letterboxIntoRect.h / this.getAbsoluteHeight();
            let ratio = Math.min(hRatio, vRatio);
            return ratio;
        }
        return 1;
    }

    get_absolute_rect_shape() {
        return this.get_absolute_shape();
    }

    containsPoint(point: any) {
        let absolute_shape = this.get_absolute_shape();
        return absolute_shape.intersects(point) === true;
    }

    getAbsoluteWidth() {
        return (
            this.shape.w *
            this.scale *
            (this.coordinatesTypes[1] === Geometry.CoordinatesType.RELATIVE && this.relativeTo
                ? this.relativeTo.geometry.get_absolute_rect_shape().w
                : 1)
        );
    }

    getAbsoluteHeight() {
        return (
            this.shape.h *
            this.scale *
            (this.coordinatesTypes[1] === Geometry.CoordinatesType.RELATIVE && this.relativeTo
                ? this.relativeTo.geometry.get_absolute_rect_shape().h
                : 1)
        );
    }

    translate(dx: number, dy: number) {
        this.shape.p.x += dx;
        this.shape.p.y += dy;
    }
}
