import { AudioListenerScope } from "./AudioListenerScope.ts";

/**
 *
 */
export class AmbianceContext {
    scope: AudioListenerScope | undefined;

    constructor(scope: AudioListenerScope | undefined = undefined) {
        this.scope = scope;
    }

    get area(): any {
        return this.scope?.area;
    }

    get region() {
        return this.scope?.region;
    }

    get regionName(): string | undefined {
        return this.region?.name;
    }

    get areaName(): string | undefined {
        return this.area?.name;
    }
}
