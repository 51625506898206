import { AudioAmbianceSet } from "../audio_visual/audio/audioAmbianceSet.ts";

import { Scene } from "./scene.ts";
import Graph from "graphology";
import { ScenePath } from "./ScenePath.ts";
import { InteractiveLayerJson } from "./InteractiveLayerJson.ts";
import { Resources } from "../resources.ts";
import { Simulation } from "../simulation.ts";

export type CreateSceneItem = (scene: Scene) => InteractiveLayerJson;

export class SceneGraph {
    simulation: Simulation;
    path: string | undefined;
    json: any;
    graph: Graph;
    sceneItemsToPush: CreateSceneItem[] = [];
    parentSceneGraph: SceneGraph | undefined;
    sceneGraphIndex: number | undefined;
    audioAmbiance: AudioAmbianceSet;

    constructor(
        simulation: Simulation,
        json: any,
        parentSceneGraph: SceneGraph | undefined = undefined,
        path: string | undefined = undefined,
        sceneGraphIndex: number | undefined = undefined,
    ) {
        this.simulation = simulation;
        this.parentSceneGraph = parentSceneGraph;
        this.json = json;
        this.path = path;
        this.sceneGraphIndex = sceneGraphIndex;
        this.graph = new Graph();
        this.audioAmbiance = new AudioAmbianceSet(this.name, simulation.audio);

        if (this.parentSceneGraph) {
            this.pushSceneItems(this.parentSceneGraph.sceneItemsToPush);
        }
    }

    initializeFromJson() {
        if (!this.json.resourcePath) {
            this.json.resourcePath = this.name;
        }

        for (const key in this.json) {
            let value = this.json[key];
            this.initializeGraphFromJsonKey(key, value);
        }

        this.json.sceneCommon?.interactiveLayers?.forEach((element: any) => {
            this.sceneItemsToPush.push((scene) => new InteractiveLayerJson(element));
        });

        this.pushSceneItems(this.sceneItemsToPush);

        this.graph.forEachNode((node, attributes) => {
            if (attributes.scene.audioAmbiance) {
                this.audioAmbiance.addAmbiance(attributes.scene.audioAmbiance);
            }
        });
    }

    get resources() {
        return this.simulation.resources;
    }
    get icanvas() {
        return this.simulation.icanvas;
    }
    get sceneGraphSet() {
        return this.simulation.sceneGraphSet;
    }

    get account() {
        return this.simulation.account;
    }
    get application() {
        return this.simulation.application;
    }

    collectScenes(list: Scene[]) {
        this.graph.forEachNode((node, attributes) => {
            list.push(attributes.scene);
        });
    }

    pushSceneItems(from: CreateSceneItem[]) {
        for (const eachItem in from) {
            this.graph.forEachNode((node, attributes) => {
                let eachScene: Scene = attributes.scene;
                let item = from[eachItem];
                let result = item(eachScene);
                eachScene.push(result);
            });
        }
    }
    get name() {
        return this.json.name;
    }
    get version() {
        return this.json.version;
    }
    get defaultScene() {
        return this.json.defaultScene;
    }
    get defaultOrFirstScene() {
        let name = this.json.defaultScene;

        let found = this.findSceneByName(name);

        if (!found) {
            found = this.findFirstScene();
        }
        return found;
    }
    get defaultSceneGraph() {
        return this.json.defaultSceneGraph;
    }

    addScene(name: string, json: any, isTemporary = false) {
        let s = new Scene(this, name, json);
        this.graph.addNode(name, { scene: s, isTemporary: isTemporary });
    }
    removeScene(name: string) {
        if (this.graph.hasNode(name)) {
            this.graph.dropNode(name);
        }
    }

    initializeGraphFromJsonKey(key: string, value: any) {
        if (key === "scenes") {
            for (const sceneskey in value) {
                this.addScene(sceneskey, value[sceneskey]);
            }
        }
    }

    async async_fetch_dependencies() {
        let waitFor = [];

        let includes = this.json["includes"];
        if (includes) {
            for (const each of includes) {
                let path = this.resources.getDataFilePathByNameAndExtension(
                    each,
                    Resources.jsonExtension,
                );
                waitFor.push(this.resources.getOrFetchJsonPromise(path));
            }
        }

        await Promise.all(waitFor);
    }

    async async_fetch_defaultSceneGraph() {
        let waitFor = [];

        let default_sg = this.json["defaultSceneGraph"];
        if (default_sg) {
            let path = this.resources.getNavigationGraphPathFromName(default_sg);
            waitFor.push(this.resources.getOrFetchJsonPromise(path));
        }

        let prereqs = this.json["defaultSceneGraph.prerequisite.data"];
        if (prereqs) {
            for (const each of prereqs) {
                let path = this.resources.getDataFilePathByNameAndExtension(
                    each,
                    Resources.jsonExtension,
                );
                waitFor.push(this.resources.getOrFetchJsonPromise(path));
            }
        }

        await Promise.all(waitFor);
    }

    evaluate_json_file(filename: string) {
        let file_path = this.resources.getDataFilePathByNameAndExtension(
            filename,
            Resources.jsonExtension,
        );
        let json = this.resources.getFetchedJson(file_path);
        for (const key in json) {
            let value = json[key];
            this.initializeGraphFromJsonKey(key, value);
        }
    }
    toPath(scene_name = undefined) {
        return new ScenePath(this.name, scene_name);
    }
    toPathWithDefaultScene() {
        return this.toPath(this.defaultScene);
    }

    findSceneByPath(path: ScenePath, isTemporary = false) {
        return this.findSceneByName(path.sceneName, isTemporary);
    }

    findSceneByName(name: string | undefined, isTemporary = false): Scene | undefined {
        let foundScene: Scene | undefined = undefined;
        this.graph.forEachNode((node, attributes) => {
            if (node === name && attributes.isTemporary === isTemporary) {
                foundScene = attributes.scene;
                return false; // Stop iteration
            }
        });
        return foundScene;
    }

    findFirstScene(isTemporary = false): Scene | undefined {
        let foundScene: Scene | undefined = undefined;
        this.graph.forEachNode((node, attributes) => {
            if (attributes.isTemporary === isTemporary) {
                foundScene = attributes.scene;
                return false; // Stop iteration
            }
        });
        return foundScene;
    }
}
