// https://developer.mozilla.org/en-US/docs/Web/API/Touch_events/Using_Touch_Events
// https://github.com/zingchart/zingtouch#include-the-library
import { SelectionMode } from "../input/selectionMode.ts";
import { WebApplication } from "../webApplication.ts";
import { InteractiveCanvas } from "./interactive_canvas.ts";

import { InteractivePlatformCanvas } from "./InteractivePlatformCanvas.ts";

export class InteractiveTVCanvas extends InteractivePlatformCanvas {
    static SonyBrandName = "Sony";
    static HisenseBrandName = "Hisense";
    static LGBrandName = "LG";
    static SamsungBrandName = "Samsung";

    constructor() {
        super();
    }

    uninitialize() {}

    initialize(interactiveCanvas: InteractiveCanvas) {
        super.initialize(interactiveCanvas);
        this.name = "TV";
        this.brand = "unknown";
    }

    apply_to_default_settings() {
        super.apply_to_default_settings();
        this.interactiveCanvas.state.application.setDefaultSetting(
            WebApplication.isPreloadConnectedSceneGraphContentEnabled_SettingName,
            false,
        );

        // if (this.brand === InteractiveTVCanvas.LGBrandName) {
        //     this.interactiveCanvas.state.application.setDefaultSetting(
        //         WebApplication.isAudioVisualScriptsEnabled_SettingName,
        //         false,
        //     );
        // }
    }

    isTouchCapable() {
        return super.isTouchCapable();
    }

    isPlatform() {
        return false;
    }

    modifySelectionMode(selection_mode: SelectionMode) {
        // var r = super.createDefaultSelectionMode();
        // if (this.brand == InteractiveTVCanvas.SonyBrandName || this.brand == InteractiveTVCanvas.HisenseBrandName) {
        //   return new SceneInteractionMode(sim);
        //   selection_mode.input_capability = InputCapability.wasd;
        //   return r;
        // }
        // else {
        //   return super.createDefaultSelectionMode();
        // }
        // return r;
    }

    // select_video_content(set) {
    //   var videos = new VideoFiles(set);
    //   return videos.getVideoPathByQuality(0);
    // }

    // isLikelyTVBrowser() {
    //   const userAgent = navigator.userAgent.toLowerCase();

    //   const tvKeywords = [
    //     'smarttv',
    //     'googletv',
    //     'appletv',
    //     'firetv',
    //     'lgwebostv',
    //     'samsungtv',
    //     'viera',
    //     'netcast',
    //   ];

    //   return tvKeywords.some(keyword => userAgent.includes(keyword));
    // }
}

export class InteractiveBrowserTVCanvas extends InteractiveTVCanvas {
    static isBrowserTV() {
        let found = navigator.userAgent.includes("SmartTV");
        return found;
    }
    constructor() {
        super();
    }

    isPlatform() {
        return InteractiveBrowserTVCanvas.isBrowserTV();
    }

    initialize(interactiveCanvas: InteractiveCanvas) {
        super.initialize(interactiveCanvas);
        this.name = "TV Browser";
        if (navigator.userAgent.includes("WebOS;")) {
            this.brand = InteractiveTVCanvas.LGBrandName;
        }
    }

    uninitialize() {
        super.uninitialize();
    }
}

export class InteractiveATSC3TVCanvas extends InteractiveTVCanvas {
    static isATSC3TV() {
        let atsc3 = navigator.userAgent.includes("ATSC3/");

        return atsc3;
    }
    constructor() {
        super();
    }

    isPlatform() {
        return InteractiveATSC3TVCanvas.isATSC3TV();
    }

    initialize(interactiveCanvas: InteractiveCanvas) {
        super.initialize(interactiveCanvas);
        this.name = "ATSC 3.0 TV";
        if (navigator.userAgent.includes("Samsung")) {
            this.brand = InteractiveTVCanvas.SamsungBrandName;
        } else if (navigator.userAgent.includes("Sony")) {
            this.brand = InteractiveTVCanvas.SonyBrandName;
        } else if (navigator.userAgent.includes("Hisense")) {
            this.brand = InteractiveTVCanvas.HisenseBrandName;
        }
    }

    uninitialize() {
        super.uninitialize();
    }
}
