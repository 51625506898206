import { ResourceCanvasElement } from "../audio_visual/visual/resourceCanvasElements/ResourceCanvasElement";
import {
    ResourceInterface,
    ResourceTypeInterface,
} from "../audio_visual/visual/resources/resourceInterface";
import { FileInfoType, LocalServerFileCache } from "../LocalServerFileCache";

export class FileResource {
    static getPathWithoutExtension(path: string) {
        const lastDotIndex = path.lastIndexOf(".");
        if (lastDotIndex === -1) {
            return path; // No extension found
        }
        return path.substring(0, lastDotIndex);
    }

    static getFullpathFilenameWithoutExtension(filePath: string) {
        const filename = filePath.split(/[\/\\]/).pop(); // Get filename (with extension)
        if (!filename) {
            return filePath;
        }
        const lastDotIndex = filename.lastIndexOf(".");
        if (lastDotIndex === -1) {
            return filename; // No extension found
        }
        return filename.substring(0, lastDotIndex);
    }

    url: string;
    onRunAction: ResourceInterface["onRunAction"];
    url_file_info: FileInfoType | undefined;
    server_file_cache: LocalServerFileCache;
    type: string;

    constructor() {}

    checkForErrors() {
        return false;
    }

    pixel_size() {
        return [0, 0];
    }
}
