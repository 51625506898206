import { CanvasElement } from "./visual/canvasElements/CanvasElement.ts";
import { InteractiveCanvas } from "./interactive_canvas.ts";

export class AutoCanvasElementInvalidation {
    timer?: NodeJS.Timeout;
    elements: CanvasElement[] = [];
    fps: number;
    icanvas: InteractiveCanvas;
    isStop: boolean = false;

    constructor(fps: number, icanvas: InteractiveCanvas) {
        this.fps = fps;
        this.icanvas = icanvas;
    }

    start(): void {
        this.timerCallback();
    }

    removeElement(element: CanvasElement): void {
        const index = this.elements.indexOf(element);
        if (index >= 0) {
            this.elements.splice(index, 1);
        }
    }

    get isEmpty(): boolean {
        return this.elements.length === 0;
    }

    private timerCallback(): void {
        if (this.isStop) {
            return;
        }

        this.invalidateElements();
        this.icanvas.try_invalidated_draw();

        this.timer = setTimeout(() => {
            this.timerCallback();
        }, this.milliseconds);
    }

    stop(): void {
        this.isStop = true;
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = undefined;
        }
    }

    get milliseconds(): number {
        return (1 / this.fps) * 1000;
    }

    private invalidateElements(): void {
        for (const each of this.elements) {
            each.invalidate();
        }
    }
}
