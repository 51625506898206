import { FileResource } from "./resources/FileResource";
import { FileResourceRequest } from "./resources/FileResourceRequest";
import { AssetDatabase, IndexedDBAssetDataInterface } from "./server/shared/AssetDatabase";
import { StackLayout } from "./view/stackLayout";
import { Treeview } from "./view/treeview";
import { WebApplication } from "./webApplication";
import { WebApplicationServer } from "./webApplicationServer";

export interface AssetObjectInterface {
    src: string;
    onload: (() => void) | ((this: GlobalEventHandlers, ev: Event) => any) | null;
}

export type FileInfoType = {
    now: string | undefined;
    path: string;
    size: number;
    md5_hash: string;
    width: number | undefined;
    height: number | undefined;
    duration: number | undefined;
};

export class HTMLElementCacheItem {
    /**
     * html element cached
     */
    htmlElement: HTMLElement;

    constructor(htmlElement: HTMLElement) {
        this.htmlElement = htmlElement;
    }
}

export class BinaryCacheItem {
    /**
     * binary element cached
     */
    data: ArrayBuffer;

    constructor(data: ArrayBuffer) {
        this.data = data;
    }
}

export class LocalServerFileCache {
    server: WebApplicationServer;
    server_asset_list: any[];
    server_asset_lookup: any;
    db_promise: Promise<any>;
    //db_upgrade_promise;
    db: IDBDatabase;
    asset_database: AssetDatabase;
    application: WebApplication;
    file_element_cache: Map<string, HTMLElementCacheItem>;
    file_binary_cache: Map<string, BinaryCacheItem>;

    constructor(server: WebApplicationServer, application: WebApplication) {
        this.server = server;
        this.application = application;
        this.asset_database = new AssetDatabase();
        this.file_element_cache = new Map();
        this.file_binary_cache = new Map();
    }

    get_additional_manifest_entries_for_file_request(request: FileResourceRequest) {
        let result = [];
        let look_for = FileResource.getPathWithoutExtension(request.fullpath);
        look_for += ".";

        for (let index = 0; index < this.server_asset_list.length; index++) {
            const each = this.server_asset_list[index];
            let each_path = each.path;

            if (!each_path || each_path === request.fullpath) {
                continue;
            }

            // each_path = FileResource.getPathWithoutExtension(each_path);

            if (each_path.startsWith(look_for)) {
                result.push(each_path);
            }
        }
        return result;
    }

    findFile(path: string) {
        let found = this.server_asset_lookup[path];
        return found;
    }

    findFileWithResourcePath(
        name: string,
        resource_path: string,
        resourcePath2: string,
        extension: string,
        category: string,
    ): FileInfoType | undefined {
        let request = new FileResourceRequest(
            resource_path,
            name,
            extension,
            category,
            resourcePath2,
            true,
            false,
            false,
            undefined,
        );
        let path = request.fullpath;
        let found = this.server_asset_lookup[path];
        return found;
    }

    collectEditableProperties(layout: StackLayout) {
        if (this.server_asset_list) {
            let server_asset_list_view = new Treeview();
            server_asset_list_view.buildElementFromJson(this.server_asset_list, 1);
            layout.add(server_asset_list_view.element);
        }
    }

    // createNewDatabase(database, resolve) {
    //   database.createObjectStore(AssetDatabase.storeName, { keyPath: "path" /*, autoIncrement: true*/ });
    //   resolve();
    // }

    async initialize() {
        //self = this;

        let fromLocalStorage = this.server.resources.getLocalStorageJsonItem(
            this.stroageItemName(),
        );
        this.asset_database.loadFromJson(fromLocalStorage);

        this.server_asset_list = this.server.resources.getFetchedJson(
            this.server.resources.getFileManifestJsonPath(),
        );

        this.server_asset_lookup = this.server_asset_list.reduce((acc, item) => {
            if (item.path) {
                acc[item.path] = item;
            }
            return acc;
        }, {});

        if (!this.application.getSetting("isPreCacheAssets")) {
            return;
        }

        this.db_promise = new Promise((resolve, reject) => {
            const request = window.indexedDB.open(AssetDatabase.dbName, 5);

            request.onerror = (event) => {
                console.error("error: indexedDB");
                reject(request.error); // Rej
            };

            request.onsuccess = (event) => {
                console.log("using: indexedDB");
                resolve(request.result);
                // self.db = self.db_promise.result;
            };

            request.onupgradeneeded = (event) => {
                //this.db_upgrade_promise = new Promise((resolve) => {
                //let db = event.target.result;
                // if (db.objectStoreNames.contains(AssetDatabase.storeName)) {
                //   db.deleteObjectStore(AssetDatabase.storeName);
                //   self.createNewDatabase(db, resolve);
                // } else {
                //   self.createNewDatabase(db, resolve);
                // }
                //  });
            };
        });

        try {
            this.db = await this.db_promise;
        } catch (error) {
            console.error("Error opening database:", error);
            return;
        }

        if (this.db) {
            this.asset_database.setDataInterface(new IndexedDBAssetDataInterface(this.db));

            //await this.asset_database.downloadNewAssets(this.server_asset_list);
        }
    }

    stroageItemName() {
        return this.server.resources.combineJsonResourceNameFromArray([
            this.server.application.name,
            "server_file.storage",
        ]);
    }

    saveState() {
        //this.asset_database.json.db_version = this.db.version;
        let asset_db = this.asset_database.saveToJson();
        //asset_db={};
        this.server.resources.setLocalStorageItemAsJson(this.stroageItemName(), asset_db);
    }

    shutdown() {
        this.saveState();
    }

    // startFetchPromise(url) {
    // }

    loadFromCacheOrUrl(assetObject: AssetObjectInterface, url: string) {
        let is_enabled = this.application.getSetting("isPreCacheAssets");
        this.asset_database.loadFromCacheOrUrl(assetObject, url, is_enabled);
    }

    RemoveHTMLElementFromCache(url: string) {
        this.file_element_cache.delete(url);
    }

    AddHTMLElementToCache(url: string, htmlElemnt: HTMLElement) {
        this.file_element_cache.set(url, new HTMLElementCacheItem(htmlElemnt));
    }

    TryGetHTMLElementFromCache(url: string) {
        if (this.application?.getSetting(WebApplication.IsVerboseLoggingSettingName)) {
            //console.log(`htmlElement:cache:request ${url}`);
        }

        let found = this.file_element_cache.get(url);

        if (found) {
            return found.htmlElement;
        }

        return undefined;
    }

    clearFileBinaryCache() {
        this.file_binary_cache.clear();
    }

    removeBinaryFromCache(url: string) {
        this.file_binary_cache.delete(url);
    }

    addBinaryToCache(url: string, data: ArrayBuffer) {
        this.file_binary_cache.set(url, new BinaryCacheItem(data));
    }

    tryGetBinaryFromCache(url: string) {
        if (this.application?.getSetting(WebApplication.IsVerboseLoggingSettingName)) {
            //console.log(`binary:cache:request ${url}`);
        }

        let found = this.file_binary_cache.get(url);

        if (found) {
            return found.data;
        }

        return undefined;
    }
}
