import { InteractiveCanvas } from "../audio_visual/interactive_canvas";
import { KeyCodes } from "../input/KeyCodes";
import { Interactive } from "./Interactive";
import { InteractiveEvent } from "./InteractiveEvent";

export class InteractiveInput {
    static typename = "interactive.input";
    interactive: Interactive;
    verb: string;
    verb_value: any;

    keydown = (ievent: InteractiveEvent): void => {
        this.interactive.scene.simulation.selectionMode.onInteractiveInputKeydown(ievent, this);
    };
    keyup = (ievent: InteractiveEvent): void => {
        this.interactive.scene.simulation.selectionMode.onInteractiveInputKeyup(ievent, this);
    };

    constructor(interactive: Interactive, json: any) {
        this.interactive = interactive;
        const [firstProperty, firstValue] = Object.entries(json)[0];
        this.verb = firstProperty;
        this.verb_value = firstValue;
    }
    drawFrame(icanvas: InteractiveCanvas): void {}

    activate(ievent: InteractiveEvent): void {
        for (const property in ievent.activate_value_context) {
            if (
                this.verb === property &&
                this.verb_value === ievent.activate_value_context[property]
            ) {
                this.interactive.onInteractiveInput(ievent, this);
                break;
            }
        }
    }

    onTouchTap(ievent: InteractiveEvent): void {}
    onTouchPan(ievent: InteractiveEvent): void {}
    onTouchSwipe(ievent: InteractiveEvent): void {
        let touchEvent = ievent.e_touchevent;
        if (touchEvent === undefined) return;

        // disable swipe until test on device

        // let event = touchEvent.events[0];
        // let data = touchEvent.data[0];
        // let dir = data.currentDirection;

        // // Correctly assigning a single key string based on swipe direction
        // if (dir > 180 - 20 && dir < 180 + 20) {
        //     ievent.e.keyCode = KeyCodes.ArrowRight;
        // } else if (dir > 360 - 20 || dir < 20) {
        //     ievent.e.keyCode = KeyCodes.ArrowLeft;
        // } else if (dir > 270 - 20 && dir < 270 + 20) {
        //     ievent.e.keyCode = KeyCodes.ArrowUp;
        // } else if (dir > 90 - 20 && dir < 90 + 20) {
        //     ievent.e.keyCode = KeyCodes.ArrowDown;
        // }

        // if (ievent.e.keyCode) {
        //     this.keydown(ievent); // Process the synthesized key press
        // }
    }
    onTouchDistance(ievent: InteractiveEvent): void {}
    onTouchRotate(ievent: InteractiveEvent): void {}
    onTouchGesture(ievent: InteractiveEvent): void {}
}
