import * as htmlHelper from "../../htmlHelper.ts";
import { UserInterfaceElement } from "./UserInterfaceElement.ts";

export class BreakUserInterfaceElement extends UserInterfaceElement {
    element: HTMLElement;

    constructor(elements: any, json: any) {
        super(elements, json);
    }

    addToDOM(): void {
        this.element = htmlHelper.htmlToElement(`<br/>`) as HTMLElement;
        this.elements.div.appendChild(this.element);
    }
}
