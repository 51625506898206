import { WebAudioInterface } from "./audio_visual/audio/WebAudioComponent.ts";
import { InteractiveCanvas } from "./audio_visual/interactive_canvas.ts";
import { AuthInterface, InternalAuth } from "./auth/InternalAuth.ts";
import { CustomerAccount } from "./customerAccount.ts";
import { Resources } from "./resources.ts";
import { StaticRouter } from "./routing/StaticRouter.ts";
import { AuthorInterface } from "./sceneAuthorInterface/authorInterface.ts";
import { Simulation } from "./simulation.ts";
import { WebApplication } from "./webApplication.ts";
import { WebApplicationServer } from "./webApplicationServer.ts";
import { WebBrowserClient } from "./webBrowserClient.ts";
import { ExternalModules } from "./modules/ExternalModules.ts";
import { InternalModules } from "./modules/internalModules.ts";
import { SceneModules } from "./modules/sceneModules.ts";
import { MeidaSourcePlayerComponent } from "./audio_visual/visual/MediaSourcePlayerComponent.ts";

import { RemoteHandler } from "./remote/RemoteHandler.ts";

export class WebApplicationState {
    auth: InternalAuth;
    router: StaticRouter;
    remoteHandler: RemoteHandler;
    clientVersionNumber: string;
    libraryVersionNumber: string;
    resources: Resources;
    application: WebApplication;
    browser: WebBrowserClient;
    canvas: InteractiveCanvas;
    account: CustomerAccount;
    simulation: Simulation;
    server: WebApplicationServer;
    author: AuthorInterface;
    audio: WebAudioInterface;
    externalModules: ExternalModules;
    internalModules: InternalModules;
    sceneModules: SceneModules;
    msePlayer: MeidaSourcePlayerComponent;

    saveState() {
        this.auth?.saveState();
        this.simulation?.saveState();
        this.server?.saveState();
        this.browser?.saveState();
        this.account?.saveState();
        this.application?.saveState();
        this.author?.saveState();
        this.audio?.saveState();
        this.externalModules?.saveState();
        this.internalModules?.saveState();
        this.sceneModules?.saveState();
        this.msePlayer?.saveState();
    }

    // shutdownState() {
    //   this.auth?.shutdown()
    //   this.simulation?.shutdown();
    //   this.simulation = undefined;
    //   this.server?.shutdown();
    //   this.server = undefined;
    //   this.browser?.shutdown();
    //   this.browser = undefined;
    //   this.account?.shutdown();
    //   this.account = undefined;
    //   this.application?.shutdown();
    //   this.application = undefined;
    //   this.author?.shutdown();
    //   this.author = undefined;
    //   this.audio?.shutdown();
    //   this.audio = undefined;
    //   this.externalModules?.shutdown();
    //   this.externalModules = undefined;
    //   this.internalModules?.shutdown();
    //   this.internalModules = undefined;
    //   this.sceneModules?.shutdown();
    //   this.sceneModules = undefined;
    // }
}
