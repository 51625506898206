import { v4 as uuidv4 } from "uuid";
import * as htmlHelper from "../htmlHelper.ts";

// [https://api.jqueryui.com/category/widgets/](https://api.jqueryui.com/category/widgets/)

export class EditableProperty {
    name: string;
    help?: string;
    defaultValue?: any;
    getValue: () => any;
    setValue: (value: any) => void;
    is_enabled: boolean = true;
    valueChanged() {}

    getLabelElement(): HTMLElement | null {
        const text = `<span disabled=${!this.is_enabled}>${this.name}</span>`;
        const result = htmlHelper.htmlToElement(text);
        return result;
    }

    getValueElements(): HTMLElement[] {
        return [];
    }

    getEditorElement(): HTMLElement {
        const items = this.getEditorElements();
        const result = htmlHelper.withBorderlessTableRow(items);
        return result;
    }

    getEditorElements(): (HTMLElement | null)[] {
        const values = this.getValueElements();
        values.forEach((element) => {
            element.style.marginLeft = "8px";
        });

        const result = [this.getLabelElement(), ...values];
        return result;
    }

    setEnabled(value: boolean) {}
}

export class ReadonlyProperty extends EditableProperty {
    constructor() {
        super();
    }

    getValueElements(): HTMLElement[] {
        const result = super.getValueElements();
        const value = this.getValue();
        const element = htmlHelper.htmlToElement(`<span>${value}</span>`);
        if (element) result.push(element);
        return result;
    }
}

// [https://jqueryui.com/spinner/#decimal](https://jqueryui.com/spinner/#decimal)
// [https://api.jqueryui.com/spinner/](https://api.jqueryui.com/spinner/)

export class NumberEditableProperty extends EditableProperty {
    minValue?: number;
    maxValue?: number;
    spinner: HTMLInputElement;
    slider?: HTMLInputElement;

    constructor() {
        super();
    }

    getValueElements(): HTMLElement[] {
        const result = super.getValueElements();
        const id = this.name + uuidv4();
        const get = this.getValue;
        const value = get();

        this.spinner = Object.assign(document.createElement("input"), {
            id: id,
            type: "number",
            step: 0.1,
            class: "form-control",
            value: value,
        });

        this.spinner.onchange = (event) => {
            this.setValue((event.target as HTMLInputElement).value);
        };

        this.spinner.style.width = "64px";
        result.push(this.spinner);

        if (this.minValue !== undefined && this.maxValue !== undefined) {
            this.spinner.min = this.minValue.toString();
            this.spinner.max = this.maxValue.toString();
        }

        return result;
    }
}

// [https://www.w3schools.com/jsref/prop_checkbox_checked.asp](https://www.w3schools.com/jsref/prop_checkbox_checked.asp)

export class BooleanEditableProperty extends EditableProperty {
    html_element?: HTMLInputElement;

    constructor() {
        super();
    }

    getValueElements(): HTMLElement[] {
        const result = super.getValueElements();
        const id = this.name + uuidv4();
        const get = this.getValue;
        const value = get();

        this.html_element = Object.assign(document.createElement("input"), {
            id: id,
            type: "checkbox",
            checked: value,
            disabled: !this.is_enabled,
        });

        this.html_element.onchange = (event) => {
            const v = (event.target as HTMLInputElement).checked;
            this.setValue(v);
        };

        result.push(this.html_element);
        return result;
    }

    setEnabled(value: boolean) {
        this.is_enabled = value;
        if (this.html_element) {
            this.html_element.disabled = !value;
        }
    }
}

export class PropertyEditor {}
