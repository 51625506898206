import { IConsoleLogger } from "../IConsoleLogger.ts";
import { ConsoleOverlay } from "./ConsoleOverlay.ts";

export class DirectConsoleOverlayLogger implements IConsoleLogger {
    consoleOverlay: ConsoleOverlay;
    /**
     *
     */
    constructor(consoleOverlay: ConsoleOverlay) {
        this.consoleOverlay = consoleOverlay;
    }

    log(message: string, ...optionalParams: any[]): void {
        this.sendLog("log", message, optionalParams);
    }
    info(message: string, ...optionalParams: any[]): void {
        this.sendLog("info", message, optionalParams);
    }
    warn(message: string, ...optionalParams: any[]): void {
        this.sendLog("warn", message, optionalParams);
    }
    error(message: string, ...optionalParams: any[]): void {
        this.sendLog("error", message, optionalParams);
    }

    sendLog(level: string, message: string, ...optionalParams: any[]): void {
        this.consoleOverlay.writeConsoleMessage(level, message + " " + optionalParams.join(" "));
    }
}
