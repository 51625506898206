import { ModuleActivationRewards } from "./ModuleActivationRewards";
import { OpenModule } from "./OpenModule";

export class ModuleIntegration {
    openModule: OpenModule | undefined;
    rewards: ModuleActivationRewards | undefined;

    constructor(openModule: OpenModule) {
        this.openModule = openModule;
    }

    get data_context() {
        return this.openModule?.data_context;
    }

    sendMessageToModule(message: any) {
        this.openModule?.iframe.contentWindow?.postMessage(message, "*");
    }

    async start(userId: string) {}

    async windowMessage(event: MessageEvent<any>) {}

    addActivationRewards(rewards: ModuleActivationRewards) {
        this.rewards = rewards;
    }
}
