import { ExternalModule } from "./ExternalModule";
import { ExternalModules } from "../modules/ExternalModules";
import { ModuleIntegration } from "./ModuleIntegration";
import { WebApplicationState } from "../WebApplicationState";
import { ModuleActivationRewards } from "./ModuleActivationRewards";

export class OpenModule {
    module: ExternalModule;
    iframeDivId: string;
    iframe: HTMLIFrameElement;
    externalExtensions: ExternalModules;
    integrations: ModuleIntegration[] = [];
    data_context: any;
    uuid: string;
    isClosed: boolean;
    userId: string;
    state: WebApplicationState;

    get name() {
        return this.module.name;
    }

    constructor(module: ExternalModule, iframeDivId: string, iframe: HTMLIFrameElement, uuid: string, userId: string, state: WebApplicationState) {
        this.module = module;
        this.iframeDivId = iframeDivId;
        this.iframe = iframe;
        this.uuid = uuid;
        this.userId = userId;
        module.addIntegrations(this);
        this.state = state;
    }

    start() {
        this.integrations.forEach(async (element) => {
            await element.start(this.userId);
        });
    }

    tryClose() {
        this.externalExtensions.closeModule(this);
    }

    exit(message?: any) {
        this.tryClose();
    }

    windowMessage(message: MessageEvent<any>) {
        this.integrations.forEach((element) => {
            element.windowMessage(message);
        });
    }

    ready(message?: any) {
        return;
    }

    onStarted(message?: any) {}

    addActivationRewards(rewards: ModuleActivationRewards) {
        this.integrations.forEach((element) => {
            element.addActivationRewards(rewards);
        });
    }
}
