import { Action } from "../actions/action.ts";
import { SceneObjectInterface } from "../SceneObjectInterface.ts";

export class ToggleAction extends Action {
    value = false;
    values = { true: {}, false: {} };
    constructor(verb: string, name?: string, json?: any) {
        super(verb, name);
        this.values.true = json["=true"];
        this.values.false = json["=false"];
    }

    run(interactive: SceneObjectInterface) {
        console.warn("depricated-action:Toggle");
        this.value = !this.value;
        // toggle action is depreicated
        //interactive.apply(this.values[this.value]);
    }
}
