import { AmbianceAudioListener } from "../audio_visual/audio/audioListener";
import { InteractiveEvent } from "./InteractiveEvent";

import { VisualElements } from "../audio_visual/visual/VisualElements";
import { WebApplication } from "../webApplication";
import {
    GettingReadyCallback,
    GettingReadyCallbackCollector,
} from "./GettingReadyCallbackCollector";
import { ScenePath } from "./ScenePath";
import { SceneChangeRequest } from "./SceneChangeRequest";

import { InteractiveCanvas } from "../audio_visual/interactive_canvas.ts";

import { UpdateContext } from "../update.ts";
import { Simulation } from "../simulation";
import { ActiveScene } from "./ActiveScene";
import { WebApplicationSettings } from "../WebApplicationSettings";

let c2 = require("c2.js");

export class CommonScenes {
    static UXSceneName = "ux-scene";
    static UXSceneGraphName = "ux";
}

export class SceneGraphNode {
    activeScene: ActiveScene;
    simulation: Simulation;
    listener: AmbianceAudioListener;
    visual_elements: VisualElements;
    event_scene_change_request_complete?: (request: SceneChangeRequest) => void;
    scene_change_request_in_progress: SceneChangeRequest | undefined;
    scene_change_requests_in_queue: SceneChangeRequest[] = [];

    constructor(simulation: Simulation) {
        this.activeScene = new ActiveScene(this);
        this.simulation = simulation;
        this.visual_elements = new VisualElements(this);
    }

    get application() {
        return this.simulation.application;
    }

    get firstScene() {
        return this.activeScene.firstScene;
    }

    get audio() {
        return this.simulation.audio;
    }

    get resources() {
        return this.simulation.resources;
    }

    get icanvas() {
        return this.simulation.icanvas;
    }

    get visualElements() {
        return this.visual_elements;
    }

    get server() {
        return this.simulation.server;
    }

    get server_file_cache() {
        return this.server.server_file_cache;
    }

    get sceneGraphSet() {
        return this.simulation.sceneGraphSet;
    }

    async initiaize() {
        let a = this.audio;
        this.listener = new AmbianceAudioListener(a);
        this.audio.onStartAudioOnGesture = () => {
            this.onStartAudioOnGesture();
        };
    }

    onStartAudioOnGesture() {
        this.listener?.onStartAudioOnGesture();
    }

    getUXScene() {
        return this.activeScene.ux_scene;
    }

    getActiveScene() {
        return this.activeScene.getActiveScene();
    }

    evaluateSceneChangeProgress() {
        if (
            !this.scene_change_request_in_progress &&
            this.scene_change_requests_in_queue.length > 0
        ) {
            this.scene_change_request_in_progress = this.scene_change_requests_in_queue.shift();
        }

        if (!this.scene_change_request_in_progress) {
            return;
        }

        let request = this.scene_change_request_in_progress;

        // check for error

        if (request.error) {
            if (request.fallback_request_on_error) {
                this.scene_change_request_in_progress = request.fallback_request_on_error;
            } else {
                this.scene_change_request_in_progress = undefined;
                this.event_scene_change_request_complete?.(request);
            }
            request.isCanceled = true;
            console.error(`scene navigation canceled: ${request.error}`);
            this.evaluateSceneChangeProgress();
            return;
        }

        // check for complete

        if (request.isLoadingCompleted) {
            this.scene_change_request_in_progress = undefined;
            this.activeScene.swapInNewActiveScene(request);
            this.event_scene_change_request_complete?.(request);
            this.evaluateSceneChangeProgress();
            return;
        }

        // wait for loading

        if (request.callback_collector_promise) {
            return;
        }

        // load scene graph

        let sceneGraphName = request.location.sceneGraphName;

        if (!this.sceneGraphSet.isSceneGraphFetched(sceneGraphName)) {
            request.sceneGraphLoadingPromise =
                this.sceneGraphSet.fetchSceneGraphPromise(sceneGraphName);
            if (!request.sceneGraphLoadingPromise) {
                request.error = "scene graph loading_promise is undefined";
                this.evaluateSceneChangeProgress();
                return;
            }
            request.sceneGraphLoadingPromise.then((result) => {
                request.sceneGraphLoadingPromise = undefined;
                this.evaluateSceneChangeProgress();
            });
            return;
        }

        // find scene graph

        request.foundSceneGraph = this.sceneGraphSet.getSceneGraph(sceneGraphName);

        if (request.foundSceneGraph === undefined) {
            request.error = `scene graph not found ${sceneGraphName}`;
            this.evaluateSceneChangeProgress();
            return;
        }

        if (request.location.sceneName === undefined) {
            request.location.sceneName = request.foundSceneGraph.defaultOrFirstScene?.name;
        }

        // find scene

        request.foundScene = request.foundSceneGraph.findSceneByName(request.location.sceneName);

        if (request.foundScene === undefined) {
            request.error = `scene not found ${request.location.sceneName}`;
            this.evaluateSceneChangeProgress();
            return;
        }

        request.callback_collector = new GettingReadyCallbackCollector();

        // load ux scene graph

        if (
            !this.sceneGraphSet.isSceneGraphFetched(CommonScenes.UXSceneGraphName) &&
            this.application.getSetting(WebApplicationSettings.isUXSceneEnabled_SettingName) &&
            request.foundScene.json.excludeSceneLayer !== CommonScenes.UXSceneName
        ) {
            request.ux_scene_loading_promise = this.sceneGraphSet.fetchSceneGraphPromise(
                CommonScenes.UXSceneGraphName,
            );

            request.callback_collector.add(
                new GettingReadyCallback().addPromise(request.ux_scene_loading_promise),
            );
        }

        // load scene content

        if (!request.foundScene.isVisualContentReady()) {
            request.foundScene.startVisualContentGetReady(request.callback_collector);
        }

        // wait for loading

        request.callback_collector_promise = request.callback_collector
            .newWaitPromiseForAllCallbacks()
            .then((result) => {
                request.callback_collector_promise = undefined;

                if (request.foundScene?.visual_element?.hasError()) {
                    request.error = request.foundScene.visual_element.getError();
                }

                request.isLoadingCompleted = true;
                this.evaluateSceneChangeProgress();
            });
    }

    startSceneChange(request: SceneChangeRequest) {
        if (this.scene_change_request_in_progress) {
            this.scene_change_requests_in_queue.push(request);
            console.log("startSceneChange queued: scene change in progress");
        } else {
            this.scene_change_request_in_progress = request;

            this.evaluateSceneChangeProgress();
        }
    }

    invalidate() {
        this.icanvas.invalidate();

        this.icanvas.try_invalidated_draw();
    }

    findSceneByPath(path: ScenePath) {
        return this.activeScene.findSceneByPath(path);
    }

    findSceneIndexByPath(path: ScenePath) {
        return this.activeScene.findSceneIndexByPath(path);
    }

    findSceneByName(name?: string) {
        return this.activeScene.findSceneByName(name);
    }

    drawFrame(icanvas: InteractiveCanvas) {
        this.activeScene.drawFrame(icanvas);
    }

    mousedown(icanvas: InteractiveCanvas, e: MouseEvent) {
        this.activeScene.mousedown(icanvas, e);
    }

    mouseup(icanvas: InteractiveCanvas, e: MouseEvent) {
        this.activeScene.mouseup(icanvas, e);
    }

    mousemove(icanvas: InteractiveCanvas, e: MouseEvent) {
        this.activeScene.mousemove(icanvas, e);
    }
    keydown(icanvas: InteractiveCanvas, ievent: InteractiveEvent) {
        this.activeScene.keydown(icanvas, ievent);
    }
    keyup(icanvas: InteractiveCanvas, ievent: InteractiveEvent) {
        this.activeScene.keyup(icanvas, ievent);
    }
    onTouchTap(e: Event) {
        this.activeScene.onTouchTap(e);
    }
    onTouchPan(e: Event) {
        this.activeScene.onTouchPan(e);
    }
    onTouchSwipe(e: Event) {
        this.activeScene.onTouchSwipe(e);
    }
    onTouchDistance(e: Event) {
        this.activeScene.onTouchDistance(e);
    }
    onTouchRotate(e: Event) {
        this.activeScene.onTouchRotate(e);
    }
    onTouchGesture(e: Event) {
        this.activeScene.onTouchGesture(e);
    }
    file_dropped(e: DragEvent, files: any[]) {
        this.activeScene.file_dropped(e, files);
    }
    drag_file(e: DragEvent, files: any[]) {
        this.activeScene.drag_file(e, files);
    }

    activate_event(event: InteractiveEvent) {
        this.activeScene.activate_event(event);
    }

    activate(value: any, value_context: any) {
        this.activeScene.activate(value, value_context);
    }

    selectNextSceneInteractive(increment = 1) {
        this.activeScene.selectNextSceneInteractive(increment);
    }

    getSelectedSceneInteractive() {
        return this.activeScene.getSelectedSceneInteractive();
    }

    selectNone() {
        this.activeScene.selectNone();
    }
    /**
     *
     * @param {UpdateContext} update_context
     */
    update(update_context: UpdateContext) {
        this.visual_elements.update(update_context);
    }

    excludeSceneLayer(name: string) {
        this.activeScene.excludeSceneLayer(name);
    }
    unexcludeSceneLayer(name: string) {
        this.activeScene.unexcludeSceneLayer(name);
    }
}
