import { Action } from "../actions/action";
import { SceneObjectInterface } from "../SceneObjectInterface";
export class OpenModuleRedirectAction extends Action {
    value: string;
    value_context?: string;

    constructor(verb: string, name: string | undefined, json: any) {
        super(verb, name);
        this.value = json["value"];
        this.value_context = json["value_context"];
    }

    run(interactive: SceneObjectInterface | undefined) {
        console.log("open and redirect to module");
        const loginModule = interactive?.scene.icanvas.externalModules.findOpenModule("login");
        if (loginModule) {
            interactive?.scene.icanvas.externalModules.closeModule(loginModule);
        }
        const module = interactive?.scene.icanvas.externalModules.openModule(
            this.value,
            true,
            true,
        );
        // if (module) {
        //   module.data_context = this.value_context;
        //   var account_rewards = interactive.simulation.account.addTokenRewardSource(module.name);
        //   module.addActivationRewards(account_rewards);
        // }
    }
}
