import { Interactives } from "./interactives.ts";
import { AudioAmbiance } from "../audio_visual/audio/audioAmbiance.ts";
import { Interactive } from "./Interactive.ts";
import { VisualElement } from "../audio_visual/visual/visualElement.ts";
import { ScenePath, ScenePathInterface } from "./ScenePath.ts";
import { InteractiveLayerJson } from "./InteractiveLayerJson";
import { InteractiveLayers } from "./InteractiveLayers.ts";
import { AudioListenerScope } from "../audio_visual/audio/AudioListenerScope.ts";
import { AmbianceContext } from "../audio_visual/audio/AmbianceContext.ts";
import { Actions } from "./actions/actions.ts";
import { RectangleGeometry } from "../geometry/RectangleGeometry.ts";
import { GettingReadyCallbackCollector } from "./GettingReadyCallbackCollector.ts";
import { CustomerAccount } from "../customerAccount.ts";
import { InteractiveCanvas } from "../audio_visual/interactive_canvas.ts";
import { InteractiveEvent } from "./InteractiveEvent.ts";
import { DragDropEvent } from "./DragDropEvent.ts";
import { SceneGraph } from "./scene_graph";
import { SceneGraphNode } from "./scene_graph_node";
import { Stem } from "../audio_visual/audio/stem";
import { ResourceInterface } from "../audio_visual/visual/resources/resourceInterface";
import { VideoFileResource } from "../audio_visual/visual/resources/VideoFileResource.ts";
import { SceneObjectInterface } from "./SceneObjectInterface";
import { setPlaylistConfig } from "../audio_visual/audio/playlistAPI/Radio";
import { Simulation } from "../simulation";
let c2 = require("c2.js");
let geometry_js = require("../geometry.ts");

export class Scene implements SceneObjectInterface {
    static getNameFromFilename(file: any) {
        return file.name;
    }

    static newJsonFromFile(file: any) {
        return {};
    }

    scene_graph: SceneGraph;
    scene_graph_node?: SceneGraphNode;
    name: string;
    json: any;
    visual_element: VisualElement;
    audioAmbiance: AudioAmbiance;
    //objects: SceneObjects;
    interactiveLayers: InteractiveLayers;
    //isVisualError = false;
    //visualError :string;
    geometry: RectangleGeometry;
    visual_geometry?: RectangleGeometry;
    callback_collector?: GettingReadyCallbackCollector;
    callback_collector_promise?: Promise<GettingReadyCallbackCollector | void>;

    constructor(scene_graph: SceneGraph, name: string, json: any) {
        this.scene_graph = scene_graph;
        this.name = name;
        this.json = json;

        this.initializeFromJson();

        this.audioAmbiance = new AudioAmbiance(
            this.toAudioContext(),
            this.json.audioAmbiance,
            this.getResourcePath.bind(this),
        );
    }

    isFadedOut() {
        if (this.sceneInteractiveLayer.isFadedOut) {
            return true;
        }
        return false;
    }

    onPlaySoundEffects(stems: Stem[]) {
        this.audioAmbiance.onPlaySoundEffects(stems);
    }

    isVisualContentReady() {
        if (!this.visual_element?.isReadyOrEmpty()) {
            return false;
        }
        // if (this.objects && !this.objects.isVisualContentReady()) {
        //     return false;
        // }

        return this.interactiveLayers.isVisualContentReady();
    }

    get sceneInteractiveLayer() {
        return this.interactiveLayers.default_layer;
    }

    get sceneInteractiveLayerInteractives() {
        return this.sceneInteractiveLayer.interactives;
    }

    get visualElements() {
        return this.scene_graph_node?.visualElements;
    }

    get resources() {
        return this.scene_graph.resources;
    }

    get icanvas() {
        return this.sceneGraph.icanvas;
    }

    get simulation() {
        return this.scene_graph.simulation;
    }

    get sceneGraph() {
        return this.scene_graph;
    }

    get sceneGraphNode() {
        return this.scene_graph_node;
    }

    get sceneLayerOrder() {
        return this.json.sceneLayerOrder;
    }

    get scene() {
        return this;
    }

    get localScene() {
        return this;
    }

    get globalScene() {
        return this;
    }

    get application() {
        return this.scene_graph.application;
    }

    get server() {
        return this.scene_graph.simulation.server;
    }

    get server_file_cache() {
        return this.server.server_file_cache;
    }

    get draw_order() {
        return this.sceneLayerOrder;
    }

    // get geometry_isRelativeTo() {
    //   return undefined;
    // }

    initializeFromJson() {
        if (this.json.sceneLayerOrder === undefined) {
            this.json.sceneLayerOrder = 1;
        }

        this.interactiveLayers = new InteractiveLayers(this);

        // this.objects?.initializeFromJson();

        this.interactiveLayers.setDefaultLayer(this.json);
        this.interactiveLayers.initializeFromJson();

        if (this.json.inactivityFadeoutSeconds !== undefined) {
            this.interactiveLayers.default_layer.json.inactivityFadeoutSeconds =
                this.json.inactivityFadeoutSeconds;
        }
    }

    activate_event(ievent: InteractiveEvent) {
        this.interactiveLayers.activate_event(ievent);
    }

    activate(value: any, value_context: any) {
        this.interactiveLayers.activate(value, value_context);
    }

    getResourcePathFunction() {
        return this.getResourcePath.bind(this);
    }

    toAudioContext() {
        let scope = AudioListenerScope.fromScenePath(this.toScenePath(), this, this.sceneGraph);
        let audioContext = new AmbianceContext(scope);
        return audioContext;
    }

    toScenePath() {
        return new ScenePath(this.scene_graph.name, this.name);
    }

    push(item: InteractiveLayerJson) {
        if (item instanceof InteractiveLayerJson) {
            this.interactiveLayers.pushLayer(item.json);
        }
    }

    removeTemporary() {
        this.sceneInteractiveLayer.removeTemporary();
    }

    canNavigateBack() {
        return this.simulation.player.canNavigateBack();
    }

    navigateBack() {
        this.simulation.player.navigateBack();
    }

    navigateHome() {
        this.simulation.player.navigateHome();
    }

    navigate(scene_name: string, scene_graph_name?: string) {
        if (scene_graph_name === undefined) {
            scene_graph_name = this.sceneGraph.name;
        }
        this.simulation.player.startChangeLocation(new ScenePath(scene_graph_name, scene_name));
    }

    graphStarted(previousPath?: ScenePathInterface) {}

    startVisualContentGetReady(gettingReadyCallbackCollector: GettingReadyCallbackCollector) {
        if (!this.visual_element) {
            this.visual_element = new VisualElement(this, this.json, this.getResourcePath());
        }

        this.geometry = RectangleGeometry.createGeometryFromJson_Relative(
            this.json,
            this,
            undefined,
            undefined,
            geometry_js.Geometry.CoordinatesType.ABSOLUTE,
            geometry_js.Geometry.CoordinatesType.ABSOLUTE,
            Simulation.defaultAbsoluteSize,
        );
        this.geometry.letterboxInto = this.icanvas;

        //var test_rect = this.geometry.get_absolute_shape();

        this.visual_element.startVisualContentGetReady(gettingReadyCallbackCollector);

        // this.objects?.startVisualContentGetReady(gettingReadyCallbackCollector);
        this.interactiveLayers.startVisualContentGetReady(gettingReadyCallbackCollector);
    }

    start(previousScene?: SceneObjectInterface) {
        if (!this.visual_element) {
            console.info("loading scene: " + this.name);
            this.callback_collector = new GettingReadyCallbackCollector();
            this.startVisualContentGetReady(this.callback_collector);
            this.callback_collector_promise = this.callback_collector
                .newWaitPromiseForAllCallbacks()
                .then((result) => {
                    this.callback_collector_promise = undefined;
                    this.callback_collector = undefined;
                    this.start(previousScene);
                    this.icanvas.try_invalidated_draw();
                });
            return;
        }

        console.info("start scene: " + this.name);

        // On scene transitions, update the remote session appropriately.
        // TODO: we should not be checking the scene name directly
        const qrCode = document.getElementById("remote-qr-code-container");
        if (
            this.name !== "ux-scene" &&
            this.name !== "cabin-splash-screen" &&
            !this.simulation.state.remoteHandler.phoneConnected
        ) {
            // Not in the main cabin splashscreen, remove the qr code.
            this.simulation.state.remoteHandler.endRemoteSession();
        } else if (
            !qrCode &&
            this.name === "cabin-splash-screen" &&
            !this.simulation.state.remoteHandler.phoneConnected
        ) {
            // Entered the main cabin splashscreen, start a remote session only if there isn't already one.
            this.simulation.state.remoteHandler.startRemoteSession();
        }

        this.visual_element.start();

        //this.objects?.start();
        this.interactiveLayers.start();

        this.scene_graph_node?.listener.addAudioAmbiance(this.audioAmbiance);

        if (this.json["scene.event.start"]) {
            let actions = new Actions();
            actions.parseJson(this.json["scene.event.start"], this.application);
            actions.runActions(this);
        }

        if (this.json.excludeSceneLayer) {
            this.scene_graph_node?.excludeSceneLayer(this.json.excludeSceneLayer);
        }
    }

    // onVisualError(resource: ResourceInterface) {
    //     this.isVisualError = true;
    //     this.visualError=resource.error;
    // }

    onVisualDisplayed(resource: ResourceInterface, success: boolean) {
        // if (success && !this.isVisualError) {
        //     console.info("scene visual is displayed");
        // }
    }

    stop(nextScene?: Scene) {
        console.info("stop scene: " + this.name);
        //this.objects?.stop();
        this.interactiveLayers.stop();
        let next_vis = nextScene?.visual_element;
        this.visual_element.stop(next_vis?.firstResourceCanvasElement());
        this.scene_graph_node?.listener.removeAudioAmbiance(
            this.audioAmbiance,
            nextScene?.audioAmbiance,
        );
        this.removeTemporary();

        if (this.json.excludeSceneLayer) {
            this.scene_graph_node?.unexcludeSceneLayer(this.json.excludeSceneLayer);
        }
    }

    graphStopped(nextPath?: ScenePathInterface) {}

    getResourcePath() {
        return this.json.resourcePath || this.scene_graph.json.resourcePath;
    }

    // deriveRectForScene(scaleToCanvas = true) {

    //   var defaultW = 3840;
    //   var defaultH = 2160;

    //   if (scaleToCanvas) {
    //     var hRatio = this.simulation.icanvas.get_width() / defaultW;
    //     var vRatio = this.simulation.icanvas.get_height() / defaultH;
    //     var ratio = Math.min(hRatio, vRatio);
    //     return new c2.Rect(0, 0, defaultW * ratio, defaultH * ratio);
    //   }

    //   return new c2.Rect(0, 0, defaultW, defaultH);
    // }

    // toRect(scaleToCanvas = true) {
    //   if (scaleToCanvas && this.scene_graph_node == undefined) {
    //     return new c2.Rect(0, 0, 0, 0)
    //   }

    //   if (this.visual_element.isReady()) {
    //     var visual_rect = this.visual_element.toRect(scaleToCanvas);
    //     if (visual_rect) {
    //       return visual_rect;
    //     }
    //   }

    //   return this.deriveRectForScene(scaleToCanvas);
    // }

    // getScreenSpaceAreaRect() {
    //   return this.toRect();
    // }

    getScreenSpaceAreaRect() {
        return this.geometry.get_absolute_rect_shape();
    }

    drawFrame(icanvas: InteractiveCanvas) {
        if (this.scene_graph_node === undefined) {
            return;
        }

        this.interactiveLayers.drawFrame(icanvas);
    }

    mousedown(ievent: InteractiveEvent) {
        this.interactiveLayers.mousedown(ievent);
    }

    mouseup(ievent: InteractiveEvent) {
        this.interactiveLayers.mouseup(ievent);
    }

    mousemove(ievent: InteractiveEvent) {
        if (!this.isIEventOnGeometry(ievent)) {
            return;
        }

        this.interactiveLayers.mousemove(ievent);
    }

    keydown(ievent: InteractiveEvent) {
        this.interactiveLayers.keydown(ievent);
    }

    keyup(ievent: InteractiveEvent) {
        this.interactiveLayers.keyup(ievent);
    }

    onTouchTap(ievent: InteractiveEvent) {
        this.interactiveLayers.onTouchTap(ievent);
    }

    onTouchPan(ievent: InteractiveEvent) {
        this.interactiveLayers.onTouchPan(ievent);
    }

    onTouchSwipe(ievent: InteractiveEvent) {
        this.interactiveLayers.onTouchSwipe(ievent);
    }

    onTouchDistance(ievent: InteractiveEvent) {
        this.interactiveLayers.onTouchDistance(ievent);
    }

    onTouchRotate(ievent: InteractiveEvent) {
        this.interactiveLayers.onTouchRotate(ievent);
    }

    onTouchGesture(ievent: InteractiveEvent) {
        this.interactiveLayers.onTouchGesture(ievent);
    }

    file_dropped(ievent: DragDropEvent) {
        this.interactiveLayers.file_dropped(ievent);
    }

    drag_file(ievent: DragDropEvent) {
        this.interactiveLayers.drag_file(ievent);
    }

    findInteractiveByName(name: string, isTemporary: boolean) {
        return this.interactiveLayers.default_layer.findInteractiveByName(name, isTemporary);
    }

    convertEventWithPointToRelativePoint(e: MouseEvent) {
        let asMouse = { x: e.offsetX, y: e.offsetY };

        let rect = this.geometry.shape;

        let result = new c2.Point(asMouse.x / rect.w, asMouse.y / rect.h);

        return result;
    }

    isIEventOnGeometry(ievent: InteractiveEvent) {
        let geometry = this.getScreenSpaceAreaRect();
        let mouse_point = geometry_js.mouseEventToPoint(ievent.e);
        return geometry.intersects(mouse_point);
    }

    runActionJson(action: any) {
        let actions = new Actions();
        actions.parseJson(action, this.application);
        actions.runActions(this);
    }

    getVideoFileInfoForStem(video_stem: any) {
        if (!video_stem) return undefined;

        let fileinfo = this.application.server_file_cache.findFileWithResourcePath(
            video_stem.video,
            this.getResourcePath(),
            video_stem.resourcePath2,
            VideoFileResource.VideoFileExtension,
            VideoFileResource.VideoCategoryPath,
        );

        return fileinfo;
    }
}
