import { AudioVisualPlaybackQueue } from "../../audio_visual/visual/AudioVisualPlaybackQueue";
import { AudioVisualPlaybackQueueItem } from "../../audio_visual/visual/AudioVisualPlaybackQueueItem";
import { TimeUtils } from "../../TileUtils";
export class SceneGraphAudioVisualStateEventVariation {
    json: any;
    constructor(json: any) {
        this.json = json;
    }
    initializeFromJson(): void {}
    get occurrence_weighting(): number {
        return this.json.occurrence_weighting;
    }
    get video(): string {
        return this.json.video;
    }
}
export class SceneGraphAudioVisualStateEventComponent {
    static QueueItemType = "event";
    name?: string;
    json: any;
    variations: SceneGraphAudioVisualStateEventVariation[] = [];
    constructor(json: any, name?: string) {
        this.json = json;
        this.name = name;
    }
    initializeFromJson(): void {
        for (const each of this.variationsJson) {
            const variation = new SceneGraphAudioVisualStateEventVariation(each);
            variation.initializeFromJson();
            this.variations.push(variation);
        }
    }
    get occurrenceIntervalMilliseconds(): number {
        const timeUnits = {
            occurrence_per_second: TimeUtils.MILLISECONDS_PER_SECOND,
            occurrence_per_minute: TimeUtils.MILLISECONDS_PER_MINUTE,
            occurrence_per_hour: TimeUtils.MILLISECONDS_PER_HOUR,
            occurrence_per_day: TimeUtils.MILLISECONDS_PER_DAY,
        };
        for (const [key, milliseconds] of Object.entries(timeUnits)) {
            if (this.json.hasOwnProperty(key)) {
                return milliseconds / this.json[key];
            }
        }
        throw new Error("No valid occurrence_per_* property found in JSON");
    }
    get variationsJson(): any[] {
        return this.json.variations;
    }

    nextRandomVariation(): SceneGraphAudioVisualStateEventVariation | undefined {
        const totalWeight = this.variations.reduce(
            (sum, variation) => sum + variation.occurrence_weighting,
            0,
        );
        let randomWeight = Math.random() * totalWeight;
        for (const variation of this.variations) {
            if (randomWeight < variation.occurrence_weighting) {
                return variation;
            }
            randomWeight -= variation.occurrence_weighting;
        }
    }

    hasLatestOccurrence(playbackQueue: AudioVisualPlaybackQueue): boolean {
        let found = this.getLatestOccurrence(playbackQueue);
        return found !== undefined;
    }

    getLatestOccurrence(playbackQueue: AudioVisualPlaybackQueue): number | undefined {
        const found = playbackQueue.item_queue.findLastAssociatedObjectWhereWithRange(
            (each: AudioVisualPlaybackQueueItem) => each.name === this.name,
        );
        return found == null ? undefined : found.range.start;
    }
}
