import { WebApplication } from "../../webApplication.ts";
import { ActionWithValue } from "../actions/action.ts";
import { SceneObjectInterface } from "../SceneObjectInterface.ts";

export class ToggleApplicationSettingAction extends ActionWithValue {
    application: WebApplication | undefined;
    constructor(
        verb: string,
        name: string | undefined,
        json: any,
        application: WebApplication | undefined,
    ) {
        super(verb, name, json);
        this.application = application;
    }

    run(interactive: SceneObjectInterface | undefined) {
        console.log(`action: ${"ToggleApplicationSettingAction"} ${this.value}`);
        this.application?.toggleBooleanSetting(this.value);
    }
}
