import { Action } from "../actions/action";
import { SceneObjectInterface } from "../SceneObjectInterface";

export class ActivateAction extends Action {
    value;
    value_context;
    constructor(verb: string, name: string | undefined, json?: any) {
        super(verb, name);
        this.value = json["value"];
        this.value_context = json["value_context"];
    }
    canRun(interactive: SceneObjectInterface | undefined) {
        return true;
    }
    run(interactive: SceneObjectInterface | undefined) {
        console.log("action:Activate");
        interactive?.scene.icanvas.activate(this.value, this.value_context);
    }
}
