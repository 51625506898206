import { IConsoleLogger } from './IConsoleLogger';
export class ConsoleLogger implements IConsoleLogger {
    log(message: string, ...optionalParams: any[]): void {
        console.log(message, ...optionalParams);
    }
    info(message: string, ...optionalParams: any[]): void {
        console.info(message, ...optionalParams);
    }
    warn(message: string, ...optionalParams: any[]): void {
        console.warn(message, ...optionalParams);
    }
    error(message: string, ...optionalParams: any[]): void {
        console.error(message, ...optionalParams);
    }
}