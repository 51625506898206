import { InteractiveCanvas } from "./audio_visual/interactive_canvas";
import { HasGeometryInterface } from "./geometry/RectangleGeometry";

//https://github.com/ren-yuan/c2.js
//let c2 = require("c2.js");
import { Rect, Point } from "c2.js";

export function mouseEventToPoint(e: MouseEvent) {
    return new Point(e.x, e.y);
}

export class Geometry {
    static CoordinatesType = {
        ABSOLUTE: "absolute",
        RELATIVE: "relative",
    };
    relativeTo?: HasGeometryInterface;
    letterboxedFrom?: HasGeometryInterface;
    letterboxInto?: HasGeometryInterface;
    shape: Rect;
    border_size: number = 2;
    scale: number = 1;
    geometry: Geometry;
    coordinatesTypes: string[] = [Geometry.CoordinatesType.ABSOLUTE];

    constructor() {}

    draw(icanvas: InteractiveCanvas, drawScope: number) {}
    get_absolute_rect_shape() {}
    containsPoint(point: Point) {}
    setScale(scaler: number) {
        this.scale = scaler;
    }
}
