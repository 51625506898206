import * as htmlHelper from "../../htmlHelper.ts";
import { UserInterfaceElement } from "./UserInterfaceElement.ts";

export class LableUserInterfaceElement extends UserInterfaceElement {
    element: HTMLElement;

    constructor(elements: any, json: any) {
        super(elements, json);
    }

    addToDOM(): void {
        this.element = htmlHelper.htmlToElement(`<span>${this.json.content}</span>`) as HTMLElement;

        if (this.json.margin) {
            this.element.style.margin = this.json.margin;
        }
        this.elements.div.appendChild(this.element);
    }

    value_string(): string {
        return this.element.innerText;
    }
}
