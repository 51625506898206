import { ConnectedStem } from "./ConnectedStem.ts";
import { WebAudio } from "./webAudio.ts";

type stringFunction = () => string;

export class Stem {
    json: any;
    parentGetResourcePath: stringFunction;
    gain: number;

    /**
     *
     * @param {object} json
     * @param {stringFunction} parentGetResourcePath
     */
    constructor(json: any, parentGetResourcePath: stringFunction) {
        this.json = json || {};

        if (this.json.isEnabled === undefined) {
            this.json.isEnabled = true;
        }
        if (this.json.gain === undefined) {
            this.json.gain = 1;
        }
        if (this.json.isLoop === undefined) {
            this.json.isLoop = true;
        }
        if (this.json.isAutoPlay === undefined) {
            this.json.isAutoPlay = true;
        }
        if (this.json.layer === undefined) {
            this.json.layer = WebAudio.ambiancelayerName;
        }
        if (this.json.region === undefined) {
            this.json.region = {};
        }
        if (this.json.region.gain === undefined) {
            this.json.region.gain = 0;
        }
        this.parentGetResourcePath = parentGetResourcePath;
    }

    findInArray(array: Stem[]) {
        return array.find((each) => each.isEquivilentToStem(this));
    }

    removeFromArray(array: Stem[]) {
        for (let index = 0; index < array.length; index++) {
            if (array[index].isEquivilentToStem(this)) {
                array.splice(index, 1);
            }
        }
    }

    isEquivilentToStem(stem: Stem) {
        return /*this.name === stem.name ||*/ this.audioSrc === stem.audioSrc;
    }

    hasRegionGain(): boolean {
        return this.json.region?.gain;
    }

    toRegionStem() {
        let result = new Stem({ ...this.json }, this.parentGetResourcePath);
        if (result.hasRegionGain()) {
            result.json.gain = result.json.region.gain;
        }
        return result;
    }

    get name(): string {
        return this.json.name;
    }

    get isEnabled(): boolean {
        return this.json.isEnabled;
    }

    get audioSrc(): string {
        return this.json.audioSrc;
    }

    getResourcePath(): string {
        return this.json.resourcePath || this.parentGetResourcePath();
    }

    load(connection: ConnectedStem | undefined) {
        if (!this.isEnabled) {
            return;
        }
        connection?.addReference(this);
    }

    unload(connection: ConnectedStem | undefined) {
        if (!this.isEnabled) {
            return;
        }
        connection?.removeReference(this);
    }

    play(connection: ConnectedStem | undefined) {
        if (!this.isEnabled) {
            return;
        }
        connection?.playFrom(this);
    }

    setGain(connection: ConnectedStem | undefined, amount: number) {
        if (!this.isEnabled) {
            return;
        }
        this.json.gain = amount;
        connection?.setGainFrom(this, amount);
    }

    stop(connection: ConnectedStem | undefined) {
        if (!this.isEnabled) {
            return;
        }
        connection?.stopFrom(this);
    }
}
