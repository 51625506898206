import { InteractiveCanvas } from "../audio_visual/interactive_canvas";
import { WebApplicationSettings } from "../WebApplicationSettings";
import { DragDropEvent } from "./DragDropEvent";
import { InteractiveEvent } from "./InteractiveEvent";
import { Scene } from "./scene";
import { SceneGraphNode, CommonScenes } from "./scene_graph_node";
import { SceneChangeRequest } from "./SceneChangeRequest";
import { ScenePath } from "./ScenePath";

export class ActiveScene {
    activeNode: SceneGraphNode;
    active_scene?: Scene;
    ux_scene?: Scene;
    scenes: Scene[];

    /**
     *
     */
    constructor(activeNode: SceneGraphNode) {
        this.activeNode = activeNode;
        this.scenes = [];
    }

    get name() {
        return this.active_scene?.name;
    }
    get firstScene() {
        return this.scenes[0];
    }

    startUXScene() {
        let ux = this.activeNode.sceneGraphSet.getSceneGraph(CommonScenes.UXSceneGraphName);

        if (ux) {
            this.ux_scene = ux.defaultOrFirstScene;

            if (this.ux_scene) {
                this.ux_scene.scene_graph_node = this.activeNode;
                this.ux_scene.start(undefined);
                return;
            }
        }

        console.warn(`ux scene not found`);
    }

    getUXScene() {
        return this.ux_scene;
    }

    getActiveScene() {
        for (let index = 0; index < this.scenes.length; index++) {
            const element = this.scenes[index];
            if (element.name === CommonScenes.UXSceneName) {
                continue;
            }
            return element;
        }
        return undefined;
    }

    excludeSceneLayer(name: string) {
        if (this.ux_scene && name === CommonScenes.UXSceneName) {
            this.ux_scene.stop();
            let index = this.scenes.indexOf(this.ux_scene);
            if (index !== -1) this.scenes.splice(index, 1);
            this.ux_scene = undefined;
        }
    }
    unexcludeSceneLayer(name: string) {
        if (!this.ux_scene && name === CommonScenes.UXSceneName) {
            this.startUXScene();
            if (this.ux_scene) {
                this.scenes.push(this.ux_scene);
            }
        }
    }
    getSelectedSceneInteractive() {
        for (const each_scene of this.scenes) {
            for (let each of each_scene.sceneInteractiveLayerInteractives.interactives) {
                if (each.isSelected) {
                    return each;
                }
            }
        }

        return undefined;
    }

    selectNone() {
        for (const each_scene of this.scenes) {
            for (let each of each_scene.sceneInteractiveLayerInteractives.interactives) {
                if (each.isSelected) {
                    each.setSelected(false);
                }
            }
        }
    }

    findSceneByPath(path: ScenePath) {
        return this.findSceneByName(path.sceneName);
    }

    findSceneIndexByPath(path: ScenePath) {
        if (!path) {
            return undefined;
        }
        for (let index = 0; index < this.scenes.length; index++) {
            const element = this.scenes[index];
            if (element.name === path.sceneName) {
                return index;
            }
        }
    }

    findSceneByName(name?: string) {
        for (const scene of this.scenes) {
            if (scene.name === name) {
                return scene;
            }
        }
    }

    drawFrame(icanvas: InteractiveCanvas) {
        for (let each in this.scenes) {
            let i = this.scenes[each];
            i.drawFrame(icanvas);
        }
    }

    mousedown(icanvas: InteractiveCanvas, e: MouseEvent) {
        let event = new InteractiveEvent(icanvas, e);
        for (let each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].mousedown(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }

    mouseup(icanvas: InteractiveCanvas, e: MouseEvent) {
        let event = new InteractiveEvent(icanvas, e);
        for (let each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].mouseup(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }

    mousemove(icanvas: InteractiveCanvas, e: MouseEvent) {
        let event = new InteractiveEvent(icanvas, e);
        for (let each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].mousemove(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }
    keydown(icanvas: InteractiveCanvas, ievent: InteractiveEvent) {
        for (let each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].keydown(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }
    }
    keyup(icanvas: InteractiveCanvas, ievent: InteractiveEvent) {
        for (let each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].keyup(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }
    }
    onTouchTap(e: Event) {
        let event = new InteractiveEvent(undefined, e);
        for (let each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].onTouchTap(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }
    onTouchPan(e: Event) {
        let event = new InteractiveEvent(undefined, e);
        for (let each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].onTouchPan(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }
    onTouchSwipe(e: Event) {
        let event = new InteractiveEvent(undefined, e);
        for (let each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].onTouchSwipe(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }
    onTouchDistance(e: Event) {
        let event = new InteractiveEvent(undefined, e);
        for (let each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].onTouchDistance(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }
    onTouchRotate(e: Event) {
        let event = new InteractiveEvent(undefined, e);
        for (let each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].onTouchRotate(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }
    onTouchGesture(e: Event) {
        let event = new InteractiveEvent(undefined, e);
        for (let each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].onTouchGesture(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }
    file_dropped(e: DragEvent, files: any[]) {
        let event = new DragDropEvent(this.active_scene?.icanvas, e, files);
        for (let each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].file_dropped(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }
    drag_file(e: DragEvent, files: any[]) {
        let event = new DragDropEvent(this.active_scene?.icanvas, e, files);
        event.isDrag = true;
        for (let each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].drag_file(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }

    activate_event(event: InteractiveEvent) {
        for (let each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].activate_event(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }

    activate(value: any, value_context: any) {
        let event = new InteractiveEvent();
        event.activate_value = value;
        event.activate_value_context = value_context;
        this.activate_event(event);
    }

    selectNextSceneInteractive(increment = 1) {
        let selectable = [];

        for (const each_scene of this.scenes) {
            let scene_selectable = each_scene.sceneInteractiveLayerInteractives.interactives.filter(
                (item) => item.isSelectable(),
            );
            selectable.push(...scene_selectable);
        }
        selectable.sort((a, b) => (a.json.selectionOrder ?? 0) - (b.json.selectionOrder ?? 0));

        let index = selectable.findIndex((item) => item.isSelected);

        if (index === -1) {
            index = selectable.findIndex((item) => item.json.isDefaultSelection);
            if (index !== -1) {
                index -= increment;
            }
        } else {
            selectable[index].setSelected(false);
        }

        index += increment;
        index = index % selectable.length;

        if (index < 0) {
            index += selectable.length;
        }

        selectable[index].setSelected(true);
    }

    swapInNewActiveScene(request: SceneChangeRequest) {
        let previous_active_scene = undefined;
        let found_scene = request.foundScene;

        if (this.active_scene) {
            this.active_scene.stop(found_scene);
            this.active_scene.scene_graph_node = undefined;
            previous_active_scene = this.active_scene;
            this.active_scene = undefined;
        }

        this.active_scene = found_scene;

        if (this.active_scene) {
            this.active_scene.scene_graph_node = this.activeNode;

            this.scenes = [this.active_scene];

            if (
                !this.ux_scene &&
                this.active_scene.json.excludeSceneLayer !== CommonScenes.UXSceneName
            ) {
                this.startUXScene();
            }

            if (this.ux_scene) {
                this.scenes.push(this.ux_scene);
            }
            this.active_scene?.start(previous_active_scene);
        }

        request.isCompleted = true;
        // this.event_scene_change_request_complete?.(request);
        this.activeNode.invalidate();
    }
}
