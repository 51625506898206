//@ts-check
/**
 *
 */
export class ModuleActivationRewards {

    onStart(tokens_per_second: number) {}

    onDepositTokens(tokens_amount: number) {}

    onEnd() {}
}
