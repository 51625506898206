import { Action } from "../actions/action";
import { SceneObjectInterface } from "../SceneObjectInterface";

export class OpenFullscreenModuleAction extends Action {
    value: any;
    value_context: any;

    constructor(verb: string, name: string | undefined, json: any) {
        super(verb, name);
        this.value = json["value"];
        this.value_context = json["value_context"];
    }

    run(interactive: SceneObjectInterface | undefined) {
        console.log("open fullscreen module");
        const loginModule = interactive?.scene.icanvas.externalModules.findOpenModule("login");
        if (loginModule) {
            interactive?.scene.icanvas.externalModules.closeModule(loginModule);
        }
        const module = interactive?.scene.icanvas.externalModules.openModule(this.value, true);
        // if (module) {
        //     module.data_context = this.value_context;
        //     var account_rewards = interactive.simulation.account.addTokenRewardSource(module.name);
        //     module.addActivationRewards(account_rewards);
        // }
    }
}
