/* context

https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Using_Web_Audio_API
https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Migrating_from_webkitAudioContext
https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Web_audio_spatialization_basics
https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Best_practices

https://developer.chrome.com/blog/autoplay/#webaudio

*/

import { CustomerAccount } from "../../customerAccount.ts";
import { Resources } from "../../resources.ts";
import { NumberEditableProperty } from "../../view/propertyEditor.ts";
import { StackLayout } from "../../view/stackLayout.ts";
import { Treeview } from "../../view/treeview.ts";
import { actionFunction } from "../visual/MediaSourcePlayerComponent.ts";
import { ConnectedStems } from "./ConnectedStems.ts";
import { NullConnectedStems } from "./NullConnectedStems.ts";
import { WebAudioPlaylistPlayer } from "./playlistAPI/PlaylistPlayer.ts";
import { Stem } from "./stem.ts";

export interface Audio {
    connectedStems: ConnectedStems | NullConnectedStems;
    layers: Map<string, GainNode>;
    playlistPlayer?: WebAudioPlaylistPlayer;
    resources: Resources;
    audioContext: AudioContext;
    connectNodeToLayer(layer: string, node: GainNode): void;
    initialize(): void;
    tryStartAudio(): boolean;
    uninitialize(): void;
    deactivate(): void;
    reactivate(): void;
    storageItemName(): string;
    saveState(): void;
    shutdown(): void;
    playSoundEffects(stems: Stem[]): void;
    startAudioOnGesture(): void;
    collectEditableProperties(layout: StackLayout): void;
    getAuthorInterfaceName(): string;
    createAuthorInterfaceElement(): HTMLElement | undefined;
    addAuthorInterfaceElementToTreeview(treeview: Treeview): void;
}

export class WebAudio implements Audio {
    static ambiancelayerName = "ambiance";
    static musiclayerName = "music";
    static effectslayerName = "effects";
    static layerNames = [
        WebAudio.ambiancelayerName,
        WebAudio.musiclayerName,
        WebAudio.effectslayerName,
    ];

    static createEditablePropertyForGainNode(node: GainNode, name: string) {
        let prop = new NumberEditableProperty();
        prop.name = name;
        prop.setValue = (v: number) => {
            node.gain.value = v;
        };
        prop.getValue = () => {
            return node.gain.value;
        };
        prop.minValue = 0;
        prop.maxValue = 3;
        prop.defaultValue = node.gain.defaultValue;
        return prop;
    }
    audioContext: AudioContext;
    account: CustomerAccount;
    resources: Resources;
    isWaitingForGesture: boolean;
    connectedStems: ConnectedStems;
    layers = new Map<string, GainNode>();
    /** stores a reference to the playlist manager object */
    playlistPlayer?: WebAudioPlaylistPlayer;
    onStartAudioOnGesture: actionFunction;
    volumeNode: GainNode;
    isDeactivate: boolean;

    constructor(resources: Resources, account: CustomerAccount) {
        this.resources = resources;
        this.account = account;
        this.connectedStems = new ConnectedStems(this);
    }

    connectNodeToLayer(layer: string, node: GainNode) {
        let layerNode = this.layers.get(layer);
        if (layerNode) {
            node.connect(layerNode);
        } else {
            node.connect(this.volumeNode);
        }
    }

    initialize() {
        this.tryStartAudio();
    }

    tryStartAudio() {
        if (this.audioContext != null) {
            return true;
        }
        try {
            this.audioContext = new AudioContext();
            this.isWaitingForGesture = this.audioContext.state === "suspended";

            this.volumeNode = this.audioContext.createGain();
            this.volumeNode.connect(this.audioContext.destination);

            WebAudio.layerNames.forEach((layer) => {
                this.layers.set(layer, this.audioContext.createGain());
                this.layers.get(layer)?.connect(this.volumeNode);
            });
            const musicOutputGainNode = this.layers.get(WebAudio.musiclayerName);
            if (musicOutputGainNode) {
                this.playlistPlayer = new WebAudioPlaylistPlayer(
                    this.audioContext,
                    this.volumeNode,
                );
            }
            return true;
        } catch (e) {}
        return false;
    }

    uninitialize() {}

    deactivate() {
        this.isDeactivate = true;
        this.audioContext.suspend();
        //this.archiveVolume=this.volumeNode.gain.value;
        // this.volumeNode.gain.value=0;
        //this.volumeNode.disconnect();
    }

    reactivate() {
        if (this.isDeactivate) {
            this.isDeactivate = false;

            this.audioContext.resume();
            // this.volumeNode.gain.value=this.archiveVolume;
            //this.volumeNode.connect(this.audioContext.destination);
        }
    }

    storageItemName() {
        return this.resources.combineJsonResourceNameFromArray([
            this.account.application.name,
            this.account.name,
            "audio.storage",
        ]);
    }

    saveState() {}

    shutdown() {
        this.saveState();
    }

    playSoundEffects(stems: Stem[]) {
        stems.forEach((element) => {
            this.connectedStems.playSoundEffect(element);
        });
    }

    startAudioOnGesture() {
        this.tryStartAudio();

        if (this.isWaitingForGesture) {
            this.audioContext.resume();
            this.isWaitingForGesture = false;
            if (this.onStartAudioOnGesture) {
                this.onStartAudioOnGesture();
            }

            console.log("AudioContext resume");
            this.connectedStems.startAudioOnGesture();
        }
    }

    collectEditableProperties(layout: StackLayout) {
        let prop = WebAudio.createEditablePropertyForGainNode(this.volumeNode, "Volume");
        layout.addAsTableRow(prop.getEditorElements());

        WebAudio.layerNames.forEach((element) => {
            const found = this.layers.get(element);
            if (found) {
                let prop = WebAudio.createEditablePropertyForGainNode(found, element + " Volume");
                layout.addAsTableRow(prop.getEditorElements());
            }
        });
    }

    getAuthorInterfaceName() {
        return "audio";
    }

    createAuthorInterfaceElement() {
        let layout = new StackLayout();
        this.collectEditableProperties(layout);
        return layout.element;
    }

    addAuthorInterfaceElementToTreeview(treeview: Treeview) {
        let elm = this.createAuthorInterfaceElement();
        treeview.addItem(this.getAuthorInterfaceName(), elm, true);
    }
}
