import { AmbianceContext } from "../audio_visual/audio/AmbianceContext";
import { InteractiveCanvas } from "../audio_visual/interactive_canvas";
import { VisualElement } from "../audio_visual/visual/visualElement";
import { VisualElements } from "../audio_visual/visual/VisualElements";
import { HasGeometryInterface, RectangleGeometry } from "../geometry/RectangleGeometry";
import { FileInfoType, LocalServerFileCache } from "../LocalServerFileCache";
import { Resources } from "../resources";
import { Simulation } from "../simulation";
import { WebApplication } from "../webApplication";
import { GettingReadyCallbackCollector } from "./GettingReadyCallbackCollector";
import { Scene } from "./scene";
import { SceneGraph } from "./scene_graph";
import { SceneGraphNode } from "./scene_graph_node";
import { ScenePath } from "./ScenePath";

export interface SceneObjectInterface extends HasGeometryInterface {
    isFadedOut(): boolean;
    toScenePath(): ScenePath;
    toAudioContext?(): AmbianceContext;
    getResourcePath(): string;
    runActionJson(action: any): void;
    getVideoFileInfoForStem(video_stem: any): FileInfoType | undefined;
    visual_element?: VisualElement;
    scene: SceneObjectInterface;
    icanvas: InteractiveCanvas;
    resources: Resources;
    sceneGraph: SceneGraph;
    scene_graph_node?: SceneGraphNode;
    // audioAmbiance: AudioAmbiance;
    geometry: RectangleGeometry;
    visual_geometry?: RectangleGeometry;
    application: WebApplication;
    visualElements?: VisualElements;
    server_file_cache: LocalServerFileCache;
    simulation: Simulation;
    draw_order: number;
    callback_collector?: GettingReadyCallbackCollector;
    callback_collector_promise?: Promise<GettingReadyCallbackCollector | void>;
}
export class SceneObjectInterfaceExtensions {
    static sceneAsScene(self: SceneObjectInterface | undefined): Scene | undefined {
        return self && self.scene instanceof Scene ? self.scene : undefined;
    }
}
