import { KeyCodes } from "../input/KeyCodes";
import { WebApplication } from "../webApplication";
import { WebApplicationState } from "../WebApplicationState";
import QRCode from "qrcode";

function checkWebRTCSupport() {
    type WindowWithPeerConnections = typeof window & {
        mozRTCPeerConnection?: RTCPeerConnection;
        webkitRTCPeerConnection?: RTCPeerConnection;
    };
    const typedWindow: WindowWithPeerConnections = window;
    return (
        typedWindow.RTCPeerConnection ||
        typedWindow.mozRTCPeerConnection ||
        typedWindow.webkitRTCPeerConnection
    );
}

/**
 * Calculates the expiry time from the current time
 * @param days How many days to expire at
 * @returns Expiry time as a Date object
 */
function getExpiresAt(days = 1) {
    const expiresAt = new Date();
    expiresAt.setDate(expiresAt.getDate() + days);
    return expiresAt;
}

// TODO: separate into incoming and outgoing commands (like in the remote)
export enum RemoteCommands {
    DISCONNECTED = "disconnected",
    PING = "ping",
    POS_ACK = "pos_ack",
    ERROR = "error",
    SELECT_UP = "up",
    SELECT_DOWN = "down",
    SELECT_RIGHT = "right",
    SELECT_LEFT = "left",
    SELECT_BACK = "back",
    SELECT_HOME = "home",
    SELECT_DISCONNECT = "end",
    SELECT_MIDDLE = "middle",
    SELECT_DEBUG = "debug",
    SEND_REMOTE_SDP_TO_DISPLAY = "send_remote_sdp",
    SEND_DISPLAY_SDP_TO_REMOTE = "send_display_sdp",
    SEND_ICE_CANDIDATE = "send_ice_candidate",
    PHONE_CONNECTED = "phone_connected",
    START_SESSION = "start_session",
    UPDATE_SESSION = "update_session",
}

export class RemoteHandler {
    private static readonly LOCAL_STORAGE_KEY = "remoteSession";

    private static storeLocalSession(sessionID: string) {
        const sessionItem = {
            sessionID,
            expiresAt: getExpiresAt()
        };
        localStorage.setItem(RemoteHandler.LOCAL_STORAGE_KEY, JSON.stringify(sessionItem));
    }

    private static deleteLocalSession() {
        localStorage.removeItem(RemoteHandler.LOCAL_STORAGE_KEY);
    }

    private static getLocalSession() {
        let sessionID: string | null = null;
        const sessionString = localStorage.getItem(RemoteHandler.LOCAL_STORAGE_KEY);
        if (sessionString) {
            const parsedData = JSON.parse(sessionString);
            const expiresAt = Date.parse(parsedData.expiresAt);
            if (expiresAt < Date.now()) {
                RemoteHandler.deleteLocalSession();
                return null;
            }
            if (parsedData.sessionID) sessionID = parsedData.sessionID;
        }
        return sessionID;
    }

    state: WebApplicationState;
    ws: WebSocket | null = null;
    peerConnection: RTCPeerConnection | null = null;
    dataChannel: RTCDataChannel | null = null;
    activeConnection: WebSocket | RTCDataChannel | null = null;
    sessionID: string;
    displayConnectionID: string;
    remoteConnectionID: string;
    isEnabled: boolean = false;
    webRTCEnabled: boolean;
    countSinceMessage: number = 0;
    heartbeatInterval: NodeJS.Timer;
    iceCandidates: (RTCIceCandidate | null)[] = [];
    phoneConnected: boolean = false;

    constructor(state: WebApplicationState) {
        this.state = state;
        this.isEnabled = state.application.getSetting(
            WebApplication.isPhoneRemoteEnabledSettingName,
        );
        this.webRTCEnabled = state.application.getSetting(
            WebApplication.isWebRTCForRemoteEnabledSettingName,
        );
    }

    // TODO: code should not know about any specific cabin scene, a verb could be added that is set by the cabin-splash-screen to control this
    private isSplashScreen() {
        return (
            window.location.pathname === "/" &&
            this.state.simulation.sceneGraphNode.activeScene?.name === "cabin-splash-screen"
        );
    }

    /**
     * Initializes the remote handler.
     * Create a new remote session and shows the QR code on the main screen.
     */
    startRemoteSession() {
        if (this.state.canvas.platformCanvas.name === "Mobile") {
            console.log("Platform is Mobile, not starting remote session");
            return;
        }

        const WS_URL = process.env.REMOTE_WS_URL;
        if (!WS_URL) {
            console.error("REMOTE_WS_URL is undefined");
            return;
        }

        if (!this.isEnabled) {
            console.log("Remote handler is disabled");
            return;
        }

        // Don't start remote session if not on the main screen
        if (window.location.pathname !== "/") {
            console.log(
                "Not on main screen, not creating remote session: ",
                window.location.pathname,
            );
            return;
        }

        console.log("Remote handler initialized");

        // Connect to WS to get the connectionID
        if (this.ws) return;
        this.ws = new WebSocket(WS_URL);
        this.activeConnection = this.ws;

        // Create WebRTC peer connection and add event listeners
        if (checkWebRTCSupport() && this.webRTCEnabled) {
            this.peerConnection = new RTCPeerConnection();
            this.peerConnection.onconnectionstatechange = () =>
                console.log(`Connection state change: ${this.peerConnection?.connectionState}`);
            this.peerConnection.onicegatheringstatechange = () =>
                console.log(`ICE gather state change: ${this.peerConnection?.iceGatheringState}`);
            this.peerConnection.ondatachannel = (event) => {
                console.log("Data channel received");
                this.dataChannel = event.channel;
                this.dataChannel.onopen = () => {
                    console.log("Data channel opened, closing WebSocket");
                    this.activeConnection = this.dataChannel;
                    this.ws?.close();
                    if (!this.dataChannel) return;
                    this.dataChannel.onmessage = this.onMessage.bind(this);
                    this.dataChannel.onclose = this.onClose.bind(this);
                    this.dataChannel.onerror = this.onError.bind(this);
                };
            };
            this.peerConnection.onicecandidate = (event) => {
                console.log("Sent ICE candidate");
                this.ws?.send(
                    JSON.stringify({
                        command: RemoteCommands.SEND_ICE_CANDIDATE,
                        destinationConnectionID: this.remoteConnectionID,
                        candidate: event.candidate,
                    }),
                );
            };
        } else {
            console.log("Browser does not support WebRTC");
        }

        // Set up WS event handlers
        this.ws.onopen = this.onOpen.bind(this);
        this.ws.onclose = this.onClose.bind(this);
        this.ws.onmessage = this.onMessage.bind(this);
        this.ws.onerror = this.onError.bind(this);
    }

    /**
     * Ends the remote session.
     */
    endRemoteSession() {
        if (!this.isEnabled) return;
        console.log("Ending remote session");

        this.phoneConnected = false;

        // Close the WS or WebRTC connection
        if (this.ws?.readyState === WebSocket.OPEN) {
            this.ws.close();
            this.ws = null;
        } else if (
            checkWebRTCSupport() &&
            this.webRTCEnabled &&
            this.dataChannel?.readyState === "open" &&
            this.peerConnection?.connectionState === "connected"
        ) {
            this.dataChannel.close();
            this.peerConnection.close();
            this.dataChannel = null;
            this.peerConnection = null;
        }
        this.activeConnection = null;

        // Remove the QR code from the page
        this.removeQRCodeFromPage();

        // Clear heartbeat interval
        clearInterval(this.heartbeatInterval);

        // If user is on splash screen, restart remote session
        if (this.isSplashScreen()) {
            this.startRemoteSession();
        }
    }

    /**
     * Checks the count to see if the phone has lost connection
     */
    private checkCount() {
        if (this.countSinceMessage >= 3) {
            console.log("Lost connection to remote, closing connection...");
            this.endRemoteSession();
        }
        this.countSinceMessage++;
    }

    /**
     * Handles the opening of the WS.
     */
    private onOpen() {
        console.log("WS connection opened");

        // starts a new session with this display's connection ID
        const storedSession = RemoteHandler.getLocalSession();
        if (storedSession) {
            this.ws?.send(
                JSON.stringify({
                    command: RemoteCommands.UPDATE_SESSION,
                    sessionID: storedSession,
                    device: "display",
                }),
            );
        }
        this.ws?.send(
            JSON.stringify({
                command: RemoteCommands.START_SESSION,
            }),
        );
    }

    /**
     * Handles the closing of the WS.
     */
    private onClose() {
        console.log("WS connection closed");
    }

    /**
     * Handles messages received from the WS.
     *
     * @param event the message event
     */
    private async onMessage(event: MessageEvent) {
        const data = JSON.parse(event.data);
        console.log("message received", data);

        if (data.error) {
            console.error("message error", data.error);
            return;
        }

        const command: string = data.command;
        this.countSinceMessage = 0;

        if (!command) return;

        switch (command) {
            case RemoteCommands.START_SESSION:
                this.sessionID = data.sessionID;
                this.createQRCode();
                break;

            case RemoteCommands.UPDATE_SESSION:
                this.displayConnectionID = data.displayConnectionID;
                this.remoteConnectionID = data.remoteConnectionID;
                RemoteHandler.storeLocalSession(this.sessionID);
                break;

            case RemoteCommands.SELECT_UP:
                console.log("remote selected up");
                this.state.canvas.keydown(
                    new KeyboardEvent("keydown", { keyCode: KeyCodes.ArrowUp }),
                );
                break;

            case RemoteCommands.SELECT_DOWN:
                console.log("remote selected down");
                this.state.canvas.keydown(
                    new KeyboardEvent("keydown", { keyCode: KeyCodes.ArrowDown }),
                );
                break;

            case RemoteCommands.SELECT_RIGHT:
                console.log("remote selected right");
                this.state.canvas.keydown(
                    new KeyboardEvent("keydown", { keyCode: KeyCodes.ArrowRight }),
                );
                break;

            case RemoteCommands.SELECT_LEFT:
                console.log("remote selected left");
                this.state.canvas.keydown(
                    new KeyboardEvent("keydown", { keyCode: KeyCodes.ArrowLeft }),
                );
                break;

            case RemoteCommands.SELECT_BACK:
                console.log("remote selected back");
                this.state.simulation.player.navigateBack();
                break;

            case RemoteCommands.SELECT_HOME:
                console.log("remote selected home");
                this.state.canvas.keydown(new KeyboardEvent("keydown", { keyCode: KeyCodes.Home }));
                break;

            case RemoteCommands.SELECT_MIDDLE:
                console.log("remote selected middle");
                // Adding this here fixes the error saying that the user has not interacted with the page yet
                document.dispatchEvent(new MouseEvent("click"));
                this.state.canvas.keydown(
                    new KeyboardEvent("keydown", { keyCode: KeyCodes.Enter }),
                );
                break;

            case RemoteCommands.SELECT_DISCONNECT:
                console.log("remote selected disconnect");
                this.endRemoteSession();
                break;

            case RemoteCommands.SELECT_DEBUG:
                console.log("remote selected debug");
                break;

            case RemoteCommands.SEND_REMOTE_SDP_TO_DISPLAY:
                if (!data.sdp || !data.sourceConnectionID) {
                    console.error("Does not have required fields");
                    return;
                }
                console.log("Received SDP from remote");
                this.remoteConnectionID = data.sourceConnectionID;
                await this.handleSDP(data.sdp, data.sourceConnectionID);
                break;

            case RemoteCommands.SEND_ICE_CANDIDATE:
                if (data.candidate === undefined) {
                    console.error("Does not have candidate field");
                    return;
                }
                console.log("Adding ICE candidate");
                if (this.peerConnection?.remoteDescription) {
                    console.log("Adding ICE candidate...");
                    await this.peerConnection.addIceCandidate(data.candidate);
                } else {
                    console.log("Remote description not set, adding ICE candidate to queue");
                    this.iceCandidates.push(data.candidate);
                }
                break;

            case RemoteCommands.ERROR:
                console.error(`${data.command} command error: ${data.message}`);
                break;

            case RemoteCommands.PHONE_CONNECTED:
                this.phoneConnected = true;
                this.sessionID = data.sessionID;
                RemoteHandler.storeLocalSession(this.sessionID);
                this.removeQRCodeFromPage();
                // Create heartbeat interval to handle disconnection
                this.checkCount();
                this.heartbeatInterval = setInterval(this.checkCount.bind(this), 5000);
                break;

            case RemoteCommands.PING:
                console.log("sending ping acknoledgement");
                this.activeConnection?.send(
                    JSON.stringify({
                        command: RemoteCommands.POS_ACK,
                        targetConnectionID: this.remoteConnectionID,
                    }),
                );
                break;

            default:
                console.log("Unhandled command: ", command);
                break;
        }
    }

    /**
     * Creates a QR code for the remote session.
     * This QR code will be displayed on the main screen.
     * When the user scans it, they will be able to control the main screen from their phone.
     */
    public createQRCode() {
        if (!this.isSplashScreen()) {
            console.log("Not on splash screen, not creating QR code");
            return;
        }

        const remoteURL = process.env.REMOTE_PAGE_URL;
        if (!remoteURL) {
            console.error("REMOTE_PAGE_URL is undefined");
            return;
        }
        // const remoteURL = "http://localhost:8080"; // for local testing
        const qrCodeURL = `${remoteURL}/?sessionID=${this.sessionID}&${this.webRTCEnabled ? "enableWebRTC=true" : "enableWebRTC=false"}`;
        console.log("Creating remote QR code with url: ", qrCodeURL);

        // Create a container to hold the qr code canvas and some text
        const qrCodeContainer = document.createElement("div");
        qrCodeContainer.id = "remote-qr-code-container";
        const qrCodeText = document.createElement("h2");
        qrCodeText.innerText = "Control with your phone!";
        document.body.appendChild(qrCodeContainer);
        qrCodeContainer.appendChild(qrCodeText);

        // Create the qr code canvas itself
        const qrCodeCanvas = document.createElement("canvas");
        qrCodeCanvas.id = "remote-qr-code-canvas";
        qrCodeContainer.appendChild(qrCodeCanvas);

        // Generate the qr code onto the canvas
        QRCode.toCanvas(qrCodeCanvas, qrCodeURL, { width: 250, margin: 2 }, function (error) {
            if (error) {
                console.error(error);
            }
        });
    }

    /**
     * Removes the QR code from the page.
     */
    removeQRCodeFromPage() {
        console.log("Removing QR code from page");
        const qrCodeContainer = document.getElementById("remote-qr-code-container");
        if (qrCodeContainer) {
            qrCodeContainer.remove();
        }
    }

    /**
     * Handles errors from the WS.
     *
     * @param event the error event
     */
    private onError(event: Event) {
        console.log("WS error", event);
    }

    // https://webrtc.org/getting-started/peer-connections
    /**
     * Sets the remote description and sends back an answer
     * @param sdp
     */
    private async handleSDP(sdp: RTCSessionDescriptionInit, sourceConnectionID: string) {
        if (!checkWebRTCSupport()) {
            console.log("WebRTC not supported, not setting remote description");
            return;
        }
        try {
            this.peerConnection?.setRemoteDescription(sdp);
            for (const candidate of this.iceCandidates) {
                console.log("Adding ICE candidate...");
                await this.peerConnection?.addIceCandidate(candidate);
            }
            const answer = await this.peerConnection?.createAnswer();
            this.ws?.send(
                JSON.stringify({
                    command: RemoteCommands.SEND_DISPLAY_SDP_TO_REMOTE,
                    destinationConnectionID: sourceConnectionID,
                    sdp: answer,
                }),
            );
            await this.peerConnection?.setLocalDescription(answer);
        } catch (error) {
            console.error(`Error setting remote description: ${error}`);
        }
    }
}
