import { ImageResource } from "../audio_visual/visual/resources/ImageResource";
import { VisualElement } from "../audio_visual/visual/visualElement";
import { SceneObjectInterface } from "./SceneObjectInterface";

export class UXIcons {
    static json_has_icon(json: any) {
        return json["icon"] !== undefined;
    }

    static CopyIconToImageJson(json: any) {
        let result = ImageResource.CopyImageJson(json, "icon", "icon-");
        return result;
    }

    static json_to_visual_element(obj: SceneObjectInterface, json: any) {
        let result = new VisualElement(obj, UXIcons.CopyIconToImageJson(json), "ux");

        return result;
    }
}
