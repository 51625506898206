import { HasGeometryInterface, RectangleGeometry } from "../geometry/RectangleGeometry";
import { VisualElement } from "../audio_visual/visual/visualElement";
import { GettingReadyCallbackCollector } from "./GettingReadyCallbackCollector";
import { UXIcons } from "./UXIcons";
import { SceneObjectInterface } from "./SceneObjectInterface";
import { Interactive } from "./Interactive";
import { ResourceInterface } from "../audio_visual/visual/resources/resourceInterface";
import { OnDrawingParams } from "../audio_visual/visual/OnDrawingParams";

export class InteractivePointOfInterest implements SceneObjectInterface {
    static typename = "point_of_interest";
    static array_typename = "points_of_interest";
    interactive: Interactive;
    geometry: RectangleGeometry;
    json: any;
    visual_element: VisualElement;
    isVisualError = false;
    callback_collector?: GettingReadyCallbackCollector;
    callback_collector_promise?: Promise<void | GettingReadyCallbackCollector>;

    constructor(interactive: Interactive, json: any) {
        this.interactive = interactive;
        this.json = json;
    }
    get resources() {
        return this.interactive.resources;
    }
    get icanvas() {
        return this.interactive.icanvas;
    }

    get scene() {
        return this.interactive.scene;
    }
    get area() {
        return this.interactive.area;
    }
    get visualElements() {
        return this.scene.visualElements;
    }
    get application() {
        return this.scene.application;
    }
    get visual_geometry() {
        return undefined;
    }
    get icon_name() {
        return this.json.icon as string;
    }
    isFadedOut() {
        return this.scene.isFadedOut();
    }
    toScenePath() {
        return this.scene.toScenePath();
    }
    runActionJson(action: any) {
        this.scene.runActionJson(action);
    }
    get sceneGraph() {
        return this.scene.sceneGraph;
    }
    get scene_graph_node() {
        return this.scene.scene_graph_node;
    }
    get server_file_cache() {
        return this.scene.server_file_cache;
    }
    get simulation() {
        return this.scene.simulation;
    }
    get draw_order() {
        return this.scene.draw_order;
    }

    setHidden(value: boolean) {
        this.visual_element.setHidden(value);
    }

    initializeFromJson() {}

    isVisualContentReady() {
        if (!this.visual_element?.isReadyOrEmpty()) {
            return false;
        }
        return true;
    }

    startVisualContentGetReady(gettingReadyCallbackCollector: GettingReadyCallbackCollector) {
        if (!this.visual_element) {
            if (UXIcons.json_has_icon(this.json)) {
                this.visual_element = UXIcons.json_to_visual_element(this, this.json);

                let geometry_json: any = {};

                let point = [0.0, 0.0];
                let relativeTo: HasGeometryInterface | undefined = this.area;

                if (this.json["area.position"]) {
                    point = this.json["area.position"];
                } else if (this.json["scene.position"]) {
                    point = this.json["scene.position"];
                    relativeTo = this.scene;
                }
                geometry_json["geometry.point"] = point;
                this.geometry = RectangleGeometry.createGeometryFromJson_Relative(
                    geometry_json,
                    this,
                    relativeTo,
                    this.scene,
                );
            } else {
                this.visual_element = new VisualElement(this, this.json, this.getResourcePath());

                let geometry_json: any = {};

                let point = [0.0, 0.0];
                let relativeTo: HasGeometryInterface | undefined = this.scene;

                if (this.json["area.position"]) {
                    point = this.json["area.position"];
                    relativeTo = this.area;
                }

                geometry_json["geometry.rectangle"] = [point[0], point[1], 1, 1];
                this.geometry = RectangleGeometry.createGeometryFromJson_Relative(
                    geometry_json,
                    this,
                    relativeTo,
                );
            }
            let scene_area = this.scene.geometry.get_absolute_rect_shape();
            let area = this.geometry.get_absolute_rect_shape();

            this.visual_element.setDrawOrder(1.2);
        }

        this.visual_element.startVisualContentGetReady(gettingReadyCallbackCollector);
    }

    start() {
        if (!this.visual_element) {
            this.callback_collector = new GettingReadyCallbackCollector();
            this.startVisualContentGetReady(this.callback_collector);
            this.callback_collector_promise = this.callback_collector
                .newWaitPromiseForAllCallbacks()
                .then((result) => {
                    this.callback_collector_promise = undefined;

                    if (!this.callback_collector?.isCanceled) {
                        this.start();
                        this.icanvas.try_invalidated_draw();
                    }

                    this.callback_collector = undefined;
                });
            return;
        }

        //this.visual_element.onError = (resource) => this.onVisualError(resource);

        this.visual_element.start();

        this.visual_element.onDrawing = (params) => this.onVisualDrawing(params);
    }

    stop() {
        if (this.callback_collector) {
            this.callback_collector?.cancel();
        }
        if (this.visual_element && this.visual_element.isStopped === false) {
            this.visual_element.stop();
        }
    }

    onVisualDrawing(params: OnDrawingParams) {
        if (this.scene.simulation.isUXFadedOut()) {
            params.isCancel = true;
        }
    }

    onVisualError(resource: ResourceInterface) {
        this.isVisualError = true;
    }

    getResourcePath() {
        return this.json.resourcePath || this.interactive.getResourcePath();
    }

    getVideoFileInfoForStem(video_stem: any) {
        return this.scene.getVideoFileInfoForStem(video_stem);
    }
}
