import { KeyCodes } from "../../../input/KeyCodes.ts";
import { WebApplicationState } from "../../../WebApplicationState.ts";
import page from "page";
import { isMobileDevice } from "../../../auth/utils.ts";
import { AUTH_PAGE_TYPES } from "../../../auth/config.ts";

/**
 * Shows the login page by setting the viewport to a login page.
 * @param state the state of the application.
 */
export function showTVLoginPage(state: WebApplicationState) {
    let redirectURL: string | undefined;
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("redirect")) {
        // If redirect is not null, set the redirectURL to the value of the redirect parameter.
        redirectURL = urlParams.get("redirect") ?? undefined;
    }

    state.router.addPageToViewport(
        `
        <div class="login-page">
        <h1>Sign in to Cabin Platform</h1>
        <div id="login-buttons">
        <button id="signin-button">Sign in</button>
        <button id="guest-button">Continue as guest</button>
        ${!redirectURL ? '<button id="return">Return</button>' : ""}
        </div>
        </div>
        `,
        AUTH_PAGE_TYPES.LOGIN_MODAL,
    );

    let activeButton = 0;
    const buttons = document.querySelectorAll("button");
    buttons[activeButton].focus();

    // Handles the sign in button
    const signInHandler = () => {
        removeEventListeners();
        // If the device is a mobile device, go directly to the Auth0 login page.
        if (isMobileDevice()) {
            console.log("mobile device");
            // const options: AuthorizeOptions = {
            //     responseType: "token",
            //     clientID: AUTH_CONSTANTS.CLIENT_ID,
            //     redirectUri: window.location.origin,
            // };
            // state.auth.auth0Client.authorize(options);
        } else {
            // Check whether on tv or mobile, to either show QR code or just show login page.
            console.log("show TV login page");
            window.location.href = `/authorize?redirect=${redirectURL}`;
        }
    };

    /**
     * Handles the guest button.
     */
    const guestHandler = () => {
        if (redirectURL) {
            // Handle redirects from modules that require authentication.
            window.location.href = redirectURL;
        } else {
            state.auth.createGuestSession();
        }
    };

    /**
     * Returns the user to the previous page.
     */
    const returnHandler = () => {
        console.log("exit login page");
        state.router.resetPageToCanvas("login-modal");
        state.externalModules.closeModules();
        removeEventListeners();
        page.redirect("/");
    };

    /**
     * Handles keys pressed on the remote.
     * @param event the keydown event.
     */
    const keydownHandler = (event: KeyboardEvent) => {
        event.preventDefault();
        if (KeyCodes.Ups.includes(event.keyCode)) {
            if (activeButton > 0) {
                activeButton--;
            }
        } else if (KeyCodes.Downs.includes(event.keyCode)) {
            if (activeButton < 2) {
                activeButton++;
            }
        } else if (
            event.keyCode === KeyCodes.Home ||
            event.keyCode === KeyCodes.samsung_tv_back ||
            event.keyCode === KeyCodes.sony_tv_back ||
            KeyCodes.Backspaces.includes(event.keyCode)
        ) {
            // Return back to the previous page
            document.getElementById("return")?.click();
        } else if (event.keyCode === KeyCodes.Enter) {
            buttons[activeButton]?.click();
        }
        // Set the active button
        buttons[activeButton]?.focus();
    };

    // Add event listeners
    document.getElementById("signin-button")?.addEventListener("click", signInHandler);
    document.getElementById("guest-button")?.addEventListener("click", guestHandler);
    document.getElementById("return")?.addEventListener("click", returnHandler);
    addEventListener("keydown", keydownHandler);

    // Function to remove event listeners
    function removeEventListeners() {
        document.getElementById("signin-button")?.removeEventListener("click", signInHandler);
        document.getElementById("guest-button")?.removeEventListener("click", guestHandler);
        document.getElementById("return")?.removeEventListener("click", returnHandler);
        removeEventListener("keydown", keydownHandler);
    }
}
