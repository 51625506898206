import { ActionWithValue } from "../actions/action";
import { TextTemplateReplace } from "../../textTemplateReplacement";
import { SceneObjectInterface } from "../SceneObjectInterface";
export class CallWebFunction extends ActionWithValue {
    static verb_names = ["call_post_function", "call_get_function"];

    constructor(verb: string, name: string | undefined, json?: any) {
        super(verb, name, json);
    }

    run(param: SceneObjectInterface | undefined) {
        // let args = TextTemplateReplace.withReplacedPropertyValues(this.value_context, param);
        //let args_string = JSON.stringify(args); // ${args_string}
        let log = `depricated-action:${this.verb} ${this.value}`;
        console.warn(log);

        // call web function is depricated

        //

        // if (this.verb === CallWebFunction.verb_names[0]) {
        //     // $.post(this.value, args_string, function (data, status) {
        //     //   console.log(`success:${status}, ${log}`);

        //     // });
        //     let xhttp = new XMLHttpRequest();
        //     xhttp.onreadystatechange = function () {
        //         if (this.readyState === 4) {
        //             console.log(`${this.status}:${log}: ${this.responseText}`);
        //         }
        //     };
        //     xhttp.open("POST", this.value, true);
        //     xhttp.send(args_string);
        // }
    }
}
