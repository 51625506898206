import { AudioVisualPlaybackQueue } from "../../audio_visual/visual/AudioVisualPlaybackQueue";
import { AudioVisualScriptResourceCanvasElement } from "../../audio_visual/visual/resourceCanvasElements/AudioVisualScriptResourceCanvasElement";
import { UpdateContext } from "../../update";
import { SceneGraphAudioVisualState } from "./SceneGraphAudioVisualState";
import { SceneGraphAudioVisualStateSet } from "./SceneGraphAudioVisualStateSet";

type EventProperties = Record<string, Record<string, any>>;

interface SceneGraphAudioVisualScriptItemJson {
    "audio_visual.state": string;
    occurrence_percentage: number;
    [key: string]: any;
}

export class SceneGraphAudioVisualScriptItem {
    static EventsPropertyNamePrefix = "audio_visual.state.events.";
    static DurationPropertyPrefix = "duration.";

    json: SceneGraphAudioVisualScriptItemJson;
    event_properties: EventProperties = {};
    states: SceneGraphAudioVisualStateSet;
    state: SceneGraphAudioVisualState | undefined;

    constructor(json: SceneGraphAudioVisualScriptItemJson, states: SceneGraphAudioVisualStateSet) {
        this.json = json;
        this.states = states;
    }

    initializeFromJson(): void {
        for (const each in this.json) {
            if (each === "audio_visual.state") {
                this.state = this.states.getNewStateObjectByName(this.json[each]);
            } else if (each.startsWith(SceneGraphAudioVisualScriptItem.EventsPropertyNamePrefix)) {
                const str = each.slice(
                    SceneGraphAudioVisualScriptItem.EventsPropertyNamePrefix.length,
                );
                const dotIndex = str.indexOf(".");
                if (dotIndex !== -1) {
                    const event_name = str.slice(0, dotIndex);
                    const property_name = str.slice(dotIndex + 1);
                    if (!this.event_properties[event_name]) {
                        this.event_properties[event_name] = {};
                    }
                    this.event_properties[event_name][property_name] = this.json[each];
                }
            }
        }
    }

    get durationMilliseconds(): number {
        return this.durationSeconds * 1000;
    }
    get durationSeconds(): number {
        const duration: Record<string, number> = {};
        for (const key in this.json) {
            if (key.startsWith(SceneGraphAudioVisualScriptItem.DurationPropertyPrefix)) {
                const unit = key.slice(
                    SceneGraphAudioVisualScriptItem.DurationPropertyPrefix.length,
                );
                duration[unit] = this.json[key];
            }
        }
        let totalSeconds = 0;
        if (duration.minutes) totalSeconds += duration.minutes * 60;
        if (duration.hours) totalSeconds += duration.hours * 3600;
        if (duration.days) totalSeconds += duration.days * 86400;
        if (duration.seconds) totalSeconds += duration.seconds;
        if (duration.milliseconds) totalSeconds += duration.milliseconds / 1000;
        return totalSeconds;
    }

    get occurrence_percentage(): number {
        return this.json["occurrence_percentage"];
    }
    testOccurrence(): boolean {
        if (this.occurrence_percentage === undefined) {
            return true;
        }

        return Math.random() * 100 <= this.occurrence_percentage;
    }

    loadPlayback(
        playbackQueue: AudioVisualPlaybackQueue,
        previousState?: SceneGraphAudioVisualState,
    ) {
        this.state?.loadPlayback(playbackQueue, previousState);
    }

    update(av_script: AudioVisualScriptResourceCanvasElement, update_context: UpdateContext) {
        this.state?.update(av_script, update_context);
    }

    start() {
        this.state?.start();
    }
}
