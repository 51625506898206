import { AudioListenerScope } from "../audio_visual/audio/AudioListenerScope";

export interface ScenePathInterface {
    sceneGraphName?: string;
    sceneName?: string;
}

export class ScenePath implements ScenePathInterface {
    static onlySceneGraphNameFromJsonPath(json: any) {
        return new ScenePath(json.sceneGraphName);
    }
    static areLocationsTheSame(a: any, b: any) {
        return a.sceneGraphName === b.sceneGraphName && a.sceneName === b.sceneName;
    }
    sceneGraphName?: string;
    sceneName?: string;

    constructor(sceneGraphName?: string, sceneName?: string) {
        this.sceneGraphName = sceneGraphName;
        this.sceneName = sceneName;
    }

    toAudioScope() {
        return new AudioListenerScope(this.sceneGraphName, this.sceneName);
    }
}
