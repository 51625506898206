import { showLoadingPage } from "../routing/routes/loading/page.ts";
import { WebApplicationState } from "../WebApplicationState.ts";
import QRCode from "qrcode";
import { AUTH_CONSTANTS, QR_CODE_CONSTANTS } from "./config.ts";
import { DeviceCodeResponse } from "./types.ts";
import auth0 from "auth0-js";

export interface AuthInterface {
    initialize(): void;
    saveState(): void;
    shutdown(): void;
    createLoginSession(redirectURL: string | undefined): Promise<void>;
    createGuestSession(): void;
    showQRCode(deviceCodeResponse: DeviceCodeResponse): void;
}

export class InternalAuth implements AuthInterface {
    state: WebApplicationState;
    auth0Client: auth0.WebAuth;
    user: auth0.Auth0UserProfile;

    constructor(state: WebApplicationState) {
        this.state = state;
    }

    /**
     * Initializes the Auth0 client and checks for an existing user session.
     */
    async initialize() {
        this.auth0Client = new auth0.WebAuth({
            domain: AUTH_CONSTANTS.TENANT_URL,
            clientID: AUTH_CONSTANTS.CLIENT_ID,
        });
        this.isAuthenticated(); // Get the user profile, if there is one
    }

    /**
     * Uses a refresh token to get a new access token.
     * Refresh Token Rotation is used to allow the refresh token to be stored in local storage.
     *
     * Sources:
     * - https://auth0.com/docs/secure/tokens/refresh-tokens/use-refresh-tokens
     * - https://auth0.com/docs/secure/tokens/refresh-tokens/refresh-token-rotation
     * - https://auth0.com/blog/refresh-tokens-what-are-they-and-when-to-use-them/
     *
     * @param refreshToken the refresh token to use to get a new access token.
     * @returns
     */
    async useRefreshToken(refreshToken: string) {
        if (!refreshToken) return;
        try {
            const response = await fetch(`https://${AUTH_CONSTANTS.TENANT_URL}/oauth/token`, {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: new URLSearchParams({
                    client_id: AUTH_CONSTANTS.CLIENT_ID,
                    grant_type: "refresh_token",
                    refresh_token: refreshToken,
                }),
            });
            const data = await response.json();
            // Update the refresh token in local storage.
            if (data.error) {
                // The user session is over.
                localStorage.removeItem("refreshToken");
                localStorage.removeItem("idToken");
                console.log("invalid refresh token");
                return;
            }

            if (data) {
                localStorage.setItem("refreshToken", data.refresh_token);
                localStorage.setItem("idToken", data.id_token);
                console.log(data);

                // Fetch the userinfo with the new access token
                this.auth0Client.client.userInfo(data.access_token, (err, user) => {
                    if (err) {
                        console.error("Error getting user info: ", err);
                    } else if (user) {
                        this.user = user;
                        this.state.author.diagnostics_overlay.set_username(this.user.nickname);
                    } else {
                        console.error("User info was null");
                    }
                });
            }

            return data;
        } catch (error) {
            console.error("Error using refresh token: ", error);
            return null;
        }
    }

    /**
     * Checks if the user is authenticated.
     *
     * @returns true if the user is authenticated, false otherwise.
     */
    isAuthenticated() {
        if (this.user) {
            return true;
        }
        const idToken = localStorage.getItem("idToken");
        const refreshToken = localStorage.getItem("refreshToken");
        if (!refreshToken || !idToken) {
            return false;
        }

        this.auth0Client.validateToken(idToken, "", (err, result) => {
            if (err) {
                // At this point, check if the refresh token is valid.
                console.error("Error validating token, using refresh token: ", err);
                this.useRefreshToken(refreshToken);
                return false;
            } else if (result) {
                this.user = result;
                this.state.author.diagnostics_overlay.set_username(this.user.nickname);
                return true;
            } else {
                return false;
            }
        });
    }

    /**
     * Creates a guest session for the user.
     * This function is called when the user clicks the guest button.
     * For now, just allow the user to open the requested page for the duration of the page session.
     */
    createGuestSession() {
        showLoadingPage(this.state);
        console.log("create guest session");
        const requestedPageString = localStorage.getItem("requestedPage");
        const requestedPage = requestedPageString ? JSON.parse(requestedPageString) : undefined;
        this.state.externalModules.openModule(
            requestedPage.name,
            requestedPage.isFullscreen,
            requestedPage.isRedirect,
            true,
        );
    }

    /**
     * Creates a QR code login session for the user.
     * This function is called when the user clicks the login button.
     *
     * @param {String | undefined} redirectURL the URL to redirect to after login.
     */
    async createLoginSession(redirectURL: string | undefined = undefined) {
        const deviceCodeResponse = await this.requestDeviceCode();
        if (!deviceCodeResponse) {
            console.error("Error creating login session");
            return;
        }
        this.showQRCode(deviceCodeResponse);
        const data = await this.pollForAuthentication(deviceCodeResponse);

        this.auth0Client.client.userInfo(data.access_token, (err, user) => {
            if (err) {
                console.error("Error getting user info: ", err);
            } else if (user) {
                this.user = user;
                this.state.author.diagnostics_overlay.set_username(this.user.nickname);
                localStorage.setItem("idToken", data.id_token);
                localStorage.setItem("refreshToken", data.refresh_token);

                const requestedPage = JSON.parse(localStorage.getItem("requestedPage") || "{}");
                if (!requestedPage) {
                    console.error("Requested page was null, cannot open module");
                    return;
                }
                this.state.externalModules.openModule(
                    requestedPage.name,
                    requestedPage.isFullscreen,
                    requestedPage.isRedirect,
                );
            } else {
                console.error("User info was null");
            }
        });
    }

    /**
     * Requests a device code from the Auth0 server.
     * - https://auth0.com/docs/quickstart/native/device#request-device-code
     *
     * @returns the session information for a device code request.
     */
    async requestDeviceCode(): Promise<DeviceCodeResponse | null> {
        try {
            console.log("Requesting device code");
            const response = await fetch(`https://${AUTH_CONSTANTS.TENANT_URL}/oauth/device/code`, {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: new URLSearchParams({
                    client_id: AUTH_CONSTANTS.CLIENT_ID,
                    scope: AUTH_CONSTANTS.SCOPE,
                }),
            });
            const data = await response.json();
            console.log("Device code response: ", data);
            return data;
        } catch (error) {
            console.error("Error requesting device code: ", error);
            return null;
        }
    }

    /**
     * Creates a QR code for the user to scan.
     *
     * @param deviceCodeResponse: the response from the device code request.
     */
    showQRCode(deviceCodeResponse: DeviceCodeResponse) {
        if (!deviceCodeResponse.verification_uri) {
            console.error("Error showing QR code: verification_uri is null");
            return;
        }

        // Temporary solution to debug the QR code URL.
        console.log(deviceCodeResponse.verification_uri_complete);

        // Get all the HTML elements to update them based on the device code response.
        const qrCodeCanvas = document.getElementById("qr-code") as HTMLCanvasElement;
        const deviceURL = document.getElementById("device-url");
        const deviceCode = document.getElementById("device-code");
        if (!deviceURL || !deviceCode || !qrCodeCanvas) {
            console.error("Error showing QR code, missing an HTML element");
            return;
        }

        deviceURL.innerText = deviceCodeResponse.verification_uri;
        deviceCode.innerText = deviceCodeResponse.user_code;

        QRCode.toCanvas(
            qrCodeCanvas,
            deviceCodeResponse.verification_uri_complete,
            QR_CODE_CONSTANTS.STYLES,
            QR_CODE_CONSTANTS.ERR_FN,
        );
    }

    /**
     * Polls the Auth0 server for the user's authentication status.
     */
    async checkAuthStatus(deviceCode: string): Promise<any> {
        try {
            console.log("Polling for authentication");
            const response = await fetch(`https://${AUTH_CONSTANTS.TENANT_URL}/oauth/token`, {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: new URLSearchParams({
                    client_id: AUTH_CONSTANTS.CLIENT_ID,
                    device_code: deviceCode,
                    grant_type: AUTH_CONSTANTS.GRANT_TYPE,
                }),
            });
            const data = await response.json();
            console.log("Poll for authentication response", data);
            return data;
        } catch (error) {
            console.error("Error polling for authentication: ", error);
            return null;
        }
    }

    /**
     * Polls for a user's authentication status.
     *
     * @param deviceCodeResponse the response from the device code request.
     * @returns a promise that resolves when the user is authenticated.
     */
    async pollForAuthentication(deviceCodeResponse: DeviceCodeResponse): Promise<any> {
        return new Promise((resolve, reject) => {
            let interval = deviceCodeResponse.interval * 1000;
            let attempts = 0;

            const pollInterval = setInterval(async () => {
                try {
                    const data = await this.checkAuthStatus(deviceCodeResponse.device_code);

                    if (data.error) {
                        if (data.error === "authorization_pending") {
                            if (attempts++ >= AUTH_CONSTANTS.MAX_NUM_POLLS) {
                                clearInterval(pollInterval);
                                reject(new Error("Authentication timeout"));
                            }
                            return; // Continue polling
                        }
                        if (data.error === "slow_down") {
                            interval += 1000; // Add a second to the interval
                            return; // Continue polling
                        }

                        // Otherwise, cannot authenticate the user.
                        // Send them back to the /login page.
                        clearInterval(pollInterval);
                        reject(new Error(data.error));
                        window.location.href = "/login";
                    } else {
                        clearInterval(pollInterval);
                        resolve(data);
                    }
                } catch (error) {
                    clearInterval(pollInterval);
                    reject(error);
                }
            }, interval);
        });
    }

    // TODO - what is this for?
    saveState() {
        console.log("Internal Auth Save State");
    }

    shutdown() {
        this.saveState();
    }
}
