import { Simulation } from "../simulation";
import { SceneGraph } from "./scene_graph";
import { ScenePath } from "./ScenePath";

export class SceneGraphSet {
    simulation: Simulation;
    scene_graphs: SceneGraph[] = [];
    default_scene_graph?: SceneGraph;

    constructor(simulation: Simulation) {
        this.simulation = simulation;
    }

    get resources() {
        return this.simulation.resources;
    }
    get sceneGraphNode() {
        return this.player.sceneGraphNode;
    }
    get player() {
        return this.simulation.player;
    }
    get globalSceneGraph() {
        return this.default_scene_graph;
    }
    get defaultNavigationSceneGraphName() {
        return this.globalSceneGraph?.defaultSceneGraph;
    }
    get defaultNavigationSceneGraph() {
        return this.getSceneGraph(this.defaultNavigationSceneGraphName);
    }

    async initialize() {
        let default_scene_graph_json = this.resources.getFetchedJson(
            this.resources.getNavigationGraphPathFromName(""),
        );

        this.default_scene_graph = new SceneGraph(this.simulation, default_scene_graph_json);
        this.default_scene_graph.initializeFromJson();
    }

    getSceneGraphByIndex(index: number) {
        return this.scene_graphs[index];
    }

    getSceneGraphByPath(path: ScenePath) {
        return this.getSceneGraph(path.sceneGraphName);
    }

    getSceneGraphSceneByPath(path: ScenePath) {
        let graph = this.getSceneGraphByPath(path);

        return graph?.findSceneByPath(path);
    }

    isSceneGraphFetched(name: string) {
        let path = this.resources.getNavigationGraphPathFromName(name);
        return this.resources.isJsonFetched(path);
    }

    fetchSceneGraphPromise(name: string) {
        for (let index = 0; index < this.scene_graphs.length; index++) {
            const element = this.scene_graphs[index];
            if (element.name === name) {
                return Promise.resolve(element);
            }
        }

        let path = this.resources.getNavigationGraphPathFromName(name);
        let p = this.resources.getOrFetchJsonPromise(path);
        p = p.then(async (result) => {
            if (result === 404) {
                return result;
            }
            let newSceneGraph = this.pushNewSceneGraphFromJson(path, result);

            if (newSceneGraph) {
                await newSceneGraph.async_fetch_dependencies();
                newSceneGraph.initializeFromJson();
            }

            return result;
        });

        return p;
    }

    pushNewSceneGraphFromJson(path: string, json: any) {
        if (json === undefined) {
            return undefined;
        }

        let newSceneGraph = new SceneGraph(
            this.simulation,
            json,
            this.globalSceneGraph,
            path,
            this.scene_graphs.length,
        );

        this.scene_graphs.push(newSceneGraph);
        return newSceneGraph;
    }

    getSceneGraph(name: string | undefined) {
        if (name === undefined) {
            return undefined;
        }

        for (let index = 0; index < this.scene_graphs.length; index++) {
            const element = this.scene_graphs[index];
            if (element.name === name) {
                return element;
            }
        }

        let path = this.resources.getNavigationGraphPathFromName(name);
        let json = this.resources.getFetchedJson(path);
        let result = this.pushNewSceneGraphFromJson(path, json);
        result?.initializeFromJson();
        return result;
    }
}
