import { AmbianceContext } from "./AmbianceContext.ts";
import { AudioAmbianceGroup } from "./AudioAmbianceGroup.ts";
import { AudioAmbianceSet } from "./audioAmbianceSet.ts";
import { AudioPlaylistConfig, PlaylistConfig } from "./playlistAPI/PlaylistConfig.ts";
import { resolveRadioPlaylist } from "./playlistAPI/Radio.ts";
import { Stem } from "./stem.ts";

type stringFunction = () => string;

type PlaylistWithName = {
    name: string;
    playlist: PlaylistConfig;
};

type ScenePlaylistJSON = {
    name: string;
    type: string;
    playlistId?: string;
    gain: number;
};

export class AudioAmbiance {
    ambianceSet?: AudioAmbianceSet;
    audioContext: AmbianceContext;
    json: any;
    playlistConfig: any;
    stems: Stem[] = [];
    playlists: PlaylistWithName[] = [];
    sound_effects_played: Stem[] = [];
    parentGetResourcePath: stringFunction;

    constructor(
        audioContext: AmbianceContext,
        json: any = undefined,
        parentGetResourcePath: stringFunction,
    ) {
        this.audioContext = audioContext;
        this.parentGetResourcePath = parentGetResourcePath;

        this.json = json || {};

        this.json.stems?.forEach((element: Stem) => {
            let stem = this.newStem(element);
            this.stems.push(stem);
        });
        if (!this.json?.playlists) {
            return;
        }
        const playlists: PlaylistWithName[] = this.json.playlists.map((playlist: any) => {
            return {
                name: playlist.name,
                playlist: this.newAudioPlaylist(playlist),
            };
        });
        playlists.forEach((playlist: PlaylistWithName) => {
            this.playlists.push(playlist);
        });
    }
    get regionName() {
        return this.audioContext.regionName;
    }

    get areaName() {
        return this.audioContext.areaName;
    }

    onListenLocationExit(audio_ambiance_group: AudioAmbianceGroup) {
        for (const each of this.sound_effects_played) {
            if (each.json.isListenerAreaLocal === true) {
                audio_ambiance_group.audio.connectedStems?.stopStem(each);
            }
        }
        this.sound_effects_played = [];
    }

    onPlaySoundEffects(stems: Stem[]) {
        this.sound_effects_played.push(...stems);
    }

    collectAmbianceGroup(group: AudioAmbianceGroup, context: AmbianceContext) {}

    collectConnectedAmbianceGroup(group: AudioAmbianceGroup, context: AmbianceContext) {}

    newAudioPlaylist(playlist: ScenePlaylistJSON) {
        // TODO add proper error handling to ensure data is valid
        switch (playlist.type) {
            case "radio":
                const config = resolveRadioPlaylist(playlist.name);
                if (config == null) {
                    console.error(`failed to resolve radio playlist for ${playlist.name}`);
                    return;
                }
                return new AudioPlaylistConfig({
                    ...config,
                    gain: playlist.gain,
                });
            default:
                return;
        }
    }

    addStemFromJson(json: any) {
        let stem = this.newStem(json);
        this.stems.push(stem);
        return stem;
    }

    newStem(element: any) {
        return new Stem(element, this.getResourcePath.bind(this));
    }

    getResourcePath(): string {
        const resourcePath = this.json.resourcePath || this.parentGetResourcePath();
        return resourcePath;
    }

    get_soundscape() {
        let result: Stem[] = [];

        this.stems.forEach((each) => {
            if (!each.isEnabled) {
                return;
            }
            result.push(each);
        });

        this.ambianceSet?.collection.forEach((each_ambiance) => {
            if (each_ambiance === this) {
                return;
            }

            each_ambiance.stems.forEach((each) => {
                if (!each.isEnabled) {
                    return;
                }
                if (each.hasRegionGain()) {
                    if (result.some((each_result) => each.isEquivilentToStem(each_result))) {
                        return;
                    }
                    result.push(each.toRegionStem());
                }
            });
        });

        return result;
    }
}
