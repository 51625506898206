import { AudioVisualPlaybackQueue } from "../../audio_visual/visual/AudioVisualPlaybackQueue";
import { AudioVisualScriptResourceCanvasElement } from "../../audio_visual/visual/resourceCanvasElements/AudioVisualScriptResourceCanvasElement";
import { UpdateContext } from "../../update";
import { SceneGraphAudioVisualScriptItem } from "./SceneGraphAudioVisualScriptItem.ts";
import { SceneGraphAudioVisualScripts } from "./SceneGraphAudioVisualScripts.ts";
import { SceneGraphAudioVisualState } from "./SceneGraphAudioVisualState";

export class SceneGraphAudioVisualScript {
    json: any;
    items: SceneGraphAudioVisualScriptItem[] = [];
    av_scripts: SceneGraphAudioVisualScripts;
    item_index: number = 0;
    item_index_start_time: number | undefined;

    constructor(json: any, av_scripts: SceneGraphAudioVisualScripts) {
        this.json = json;
        this.av_scripts = av_scripts;
    }

    get isDefault(): boolean {
        return this.json["isDefault"];
    }

    get script(): any {
        return this.json["script"];
    }

    getActiveItem(): SceneGraphAudioVisualScriptItem | undefined {
        return this.items[this.item_index];
    }

    initializeFromJson(): void {
        if (this.script) {
            this.items = [];
            for (const each in this.script) {
                const new_item = new SceneGraphAudioVisualScriptItem(
                    this.script[each],
                    this.av_scripts.getStates(),
                );
                new_item.initializeFromJson();
                this.items.push(new_item);
            }
        }
    }

    loadPlayback(
        playbackQueue: AudioVisualPlaybackQueue,
        previousState?: SceneGraphAudioVisualState,
    ) {
        let active = this.getActiveItem();
        if (!active) {
            return;
        }
        active.loadPlayback(playbackQueue, previousState);
    }

    start() {
        let active = this.getActiveItem();
        if (!active) {
            return;
        }
        active.start();
    }

    update(av_script: AudioVisualScriptResourceCanvasElement, update_context: UpdateContext) {
        if (update_context.interval !== UpdateContext.FiveSecondMS) {
            return;
        }

        let active = this.getActiveItem();
        if (!active) {
            return;
        }

        if (this.item_index_start_time === undefined) {
            this.item_index_start_time = update_context.time;
        }

        if (this.item_index_start_time + active.durationMilliseconds <= update_context.time) {
            this.startNextScriptItem(av_script, update_context);
            return;
        }

        active.update(av_script, update_context);
    }

    startNextScriptItem(
        av_script: AudioVisualScriptResourceCanvasElement,
        update_context: UpdateContext,
    ) {
        let next = this.item_index;
        let foundNext = false;
        for (let index = 0; index < this.items.length; index++) {
            next += 1;
            if (next >= this.items.length) {
                next = 0;
            }
            if (this.items[next].testOccurrence()) {
                foundNext = true;
                break;
            }
        }
        if (!foundNext) {
            return;
        }

        let previous = this.getActiveItem();

        this.item_index = next;
        this.item_index_start_time = update_context.time;

        let active = this.getActiveItem();
        if (!active || !active.state) {
            return;
        }

        av_script.startSubState(active.state, previous?.state);
    }
}
