//@ts-check

import { LocalServerFileCache } from "../LocalServerFileCache";
import { WebApplication } from "../webApplication";

/**
 * @callback onDrawingFunction
 * @param {OnDrawingParams} params
 */

/**
 *
 */
export class FileResourceRequest {
    static LargeDataFolder = "dataLarge/";

    system_path: string;
    path: string;
    category_path: string;
    categorized_path: string;
    name: string;
    extension: string;
    fullpath: string;
    isWorkInProgress: boolean;
    isArchive: boolean;
    application: WebApplication | undefined;
    server_file_cache: LocalServerFileCache;

    constructor(
        path = "",
        name = "",
        extension = "",
        category_path = "",
        categorized_path = "",
        isDataLarge = true,
        isWorkInProgress = false,
        isArchive = false,
        application?: WebApplication,
    ) {
        this.setPath(path, false);
        this.setCategoryPath(category_path, false);
        this.setCategorizedPath(categorized_path, false);
        this.system_path = "./assets/";

        if (isDataLarge) {
            this.system_path += FileResourceRequest.LargeDataFolder;
        }

        this.setExtension(extension, false);
        this.name = name;
        this.isWorkInProgress = isWorkInProgress;
        this.isArchive = isArchive;
        this.updateFullpath();
        this.application = application;
    }

    change_to_filename(name: string) {
        this.name = name;
        this.updateFullpath();
    }

    setCategoryPath(category_path: string, isUpdateFullpath = true) {
        this.category_path = category_path;
        if (this.category_path && this.category_path.endsWith("/") == false) {
            this.category_path += "/";
        }
        if (isUpdateFullpath) {
            this.updateFullpath();
        }
    }

    setCategorizedPath(categorized_path: string, isUpdateFullpath = true) {
        this.categorized_path = categorized_path;
        if (this.categorized_path && this.categorized_path.endsWith("/") == false) {
            this.categorized_path += "/";
        }
        if (isUpdateFullpath) {
            this.updateFullpath();
        }
    }

    setPath(path: string, isUpdateFullpath = true) {
        this.path = path;
        if (this.path && this.path.endsWith("/") == false) {
            this.path += "/";
        }
        if (isUpdateFullpath) {
            this.updateFullpath();
        }
    }

    setExtension(extension: string, isUpdateFullpath = true) {
        this.extension = extension;
        if (isUpdateFullpath) {
            this.updateFullpath();
        }
    }

    get filename() {
        return this.fullpath;
    }

    // prependPath(path, isUpdateFullpath = true) {
    //   this.path = `${path}${this.path}`;
    //   if (isUpdateFullpath) {
    //     this.updateFullpath();
    //   }
    // }

    // appendPath(path, isUpdateFullpath = true) {
    //   this.path = `${this.path}${path}`;
    //   if (isUpdateFullpath) {
    //     this.updateFullpath();
    //   }
    // }

    fullpathWithExtension(extension: string, category_path = this.category_path) {
        return `${this.getCategorizedPath(category_path)}${this.name}${extension}`;
    }

    updateFullpath() {
        var updatedPath = this.getCategorizedPath();

        if (this.isWorkInProgress) {
            updatedPath = `${updatedPath}${"wip-demo/"}`;
        }

        this.fullpath = `${updatedPath}${this.name}${this.extension}`;
    }

    getCategorizedPath(category_path = this.category_path) {
        return this.system_path + this.path + category_path + this.categorized_path;
    }

    toUrlPath() {
        return this.fullpath;
    }

    toURLName() {
        return this.name;
    }

    toURLNameAndExtension() {
        return this.name + this.extension;
    }
}
