import { CustomerAccount } from "../customerAccount";
import { ModuleActivationRewards } from "../externalModules/ModuleActivationRewards";

export class CustomerAccountTokenRewards extends ModuleActivationRewards {
    source_name: string;
    account: CustomerAccount;

    constructor(account: CustomerAccount, source_name: string) {
        super();
        this.account = account;
        this.source_name = source_name;
    }

    onStart(tokens_per_second: number) {}

    onDepositTokens(tokens_amount: number) {}

    onEnd() {}
}
