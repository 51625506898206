import { AudioAmbiance } from "./audioAmbiance.ts";
import { AudioAmbianceGroup } from "./AudioAmbianceGroup.ts";
import { WebAudioInterface } from "./WebAudioComponent.ts";

export class AmbianceAudioListener {
    audio: WebAudioInterface;
    ambianceGroup: AudioAmbianceGroup;

    constructor(audio: WebAudioInterface) {
        this.audio = audio;
        this.ambianceGroup = new AudioAmbianceGroup(this.audio);
    }

    onStartAudioOnGesture() {
        this.ambianceGroup.onStartAudioOnGesture();
    }

    addAudioAmbiance(ambiance: AudioAmbiance) {
        this.ambianceGroup.add(ambiance);
    }

    removeAudioAmbiance(ambiance: AudioAmbiance, next_ambiance?: AudioAmbiance) {
        if (next_ambiance !== undefined) {
            this.ambianceGroup.transition(ambiance, next_ambiance);
        } else {
            this.ambianceGroup.remove(ambiance);
        }
    }

    // start() {
    //   this.ambianceGroup.start();
    // }
}
