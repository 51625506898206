import { AudioVisualScriptResourceCanvasElement } from "../../audio_visual/visual/resourceCanvasElements/AudioVisualScriptResourceCanvasElement";
import { Action } from "../actions/action";
import { SceneObjectInterface } from "../SceneObjectInterface";

export class AudioVisualScriptNextStateAction extends Action {
    constructor(verb: string, name: string | undefined, json: any) {
        super(verb, name);
    }

    run(interactive: SceneObjectInterface | undefined) {
        console.log("action:AudioVisualScriptNextStateAction");
        AudioVisualScriptResourceCanvasElement.nextState(interactive?.scene);
    }
}
