import { AssetObjectInterface } from "../../LocalServerFileCache";

export class AssetDatabase {
    static storeName = "assets";
    static dbName = "localServerFileCache";

    assets: any = {};
    dataInterface: AssetDataInterface;

    loadFromJson(json: any) {
        this.assets = json;
        // if (this.json.db_version === undefined) {
        //   this.json.db_version = 1;
        // }
    }

    saveToJson() {
        return this.assets;
    }

    setDataInterface(dataInterface: AssetDataInterface) {
        this.dataInterface = dataInterface;
    }

    async downloadNewAssets(server_asset_list: any[]) {
        await this.dataInterface?.downloadNewAssets(server_asset_list, this);
    }

    loadFromCacheOrUrl(assetObject: AssetObjectInterface, url: string, is_cache_enabled = true) {
        if (this.dataInterface && is_cache_enabled) {
            this.dataInterface.loadFromCacheOrUrl(this, assetObject, url);
        } else {
            assetObject.src = url;
        }
    }
}

export class AssetDataInterface {
    async downloadNewAssets(server_asset_list: any[], asset_database: AssetDatabase) {
        return Promise.resolve();
    }

    loadFromCacheOrUrl(
        asset_database: AssetDatabase,
        assetObject: AssetObjectInterface,
        url: string,
    ) {}
}

export class IndexedDBAssetDataInterface extends AssetDataInterface {
    db: IDBDatabase;

    constructor(db: IDBDatabase) {
        super();
        this.db = db;
    }

    downloadFile(url: string, info: any, asset_database: AssetDatabase) {
        let self = this;

        let p = new Promise((resolve) => {
            let xhr = new XMLHttpRequest();
            xhr.open("GET", url);
            xhr.responseType = "blob";

            xhr.onload = function () {
                let data = { path: url, info: info };

                let transaction = self.db.transaction([AssetDatabase.storeName], "readwrite");
                let store = transaction.objectStore(AssetDatabase.storeName);

                let storeData = { path: url, info: info, data: this.response };
                let request = store.put(storeData);
                request.onerror = function (e) {
                    //let name = e.target.error.name;
                    resolve(data);
                };
                request.onsuccess = function (e) {
                    asset_database.assets[url] = data;
                    console.log(`end download ${url}`);
                    resolve(data);
                };
            };

            console.log(`start download ${url}`);
            xhr.send();
        });
        return p;
    }

    async downloadNewAssets(server_asset_list: any[], asset_database: AssetDatabase) {
        let promises = [];

        for (const each_entry of server_asset_list) {
            if (each_entry.path === undefined) {
                continue;
            }

            if (each_entry.path.startsWith("__")) {
                continue;
            }
            let found = asset_database.assets[each_entry.path];

            if (found && found.info.md5_hash === each_entry.md5_hash) {
                continue;
            }

            let p = this.downloadFile(each_entry.path, each_entry, asset_database);
            if (p) {
                promises.push(p);
            }
        }

        await Promise.all(promises);
    }

    loadFromCacheOrUrl(
        asset_database: AssetDatabase,
        assetObject: AssetObjectInterface,
        url: string,
    ) {
        let found = asset_database.assets[url];

        if (found) {
            let transaction = this.db.transaction([AssetDatabase.storeName], "readwrite");
            transaction.objectStore(AssetDatabase.storeName).get(url).onsuccess = function (event) {
                if (!this.result) {
                    assetObject.src = url;
                    return;
                }

                let imgFile = this.result.data;

                console.log("load from cache: " + this.result.path);

                let URL = window.URL || window.webkitURL;

                let imgURL = URL.createObjectURL(imgFile);

                assetObject.onload = () => URL.revokeObjectURL(imgURL);
                assetObject.src = imgURL;
            };
        } else {
            assetObject.src = url;
        }
    }
}
