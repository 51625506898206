import { FileResourceRequest } from "../../../resources/FileResourceRequest.ts";
import { PlaylistItem, StemJSON } from "./types.ts";

/**
 * Ensures that the provided gain value is within the valid range of 0.0 to 1.0.
 *
 * If the input value is less than 0.0, the function returns 0.0.
 * If the input value is greater than 1.0, the function returns 1.0.
 * Otherwise, the original gain value is returned unchanged.
 *
 * @param gain - The input gain value to be clamped.
 * @returns A valid gain value constrained to the range [0.0, 1.0].
 */
export const boundGain = (gain: number): number => {
    if (gain <= 0.0) {
        return 0.0;
    }
    if (gain >= 1.0) {
        return 1.0;
    }
    return gain;
};

export const setGain = (audioContext: AudioContext, node: GainNode, gain: number) => {
    const boundedGain = boundGain(gain);
    node.gain.setValueAtTime(boundedGain, audioContext.currentTime);
};

/**
 * Factory function for generating playlist items
 */
export const createAudioPlaylistItemFromStem = (
    resource: FileResourceRequest,
    stem: StemJSON,
): PlaylistItem => {
    return Object.assign(
        {},
        {
            id: stem.audioSrc,
            url: resource.toUrlPath(),
            gain: stem.gain,
        },
    );
};
