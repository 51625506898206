import { GettingReadyCallbackCollector } from "./GettingReadyCallbackCollector";
import { Scene } from "./scene";
import { SceneGraph } from "./scene_graph";
/**
 *
 */
export class SceneChangeRequest {
    isPushNavigationHistory: boolean;
    location: any;
    error: any;
    startedAt: number;
    isCanceled: boolean;
    // isPrimary: boolean;
    isCompleted: boolean;
    //isLoading: boolean;
    isLoadingCompleted: boolean;
    fallback_request_on_error?: SceneChangeRequest;

    sceneGraphLoadingPromise?: Promise<any>;
    callback_collector: GettingReadyCallbackCollector;
    callback_collector_promise?: Promise<any>;
    ux_scene_loading_promise?: Promise<any>;

    foundSceneGraph: SceneGraph | undefined;
    foundScene: Scene | undefined;

    constructor(location: any, isPushNavigationHistory = true) {
        this.location = location;
        this.isPushNavigationHistory = isPushNavigationHistory;
    }
}

// export class SceneChangeRequestInProgress {
//     request:SceneChangeRequest;
//     /**
//      *
//      */
//     constructor(request:SceneChangeRequest) {
//      this.request=request;

//     }
// }
