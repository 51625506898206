import { PlaylistJSON, PlaylistsJSON, PlaylistType, StemJSON } from "./types.ts";

export class NullPlaylistJson implements PlaylistJSON {
    name: string;
    type: PlaylistType;
    stems: StemJSON[];
    message: string;

    constructor(message: string) {
        this.name = "error";
        this.type = "null";
        this.stems = [];
        this.message = message;
    }
}

export class NullPlaylistsJson implements PlaylistsJSON {
    name: string;
    type: PlaylistType;
    message: string;
    playlists: PlaylistJSON[];

    constructor(message: string) {
        this.name = "error";
        this.playlists = [];
        this.message = message;
    }
}
