import { NavigationMode, SceneInteractionMode } from "../../input/selectionMode";
import { Action } from "../actions/action";
import { SceneObjectInterface, SceneObjectInterfaceExtensions } from "../SceneObjectInterface";

export class ChangeSelectionMode extends Action {
    value: string;
    constructor(verb: string, name: string | undefined, json?: any) {
        super(verb, name);
        this.value = json["value"];
    }

    run(interactive: SceneObjectInterface | undefined) {
        console.log("action:ChangeSelectionMode");
        const scene = SceneObjectInterfaceExtensions.sceneAsScene(interactive);
        let sim = scene?.simulation;

        if (this.value === "interactive") {
            sim?.selectionMode.transitionState(new SceneInteractionMode());
        } else if (this.value === "navigation") {
            sim?.selectionMode.transitionState(new NavigationMode());
        }
    }
}
