import * as htmlHelper from "../htmlHelper.ts";
export class StackLayout {
    static addStyleToListElement(element: HTMLElement): void {
        element.style.listStyleType = "none";
        element.style.margin = "0";
        element.style.padding = "0";
    }
    element: HTMLUListElement;
    table_layout_cache?: HTMLTableElement;
    constructor() {
        const ul = document.createElement("ul");
        StackLayout.addStyleToListElement(ul);
        this.element = ul;
    }
    get table_layout(): HTMLTableElement {
        if (!this.table_layout_cache) {
            this.table_layout_cache = htmlHelper.BorderlessTable();
            this.add(this.table_layout_cache);
        }
        return this.table_layout_cache;
    }

    addAsTableRow(elements: (HTMLElement | null)[]): void {
        const table = this.table_layout;
        htmlHelper.addTableRow(table, elements);
    }
    add(element: HTMLElement | null): void {
        if (element) {
            const li = document.createElement("li");
            li.appendChild(element);
            this.element.appendChild(li);
        }
    }
}
