import { BaseModules, ModulesCommon } from "./ModulesCommon.ts";
import { AuthorInterface } from "../sceneAuthorInterface/authorInterface.ts";
/**
 *
 */
export class SceneModules extends BaseModules {
    authoringInterface: AuthorInterface | undefined;
    json: any;

    constructor(json: any = {}) {
        super();
        this.json = json;
    }

    initialize() {}

    saveState() {
        // this.resources.setLocalStorageItemAsJson(this.stroageItemName(), this.json);
    }

    shutdown() {
        this.saveState();
    }
}
