let c2 = require("c2.js");
let geometry_js = require("../geometry");
import { DrawScope } from "../audio_visual/DrawScope";
import { InteractiveCanvas } from "../audio_visual/interactive_canvas";
import { OnDrawingParams } from "../audio_visual/visual/OnDrawingParams";
import { VisualElement } from "../audio_visual/visual/visualElement";
import { RectangleGeometry } from "../geometry/RectangleGeometry";
import { GettingReadyCallbackCollector } from "./GettingReadyCallbackCollector";
import { Interactive } from "./Interactive";
import { InteractiveEvent } from "./InteractiveEvent";
import { SceneObjectInterface } from "./SceneObjectInterface";
import { SceneGraph } from "./scene_graph";
import { ScenePath } from "./ScenePath";

export class InteractiveArea implements SceneObjectInterface {
    static typename = "interactive.area";

    interactive: Interactive;
    geometry: RectangleGeometry;
    json: any;
    visual_element: VisualElement;
    visual_geometry: RectangleGeometry;

    constructor(interactive: Interactive, json: any) {
        this.interactive = interactive;
        this.json = json;
    }
    get resources() {
        return this.interactive.resources;
    }

    get icanvas() {
        return this.interactive.icanvas;
    }

    get scene() {
        return this.interactive.scene;
    }

    get visualElements() {
        return this.scene.visualElements;
    }

    get application() {
        return this.scene.application;
    }

    initializeFromJson() {}

    runActionJson(action: any): void {}

    isVisualContentReady() {
        if (!this.visual_element?.isReadyOrEmpty()) {
            return false;
        }
        return true;
    }

    onVisualDrawing(params: OnDrawingParams) {
        if (this.scene.isFadedOut()) {
            params.isCancel = true;
        }
    }

    drawFrame(icanvas: InteractiveCanvas) {
        this.visual_element.drawFrame(icanvas);
        //console.log("area draw");

        // if (this.scene.simulation.is_ux_faded_out()) {
        //   return;
        // }

        this.geometry.border_size = 2;
        let outline_scope = DrawScope.Debug;

        if (this.scene.simulation.selectionMode.isSceneInteractionMode()) {
            if (this.interactive.isSelected && !this.interactive.hasPointOfInterest()) {
                let ux_scene = this.scene.simulation.getUXScene();
                if (ux_scene) {
                    let ux_scene_interactive_layer = ux_scene.sceneInteractiveLayer;

                    if (!ux_scene_interactive_layer.isFadedOut) {
                        this.geometry.border_size = 5;
                    }
                }
            }
        }

        this.geometry.draw(icanvas, outline_scope);
    }

    mousedown(ievent: InteractiveEvent) {}

    isIEventOnInteractiveArea(ievent: InteractiveEvent) {
        let mouse_point = geometry_js.mouseEventToPoint(ievent.e);
        return this.geometry.containsPoint(mouse_point);
    }

    getScreenSpaceAreaRect() {
        return this.geometry.get_absolute_rect_shape();
    }

    mouseup(ievent: InteractiveEvent) {
        if (this.isIEventOnInteractiveArea(ievent)) {
            this.interactive.onMouseUp(ievent, this);
        }
    }

    onTouchTap(ievent: InteractiveEvent) {
        if (this.isIEventOnInteractiveArea(ievent)) {
            this.interactive.onMouseUp(ievent, this);
        }
    }

    mousemove(ievent: InteractiveEvent) {}

    isSelectable() {
        return (
            this.geometry !== undefined &&
            this.geometry.scale !== 0 &&
            this.json.isSelectable !== false
        );
    }

    isFadedOut() {
        return this.scene.isFadedOut();
    }

    toScenePath() {
        return this.scene.toScenePath();
    }

    get sceneGraph() {
        return this.scene.sceneGraph;
    }

    get server_file_cache() {
        return this.scene.server_file_cache;
    }

    get simulation() {
        return this.scene.simulation;
    }

    get draw_order() {
        return this.scene.draw_order;
    }

    startVisualContentGetReady(gettingReadyCallbackCollector: GettingReadyCallbackCollector) {
        if (!this.visual_element) {
            this.visual_element = new VisualElement(this, this.json, this.getResourcePath());
        }

        this.geometry = RectangleGeometry.createGeometryFromJson_Relative(
            this.json,
            this,
            this.scene,
            this.scene,
        );

        let test_rect = this.geometry.get_absolute_shape();

        this.visual_element.startVisualContentGetReady(gettingReadyCallbackCollector);
    }

    start() {
        this.visual_element.start();
        this.visual_element.onDrawing = (params) => this.onVisualDrawing(params);
    }

    stop() {
        this.visual_element.stop(undefined);
    }

    getResourcePath() {
        return this.json.resourcePath || this.interactive.getResourcePath();
    }

    setAreaReletivePosition(point: any) {
        if (this.geometry) {
            this.geometry.setPosition(point);
            this.icanvas.invaidate();
        }
    }

    getVideoFileInfoForStem(video_stem: any) {
        return this.scene.getVideoFileInfoForStem(video_stem);
    }
    // toRect(scaleToCanvas = false) {

    //   return this.getScreenSpaceAreaRect();
    // }
}
