import { Action } from "../actions/action";
import { SceneObjectInterface, SceneObjectInterfaceExtensions } from "../SceneObjectInterface";

export class BackAction extends Action {
    constructor(verb: string, name: string | undefined, json?: any) {
        super(verb, name);
    }
    canRun(interactive: SceneObjectInterface | undefined): boolean {
        const scene = SceneObjectInterfaceExtensions.sceneAsScene(interactive);

        return scene?.canNavigateBack() === true;
    }
    run(interactive: SceneObjectInterface | undefined) {
        const scene = SceneObjectInterfaceExtensions.sceneAsScene(interactive);

        console.log("action:Back");
        scene?.navigateBack();
    }
}
