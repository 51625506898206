import { ModuleIntegration } from "./ModuleIntegration";
import { OpenModule } from "./OpenModule";
import { GameCenterModuleIntegration } from "./GameCenterModuleIntegration";

export abstract class ExternalModule {
    abstract name: string;
    abstract html: string;
    
    addIntegrations(openModule: OpenModule) {
        openModule.integrations[0] = new GameCenterModuleIntegration(openModule);
    }
}
