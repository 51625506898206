//https://stackoverflow.com/questions/494143/creating-a-new-dom-element-from-an-html-string-using-built-in-dom-methods-or-pro

export function htmlToElement(html: string): HTMLElement | null {
    let template = document.createElement("template");
    html = html.trim(); // Never return a text node of whitespace as the result
    template.innerHTML = html;
    return template.content.firstChild as HTMLElement | null;
}

export function htmlToElements(html: string) {
    let template = document.createElement("template");
    template.innerHTML = html;
    return template.content.childNodes;
}

export function BorderlessTable() {
    let result = document.createElement("table");
    result = Object.assign(result, { cellspacing: 0, cellpadding: 0 });
    return result;
}

export function addTableRow(table: HTMLElement, elements: (HTMLElement | null)[]) {
    let row = document.createElement("tr");

    elements.forEach((element) => {
        if (element) {
            let col = Object.assign(document.createElement("td"), {});
            // col.style.padding = "2px";
            col.appendChild(element);
            row.appendChild(col);
        }
    });
    table.appendChild(row);
}

export function withBorderlessTableRow(elements: (HTMLElement | null)[]) {
    let result = BorderlessTable();
    addTableRow(result, elements);
    return result;
}

export function hideElementByName(eachName: string) {
    const each = document.getElementById(eachName);
    if (each) {
        hideElement(each);
    }
}

export function showElementByName(eachName: string) {
    const each = document.getElementById(eachName);
    if (each) {
        showElement(each);
    }
}

export function hideElement(each: HTMLElement) {
    if (!each) {
        return;
    }
    each.style.display = "none";
}

export function showElement(each: HTMLElement) {
    if (!each) {
        return;
    }
    each.style.display = "block";
}

export function isElementHidden(each: HTMLElement) {
    return each.style.display === "none";
}
