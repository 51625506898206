import { Action } from "../actions/action";
import { SceneObjectInterface, SceneObjectInterfaceExtensions } from "../SceneObjectInterface";

export class NavigateAction extends Action {
    value: string;
    value_context: string;
    constructor(verb: string, name: string | undefined, json?: any) {
        super(verb, name);
        this.value = json["value"];
        this.value_context = json["value_context"];
    }

    run(interactive: SceneObjectInterface | undefined) {
        console.log("action:Navigate");
        const scene = SceneObjectInterfaceExtensions.sceneAsScene(interactive);

        scene?.navigate(this.value, this.value_context);
    }
}
