export type onValueChanged = (value: any) => void;

export class ValueChangedSubscriptions {
    static addToObjectArrayPropertyByName(
        obj: Record<string, any>,
        name: string,
        item: onValueChanged,
    ) {
        let value = obj[name];

        if (value === undefined) {
            value = obj[name] = new ValueChangedSubscriptions(name);
        } else {
        }

        value.elements.push(item);
    }

    static removeFromObjectArrayPropertyByName(
        obj: Record<string, any>,
        name: string,
        item: onValueChanged,
    ) {
        let value = obj[name];
        value?.removeElement(item);
    }

    static updateValueByName(obj: Record<string, any>, name: string, value: any) {
        let subscription = obj[name];
        subscription?.updateValue(value);
    }

    name: string;
    elements: onValueChanged[];

    constructor(name: string) {
        this.name = name;
        this.elements = [];
    }

    removeElement(element: onValueChanged) {
        let index = this.elements.indexOf(element);
        if (index >= 0) {
            this.elements.splice(index, 1);
        }
    }

    get isEmpty() {
        return this.elements.length === 0;
    }

    updateValue(value: any) {
        for (const each of this.elements) {
            each(value);
        }
    }
}
