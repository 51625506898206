import {
    changeStation,
    getAllRadioStations,
    getDefaultRadioGain,
    getRadio,
} from "../../audio_visual/audio/playlistAPI/Radio";
import { PlaylistJSON } from "../../audio_visual/audio/playlistAPI/types";
import { boundGain } from "../../audio_visual/audio/playlistAPI/utils";
import { Action } from "../actions/action";
import { SceneObjectInterface } from "../SceneObjectInterface";
import { RadioActionValue } from "./types";

export class ChangeRadioStationAction extends Action {
    value: RadioActionValue;
    value_context;

    constructor(verb: string, name?: any, json?: any) {
        super(verb, name);
        this.value = json["value"];
        this.value_context = json["value_context"];
    }

    run(interactive: SceneObjectInterface | undefined) {
        console.log("ChangeRadioStationAction");
        const playlistManager = interactive?.scene.sceneGraph.simulation.audio?.playlistManager;
        const { radio: radioName, gain } = this.value;
        const radio = getRadio(radioName);

        // if radio is off do nothing
        if (!radio.on) {
            return;
        }

        // calculate next station and change to it
        const stations = getAllRadioStations();
        const index = this.getCurrentIndex(radio.station, stations);
        const currentStation = stations[index].name;
        const nextStation = stations[(index + 1) % stations.length].name;
        changeStation(radioName, nextStation);

        // Stop the previous playlist audio associated
        playlistManager?.setActive(currentStation, false); // boolean zen?
        playlistManager?.setPlaylistState({
            id: nextStation,
            gain: boundGain(gain ?? getDefaultRadioGain(radioName)),
            isActive: true,
        });
    }

    private getCurrentIndex(currentStationName: string, stations: PlaylistJSON[]) {
        let idx = 0;
        stations.forEach((station, index) => {
            if (station.name === currentStationName) {
                idx = index;
            }
        });
        return idx;
    }
}
