export class UserInterfaceElement {
    elements: any;
    json: any;
    constructor(elements: any, json: any) {
        this.elements = elements;
        this.json = json;
    }
    addToDOM(): void {}
    value_string(): string | undefined {
        return undefined;
    }
}
