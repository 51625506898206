import { InteractiveCanvas } from "../audio_visual/interactive_canvas.ts";
import * as htmlHelper from "../htmlHelper.ts";
import { Treeview } from "../view/treeview.ts";
import {
    ReadonlyProperty,
    BooleanEditableProperty,
    NumberEditableProperty,
    EditableProperty,
} from "./../view/propertyEditor.ts";
import { WebApplication } from "../webApplication.ts";
import { ValueChangedSubscriptions } from "../ValueChangedSubscriptions.ts";
import { DebugOverlay } from "./DebugOverlay.ts";
import { ConsoleOverlay } from "./ConsoleOverlay.ts";
import { HTMLScreenOverlay } from "./HTMLScreenOverlay.ts";
import { MemoryDebugOverlay } from "./MemoryDebugOverlay.ts";
import { Resources } from "../resources.ts";
import { CustomerAccount } from "../customerAccount.ts";
import { InteractiveEvent } from "../sceneGraph/InteractiveEvent.ts";
import { WebApplicationSettings } from "../WebApplicationSettings.ts";
import { StackLayout } from "../view/stackLayout.ts";
import { InteractiveMouseEvent } from "../MouseEvent.ts";
import { DirectConsoleOverlayLogger } from "./DirectConsoleOverlayLogger.ts";

/**
 *
 * @callback AuthorInterfaceComponentInterface_addAuthorInterfaceElementToTreeview
 * @param {Treeview} treeview
 */

/**
 *
 * @callback AuthorInterfaceComponentInterface_getAuthorInterfaceName
 * @returns {string}
 */

/**
 *
 * @callback AuthorInterfaceComponentInterface_createAuthorInterfaceElement
 * @returns {HTMLElement|undefined}
 */

// /**
//  * @typedef AuthorInterfaceComponentInterface
//  * @property {AuthorInterfaceComponentInterface_addAuthorInterfaceElementToTreeview} [addAuthorInterfaceElementToTreeview]
//  * @property {AuthorInterfaceComponentInterface_createAuthorInterfaceElement} [createAuthorInterfaceElement]
//  * @property {AuthorInterfaceComponentInterface_getAuthorInterfaceName} getAuthorInterfaceName
//  */

export interface AuthorInterfaceComponentInterface {
    addAuthorInterfaceElementToTreeview(treeview: Treeview): void;
    getAuthorInterfaceName(): string;
    createAuthorInterfaceElement(): HTMLElement | undefined;
}

/**
 *
 */
export class AuthorInterface {
    // TODO: this isn't an interface, change name?

    static collectEditablePropertiesFromProperties(
        client_values: Record<string, any>,
        ui: Record<string, any>,
        default_values: Record<string, any>,
        layout: StackLayout,
        change_subscriptions?: Record<string, any>,
    ) {
        for (const property in ui) {
            let editor: EditableProperty | undefined;
            let value: any;
            const get_value = (): any => {
                if (client_values.hasOwnProperty(property)) {
                    return client_values[property];
                } else if (default_values.hasOwnProperty(property)) {
                    return default_values[property];
                }
            };
            value = get_value();
            const value_type = typeof value;
            if (value_type === "boolean") {
                editor = new BooleanEditableProperty();
            }
            if (editor === undefined) {
                const asNumber = Number(value);
                if (!Number.isNaN(asNumber)) {
                    editor = new NumberEditableProperty();
                    if (
                        default_values.hasOwnProperty(property + WebApplicationSettings.MinSuffix)
                    ) {
                        // Handle min suffix
                    }
                    if (
                        default_values.hasOwnProperty(property + WebApplicationSettings.MaxSuffix)
                    ) {
                        // Handle max suffix
                    }
                }
            }
            if (editor === undefined) {
                editor = new ReadonlyProperty();
            }
            if (ui.hasOwnProperty(property)) {
                const ui_properties = ui[property];
                if (ui_properties.hasOwnProperty("isEnabled")) {
                    const isEnabled = ui_properties["isEnabled"];
                    editor.setEnabled(isEnabled);
                }
            }
            const property_interface = Object.assign(editor, {
                name: property,
                getValue: get_value,
                setValue: (v: any) => {
                    client_values[property] = v;
                    if (change_subscriptions) {
                        ValueChangedSubscriptions.updateValueByName(
                            change_subscriptions,
                            property,
                            v,
                        );
                    }
                },
            });
            layout.addAsTableRow(property_interface.getEditorElements());
        }
    }

    icanvas: InteractiveCanvas;
    resources: Resources;
    json: any;
    isActive: boolean;
    account: CustomerAccount;
    nav?: HTMLElement;
    sideNav?: HTMLElement;
    sideNav_treeView: Treeview;
    components: AuthorInterfaceComponentInterface[] = [];
    debug_overlay: DebugOverlay;
    memory_overlay: MemoryDebugOverlay;
    console_overlays: ConsoleOverlay[];

    constructor(resources: Resources, icanvas: InteractiveCanvas, account: CustomerAccount) {
        this.resources = resources;
        this.icanvas = icanvas;
        this.account = account;
        this.console_overlays = [
            new ConsoleOverlay("consoleLog-container"),
            new ConsoleOverlay("consoleLog2-container", false),
        ];

        this.debug_overlay = new DebugOverlay();
        this.memory_overlay = new MemoryDebugOverlay();

        for (const debug_overlay of this.debug_overlays) {
            debug_overlay.initialize(this.application);
        }
    }

    get debug_overlays(): HTMLScreenOverlay[] {
        return [this.debug_overlay, ...this.console_overlays, this.memory_overlay];
    }
    get diagnostics_overlay(): DebugOverlay {
        let r = this.debug_overlay;
        return r;
    }

    get console_overlay(): ConsoleOverlay {
        let r = this.console_overlays[0];
        return r;
    }

    newDirectLogger(consoleIndex: number = 0) {
        let logger = this.console_overlays[consoleIndex];
        return new DirectConsoleOverlayLogger(logger);
    }

    onActivity() {
        for (const debug_overlay of this.debug_overlays) {
            debug_overlay.onActivity();
        }
    }

    get application() {
        return this.account.application;
    }

    get isVisible() {
        let result = this.json.isVisible;

        if (result === undefined) {
            result = false;
        }
        return result;
    }

    set isVisible(v) {
        this.json.isVisible = v;
        this.updateActive();
    }

    initialize() {
        let storage_name = this.stroageItemName();
        let from_storage = this.resources.getLocalStorageJsonItem(storage_name);
        this.json = from_storage;
    }

    start() {
        this.updateActive();
    }

    updateActive() {
        if (this.isVisible && !this.isActive) {
            this.addToDOM();
        } else if (!this.isVisible && this.isActive) {
            this.removeFromDOM();
        }
    }

    deactivate() {
        this.json.isDeactivate = true;
        this.json.isDeactivateVisible = this.isVisible;
        if (this.isVisible) {
            this.isVisible = false;
        }
    }

    reactivate() {
        if (this.json.isDeactivate) {
            this.json.isDeactivate = false;
            if (this.json.isDeactivateVisible) {
                this.isVisible = true;
            }
        }
    }

    addCloseButton(div: HTMLElement) {
        const closeButton = document.createElement("button");
        closeButton.textContent = "Close";

        closeButton.addEventListener("click", () => {
            this.close();
        });

        div.appendChild(closeButton);
    }

    /**
     * Adds a logout button to the given div.
     *
     * @param div the div to add the logout button to.
     */
    addLogoutButton(div: HTMLElement) {
        const logoutButton = document.createElement("button");
        logoutButton.textContent = "Logout";
        logoutButton.style.marginLeft = "1rem";

        logoutButton.addEventListener("click", () => {
            // this.icanvas.state.auth.auth0Client.logout({ returnTo: window.location.origin });
            localStorage.removeItem("idToken");
            localStorage.removeItem("refreshToken");
            window.location.reload();
        });
        if (this.icanvas.state.auth.user) {
            div.appendChild(logoutButton);
        }
    }

    createSidebar() {
        let result = document.createElement("div");
        result.style.padding = "4px";

        this.addCloseButton(result);
        this.addLogoutButton(result);

        result.classList.add("sidenav");
        this.sideNav_treeView = new Treeview(true);

        this.components.forEach((element) => {
            if (element.addAuthorInterfaceElementToTreeview) {
                element.addAuthorInterfaceElementToTreeview(this.sideNav_treeView);
            } else {
                if (element.createAuthorInterfaceElement) {
                    let elm = element.createAuthorInterfaceElement();
                    if (elm) {
                        this.sideNav_treeView.addItem(element.getAuthorInterfaceName(), elm, false);
                    }
                }
            }
        });

        result.appendChild(this.sideNav_treeView.element);
        return result;
    }

    createNavbar() {
        let result = document.createElement("nav");
        result.classList.add("navbar");
        result.classList.add("bg-body-tertiary");

        let form = document.createElement("form");
        form.classList.add("container-fluid");
        form.classList.add("justify-content-start");

        result.appendChild(form);

        return result;
    }

    addToDOM() {
        let viewport = document.getElementById(InteractiveCanvas.viewportId);

        this.nav = this.createNavbar();
        this.sideNav = this.createSidebar();

        if (!viewport || !viewport.parentElement) {
            return;
        }

        viewport.parentElement.insertBefore(this.nav, viewport);

        viewport.style.marginLeft = "448px";
        viewport.parentElement.insertBefore(this.sideNav, viewport);

        //this.sideNav_accordianGroup.addCallbacks();
        //for (const debug_overlay of this.debug_overlays) {
        //  debug_overlay.set_x_offset(viewport.style.marginLeft);
        //}
        HTMLScreenOverlay.set_debug_table_x_offset(
            viewport.style.marginLeft ? parseFloat(viewport.style.marginLeft) : 0,
        );
        this.isActive = true;
    }

    removeFromDOM() {
        this.nav?.remove();
        this.nav = undefined;

        this.sideNav?.remove();
        this.sideNav = undefined;

        let viewport = document.getElementById(InteractiveCanvas.viewportId);
        if (viewport) {
            viewport.style.marginLeft = "0px";
        }

        this.isActive = false;
        //for (const debug_overlay of this.debug_overlays) {
        //  debug_overlay.set_x_offset(0);
        //}
        HTMLScreenOverlay.set_debug_table_x_offset(0);
    }

    drawFrame(icanvas: InteractiveCanvas) {
        for (const debug_overlay of this.debug_overlays) {
            debug_overlay.update();
        }
    }

    mousedown(icanvas: InteractiveCanvas, e: InteractiveMouseEvent) {}

    mouseup(icanvas: InteractiveCanvas, e: InteractiveMouseEvent) {}

    mousemove(icanvas: InteractiveCanvas, e: InteractiveMouseEvent) {}

    keydown(icanvas: InteractiveCanvas, ievent: InteractiveEvent) {
        if (ievent.e_keyboardevent?.key === "2") {
            this.isVisible = !this.isVisible;
            this.updateActive();
            icanvas.updateCanvasSize();
            icanvas.invaidate();
        }
    }

    close() {
        this.isVisible = false;
        this.updateActive();
        this.icanvas.updateCanvasSize();
        this.icanvas.invaidate();
    }

    stroageItemName() {
        return this.resources.combineJsonResourceNameFromArray([
            this.application.name,
            this.account.name,
            "author.storage",
        ]);
    }

    saveState() {
        this.resources.setLocalStorageItemAsJson(this.stroageItemName(), this.json);
    }

    shutdown() {
        this.saveState();
    }

    onTouchTap(e: any) {}

    onTouchPan(e: any) {}

    onTouchSwipe(e: any) {}

    onTouchDistance(e: any) {}

    onTouchRotate(e: any) {}

    onTouchGesture(e: any) {}

    addAuthorInterfaceElementToTreeview(treeview: Treeview): void {}

    onCanvasResized(): void {}

    update(): void {}

    activate(event: InteractiveEvent): void {}

    file_dropped(e: DragEvent, files?: any[]): void {}

    drag_file(e: DragEvent, files?: any[]): void {}

    keyup(icanvas: InteractiveCanvas, ievent: InteractiveEvent): void {}
}
