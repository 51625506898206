import { InteractiveCanvas } from "../audio_visual/interactive_canvas";
import { DragDropEvent } from "./DragDropEvent";
import { GettingReadyCallbackCollector } from "./GettingReadyCallbackCollector";
import { Interactive } from "./Interactive";
import { InteractiveEvent } from "./InteractiveEvent";
import { InteractiveLayer } from "./InteractiveLayer";
let c2 = require("c2.js");

export class Interactives {
    static preview_name = "preview";
    parent: InteractiveLayer;
    json: any;
    isStarted = false;
    interactives: Interactive[] = [];

    constructor(parent: InteractiveLayer, json: any) {
        this.parent = parent;
        this.json = json;
    }

    initializeFromJson() {
        if (this.json) {
            for (let each in this.json) {
                let i = this.newInteractive(this.json[each]);

                this.interactives.push(i);
            }
        }
    }

    get scene() {
        return this.parent.scene;
    }

    changeSelected(toSelect: Interactive) {
        let selected = this.getSelected();

        if (selected === toSelect) {
            return;
        }

        selected?.setSelected(false);
        toSelect?.setSelected(true);
    }

    selectNone() {
        let selected = this.getSelected();
        selected?.setSelected(false);
    }

    selectDefaultOrFirst() {
        let selectable = this.interactives.filter((item) => item.isSelectable());
        if (selectable.length === 0) {
            return;
        }

        for (let each of selectable) {
            if (each.json.isDefaultSelection) {
                this.changeSelected(each);
                return;
            }
        }
        this.changeSelected(selectable[0]);
    }

    getSelected() {
        for (let each of this.interactives) {
            if (each.isSelected) {
                return each;
            }
        }
        return undefined;
    }

    addInteractive(name: string, json: any, isTemporary = false) {
        let i = this.newInteractive(json, isTemporary);
        i.name = name;
        this.interactives.push(i);

        if (this.isStarted) {
            i.start();
        }
        return i;
    }
    removeInteractive(name: string, isTemporary = false) {
        let temp = this.interactives.filter((each) => !(each.name === name));
        this.interactives = temp;
    }
    removeTemporary() {
        let temp = this.interactives.filter((each) => !(each.isTemporary === true));
        this.interactives = temp;
    }

    findInteractiveByName(name: string, isTemporary: boolean) {
        for (let i in this.interactives) {
            let each = this.interactives[i];
            if (each.area?.json.name === name && each.isTemporary === isTemporary) {
                return each;
            }
        }
    }

    getResourcePath() {
        return this.json.resourcePath || this.parent.getResourcePath();
    }

    newInteractive(json: any, isTemporary = false) {
        let i = new Interactive(this, json);
        i.isTemporary = isTemporary;
        i.initializeFromJson();

        return i;
    }

    add(interactive: Interactive) {
        this.interactives.push(interactive);
    }

    isVisualContentReady() {
        for (const each of this.interactives) {
            if (!each.isVisualContentReady()) {
                return false;
            }
        }
        return true;
    }
    startVisualContentGetReady(gettingReadyCallbackCollector: GettingReadyCallbackCollector) {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            i.startVisualContentGetReady(gettingReadyCallbackCollector);
        }
    }
    start() {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            i.start();
        }
        this.isStarted = true;
    }

    stop() {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            i.stop();
        }
    }

    drawFrame(icanvas: InteractiveCanvas) {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            i.drawFrame(icanvas);
        }
    }
    isIEventOnInteractiveArea(ievent: InteractiveEvent) {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            if (i.isIEventOnInteractiveArea(ievent)) {
                return true;
            }
        }
        return false;
    }
    mousedown(ievent: InteractiveEvent) {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            i.mousedown(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }
    }
    mouseup(ievent: InteractiveEvent) {
        for (let each in this.interactives) {
            let i = this.interactives[each];

            i.mouseup(ievent);

            if (ievent.isStopPropagation) {
                break;
            }
        }
    }
    mousemove(ievent: InteractiveEvent) {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            i.mousemove(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }

        let selectable = this.interactives.filter((item) => item.isSelectable());
        let foundSelection = false;

        for (const each of selectable) {
            let isHover = each.area?.isIEventOnInteractiveArea(ievent);

            if (isHover) {
                this.changeSelected(each);
                foundSelection = true;
                break;
                // console.log("i-h-t");
            } else {
                //  console.log("i-h-f");
            }
        }
        if (!foundSelection) {
            this.selectNone();
        }
    }
    keydown(ievent: InteractiveEvent) {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            i.keydown(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }
    }
    keyup(ievent: InteractiveEvent) {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            i.keyup(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }
    }
    onTouchTap(ievent: InteractiveEvent) {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            i.onTouchTap(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }
    }
    onTouchPan(ievent: InteractiveEvent) {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            i.onTouchPan(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }
    }
    onTouchSwipe(ievent: InteractiveEvent) {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            i.onTouchSwipe(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }
    }
    onTouchDistance(ievent: InteractiveEvent) {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            i.onTouchDistance(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }
    }
    onTouchRotate(ievent: InteractiveEvent) {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            i.onTouchRotate(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }
    }
    onTouchGesture(ievent: InteractiveEvent) {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            i.onTouchGesture(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }
    }
    activate(ievent: InteractiveEvent) {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            i.activate(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }
    }
    file_dropped(ievent: DragDropEvent) {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            i.file_dropped(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }
        this.try_add_file_as_scene(ievent);

        ievent.isStopPropagation = true;
    }
    drag_file(ievent: DragDropEvent) {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            i.drag_file(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }

        this.preview_add_file_as_scene(ievent);
        ievent.isStopPropagation = true;
    }

    preview_add_file_as_scene(ievent: DragDropEvent) {
        if (ievent.files === undefined) return;

        let file = ievent.files[0].file;

        // var name = Interactive.getNameFromFilename(file);

        // if (this.scene.findInteractiveByName(name)) {
        //   console.warn("duplicate interactive name");
        //   return;
        // }

        let e = ievent.e;

        let found = this.findInteractiveByName(Interactives.preview_name, true);

        if (!found) {
            let json = {
                name: Interactives.preview_name,
                "interactive.area": {
                    "geometry.rectangle": [0.0, 0.0, 0.05, 0.05],
                },
            };
            found = this.addInteractive(Interactives.preview_name, json, true);
        }

        // disabled until mouse xy can be retrieved
        //let relative = this.parent.scene.convertEventWithPointToRelativePoint(e);
        // found.setAreaReletivePosition(relative);
    }

    try_add_file_as_scene(ievent: DragDropEvent) {
        let found = this.removeInteractive(Interactives.preview_name);
        ievent.icanvas?.invalidate();
    }
}
