import { DraggedFile, InteractiveCanvas } from "../audio_visual/interactive_canvas";
import { InteractiveEvent } from "./InteractiveEvent";

export class DragDropEvent extends InteractiveEvent {
    files: DraggedFile[];
    isDrag: boolean;

    constructor(icanvas: InteractiveCanvas | undefined, e: DragEvent, files: DraggedFile[]) {
        super(icanvas, e);
        this.files = files;
        this.isDrag = false;
    }
}
