/**
 * a range is a range between two numbers that can be on either end inclusive or exclusive
 */
export class Range {
    start: number;
    end: number;
    inclusiveStart: boolean;
    inclusiveEnd: boolean;

    constructor(start: number, end: number, inclusiveStart = true, inclusiveEnd = true) {
        this.start = start;
        this.end = end;
        this.inclusiveStart = inclusiveStart;
        this.inclusiveEnd = inclusiveEnd;
    }
    toString() {
        return `[${this.start}, ${this.end}]`;
    }
    getDuration() {
        return this.end - this.start;
    }
    shiftBy(number: number) {
        this.add(number);
        return this;
    }
    extendBy(number: number) {
        this.end += number;
        return this;
    }
    contractBy(number: number) {
        this.end -= number;
        return this;
    }
    shrinkBy(number: number) {
        const half = number / 2;
        this.start += half;
        this.end -= half;
        return this;
    }
    contains(number: number, tolerance = 0) {
        const startCheck = this.inclusiveStart
            ? number >= this.start - tolerance
            : number > this.start - tolerance;
        const endCheck = this.inclusiveEnd
            ? number <= this.end + tolerance
            : number < this.end + tolerance;
        return startCheck && endCheck;
    }
    overlaps(other: Range, tolerance = 0) {
        return (
            this.contains(other.start, tolerance) ||
            this.contains(other.end, tolerance) ||
            other.contains(this.start, tolerance) ||
            other.contains(this.end, tolerance)
        );
    }
    isAdjacent(other: Range, tolerance = 0) {
        return (
            (Math.abs(this.end - other.start) <= tolerance &&
                (!this.inclusiveEnd || !other.inclusiveStart)) ||
            (Math.abs(this.start - other.end) <= tolerance &&
                (!this.inclusiveStart || !other.inclusiveEnd))
        );
    }
    overlapsWith(other: Range) {
        return this.start < other.end && this.end > other.start;
    }
    isWithin(other: Range) {
        return this.start >= other.start && this.end <= other.end;
    }
    containsRange(other: Range, tolerance = 0) {
        return this.contains(other.start, tolerance) && this.contains(other.end, tolerance);
    }
    add(number: number) {
        this.start += number;
        this.end += number;
        return this;
    }
    subtract(number: number) {
        this.start -= number;
        this.end -= number;
        return this;
    }
    moveAdjacentTo(other: Range, offset = 0) {
        const distance = other.end - this.start + offset;
        this.start += distance;
        this.end += distance;
        return this;
    }

    intersects(other: Range) {
        return this.start < other.end && this.end > other.start;
    }

    isOverlappingOrAdjacent(other: Range) {
        return this.overlapsWith(other) || this.isAdjacent(other);
    }
    splitAt(number: number) {
        if (number <= this.start || number >= this.end) {
            throw new Error("Split point must be within the range.");
        }
        return [new Range(this.start, number), new Range(number, this.end)];
    }
    mergeWith(other: Range) {
        if (!this.intersects(other)) {
            throw new Error("Ranges do not intersect and cannot be merged.");
        }
        return new Range(Math.min(this.start, other.start), Math.max(this.end, other.end));
    }
    countContainedLengths(length: number) {
        if (length <= 0) return 0;
        return Math.floor((this.end - this.start) / length);
    }
    getContainedLengths(length: number) {
        const count = this.countContainedLengths(length);
        const lengths = [];
        for (let i = 0; i < count; i++) {
            lengths.push(new Range(this.start + i * length, this.start + (i + 1) * length));
        }
        return lengths;
    }
    getRemainingLength(length: number) {
        const totalLength = this.end - this.start;
        return totalLength % length;
    }
    canFitLength(length: number) {
        return this.end - this.start >= length;
    }
    splitByLength(length: number) {
        const ranges = this.getContainedLengths(length);
        const remainingLength = this.getRemainingLength(length);
        if (remainingLength > 0) {
            ranges.push(new Range(this.end - remainingLength, this.end));
        }
        return ranges;
    }
}
