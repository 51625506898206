import { AudioVisualScriptResource } from "../../audio_visual/audio-visual-script/AudioVisualScriptResource";
import { SceneGraphAudioVisualState } from "./SceneGraphAudioVisualState";

export class SceneGraphAudioVisualStateSet {
    json: any;
    av_script_resource: AudioVisualScriptResource;

    constructor(json: any, av_script_resource: AudioVisualScriptResource) {
        this.json = json;
        this.av_script_resource = av_script_resource;
    }

    initializeFromJson() {}

    getNextState(
        state: SceneGraphAudioVisualState | undefined,
    ): SceneGraphAudioVisualState | undefined {
        let found = false;
        for (let each in this.json) {
            if (each === state?.name) {
                found = true;
                continue;
            }
            if (found) {
                return this.getNewStateObjectByName(each);
            }
        }

        if (found) {
            for (let each in this.json) {
                return this.getNewStateObjectByName(each);
            }
        }
        return undefined;
    }

    getDefaultState(): SceneGraphAudioVisualState | undefined {
        for (let each in this.json) {
            const item = this.json[each];
            if (item.isDefault) {
                const obj = new SceneGraphAudioVisualState(item, this, each);
                obj.initializeFromJson();
                return obj;
            }
        }
    }

    getScripts() {
        return this.av_script_resource.scripts;
    }

    getNewStateObjectByName(propertyName: string): SceneGraphAudioVisualState | undefined {
        if (this.json[propertyName]) {
            const data = this.json[propertyName];
            const obj = new SceneGraphAudioVisualState(data, this, propertyName);
            obj.initializeFromJson();
            return obj;
        } else {
            // Handle cases where the property might not exist
            console.warn(`Property '${propertyName}' not found.`);
            return undefined; // Or throw an error if appropriate
        }
    }
}
