import { FileResourceRequest } from "./resources/FileResourceRequest.ts";
import { AudioResource } from "./audio_visual/audio/resources/audioResource.ts";
import { WebApplicationSettings } from "./WebApplicationSettings.ts";
import { S3ContentBucket } from "./S3ContentBucket.ts";
import { WebApplicationServer } from "./webApplicationServer";
import { WebApplication } from "./webApplication";
import { ResourceInterface } from "./audio_visual/visual/resources/resourceInterface";
import IDisposable from "./interfaces/IDisposable";

export class Resources {
    static jsonExtension = ".json";

    static static_combineJsonResourceNames(args: string[], append_extension = true) {
        let result = "";
        for (let each in args) {
            let each_value = args[each];
            if (each_value) {
                result += each_value;
                result += ".";
            }
        }
        if (append_extension) {
            result += "json";
        } else {
            result = result.slice(0, -1);
        }

        return result;
    }
    // TODO: remove combineJsonResourceName and only use combineJsonResourceNameFromArray
    static static_combineJsonResourceName() {
        let result = "";
        for (let each in arguments) {
            let each_value = arguments[each];
            if (each_value) {
                result += each_value;
                result += ".";
            }
        }

        result += "json";

        return result;
    }
    // TODO: remove non-static combineJsonResourceName methods
    static static_combineJsonResourceNameFromArray(resources: string[]) {
        let result = "";
        for (let each in resources) {
            let each_value = resources[each];
            if (each_value) {
                result += each_value;
                result += ".";
            }
        }

        result += "json";

        return result;
    }
    static static_getLocalStorageItem(name: string) {
        let found = window.localStorage.getItem(name);
        // if (!found) {
        //   found = ifNotFound;
        // }
        return found;
    }
    static static_getLocalStorageJsonItem(name: string, createIfNotFound = true): any {
        let item = this.static_getLocalStorageItem(name);

        if (item) {
            if (item === '"{}"') {
                item = "{}";
            }

            let json = JSON.parse(item);
            return json;
        } else {
            let json_item = {};

            if (createIfNotFound) {
                this.static_setLocalStorageItemAsJson(name, json_item, true);
                return this.static_getLocalStorageJsonItem(name, false);
            }
            return json_item;
        }
    }
    static static_deleteLocalStorageItem(name: string) {
        let item = window.localStorage.getItem(name);

        if (!item) {
            return;
        }

        window.localStorage.removeItem(name);
    }
    static static_setLocalStorageItem(name: string, value: string, createIfNotFound = true) {
        if (!createIfNotFound && window.localStorage.getItem(name) === null) {
            return;
        }
        window.localStorage.setItem(name, value);
    }
    static static_setLocalStorageItemAsJson(
        name: string,
        object_value: any,
        createIfNotFound = true,
    ) {
        let value = JSON.stringify(object_value);
        this.static_setLocalStorageItem(name, value, createIfNotFound);
    }
    server: WebApplicationServer;
    fetched_json: any = {};
    application: WebApplication;

    constructor(application: WebApplication) {
        this.application = application;
    }

    async initialize() {
        let scene_graphs_promise = this.getOrFetchJsonPromise(
            this.getNavigationGraphPathFromName(""),
        );
        let audio_playlists_promise = this.getOrFetchJsonPromise(
            this.getJsonPath("audio.playlists"),
        );

        let fetchSceneGraphs: Promise<any>[];
        scene_graphs_promise = scene_graphs_promise.then(async (result) => {
            result.navigationGraphs?.forEach((element: any) => {
                let path = this.getNavigationGraphPathFromName(element);

                if (fetchSceneGraphs === undefined) {
                    fetchSceneGraphs = [];
                }

                fetchSceneGraphs.push(this.getOrFetchJsonPromise(path));
            });

            if (fetchSceneGraphs) {
                await Promise.all(fetchSceneGraphs);
            }
        });
        let file_manifest_path = this.getFileManifestJsonPath();
        let file_manifest_promise = this.getOrFetchJsonPromise(file_manifest_path);

        const [scene_graph, file_manifest, audio_playlist] = await Promise.all([
            scene_graphs_promise,
            file_manifest_promise,
            audio_playlists_promise,
        ]);

        this.fetched_json["playlist"] = audio_playlist;
    }

    getDataFilePathByNameAndExtension(name: string, ext: string) {
        return `./assets/data/${name}${ext}`;
    }

    async getOrFetchSceneGraphJson(name: string) {
        let path = this.getNavigationGraphPathFromName(name);

        return await this.getOrFetchJson(path);
    }

    getOrFetchJsonPromise(path: string) {
        let found = this.fetched_json[path];

        if (!found) {
            let p = this.getJsonPromise(path);
            p = p.then((result) => {
                if (result.status === 404) {
                    console.error(`resources:404 error ${path}`);
                }
                this.fetched_json[path] = result;
                return result;
            });
            return p;
        }

        return Promise.resolve(found);
    }

    isJsonFetched(path: string) {
        return this.fetched_json[path] !== undefined;
    }

    getFetchedJson(path: string) {
        return this.fetched_json[path];
    }

    async getOrFetchJson(path: string) {
        let found = this.fetched_json[path];

        if (!found) {
            found = await this.getJsonAsync(path);

            if (found === 404) {
                console.error(`resources:404 error ${path}`);
            }
            this.fetched_json[path] = found;
        }
        return found;
    }
    // getPlaylist;

    getNavigationGraphPathFromName(name: string) {
        let filename = name;
        if (filename) {
            filename += ".";
        }
        filename += "navigation.graph";
        let path = this.getJsonPath(filename);
        return path;
    }

    getFileManifestJsonPath() {
        let result = this.getJsonPath("file_manifest");

        //if (S3ContentBucket.isEnabled(this.application)) {
        //  result = S3ContentBucket.applyURLPrefix(this.application,result);
        //}

        return result;
    }

    getJsonPath(name: string) {
        return `./assets/data/${name}.json`;
    }

    getJsonPromise(path: string) {
        console.log(`resources:fetch ${path}`);
        let p = fetch(path);

        p = p.then(async (result) => {
            return await result.json();
        });

        return p;
    }

    async getJsonAsync(path: string) {
        console.log(`resources:fetch ${path}`);
        const r = await fetch(path);
        const j = await r.json();

        return j;
    }

    combineJsonResourceNames(args: string[], append_extension = true) {
        return Resources.static_combineJsonResourceNames(args, append_extension);
    }

    combineJsonResourceName() {
        let result = "";
        for (let each in arguments) {
            let each_value = arguments[each];
            if (each_value) {
                result += each_value;
                result += ".";
            }
        }

        result += "json";

        return result;
    }

    combineJsonResourceNameFromArray(resources: string[]) {
        let result = "";
        for (let each in resources) {
            let each_value = resources[each];
            if (each_value) {
                result += each_value;
                result += ".";
            }
        }

        result += "json";

        return result;
    }

    requestMp3AudioResource(request: FileResourceRequest) {
        request.setExtension(".mp3");
        request.setCategoryPath("audio/");
        request.application = this.server.app;
        //let found = this.audios[request.fullpath];

        //if (found) {
        //  return found;
        // }

        request.server_file_cache = this.server.server_file_cache;
        let found = new AudioResource(request);
        //this.audios[request.fullpath] = found;
        return found;
    }

    disposeResource(resource: IDisposable | undefined) {
        resource?.dispose?.();
    }

    requestAudioResource(name: string, path: string, path2: string) {
        let request = new FileResourceRequest(path, name, "", "", path2, true, false, false);
        return this.requestMp3AudioResource(request);

        // var r;

        // if (type == "audio") {
        //   r =
        // }

        // r.type = type;
        // return r;
    }

    getLocalStorageItem(name: string) {
        return Resources.static_getLocalStorageItem(name);
    }

    getLocalStorageJsonItem(name: string, createIfNotFound = true) {
        return Resources.static_getLocalStorageJsonItem(name, createIfNotFound);
    }

    deleteLocalStorageItem(name: string) {
        Resources.static_deleteLocalStorageItem(name);
    }

    setLocalStorageItem(name: string, value: string, createIfNotFound = true) {
        Resources.static_setLocalStorageItem(name, value, createIfNotFound);
    }

    setLocalStorageItemAsJson(name: string, object_value: any, createIfNotFound = true) {
        Resources.static_setLocalStorageItemAsJson(name, object_value, createIfNotFound);
    }
}
