import { RectangleGeometry } from "../../../geometry/RectangleGeometry.ts";
import {
    GettingReadyCallback,
    GettingReadyCallbackCollector,
} from "../../../sceneGraph/GettingReadyCallbackCollector.ts";
import { MediaSourceResourceResolver } from "../resources/MediaSourceResourceResolver.ts";
import { ResourceInterface } from "../resources/resourceInterface.ts";
import { VisualElement } from "../visualElement.ts";
import { MediaSourcePlayer } from "./MediaSourcePlayer.ts";
import { ResourceCanvasElementInterface } from "./ResourceCanvasElementInterface.ts";
import { VideoData } from "../VideoData.ts";

const TAG = "MediaSourcePlayerWrapper";
export class MediaSourcePlayerWrapper implements ResourceCanvasElementInterface {
    buffer_video_element?: HTMLVideoElement;
    buffer_video_index?: number;
    resourceResolver: MediaSourceResourceResolver;
    vis: VisualElement;
    isReady = false;
    isError = false;
    error: string;
    isCanceled = false;
    player: MediaSourcePlayer;
    visual_resource: ResourceInterface;
    geometry: RectangleGeometry;
    videoData: VideoData;

    constructor(vis: VisualElement, resourceResolver: MediaSourceResourceResolver) {
        this.vis = vis;
        this.resourceResolver = resourceResolver;
        //console.info("new:" + TAG + '(' + this.resourceResolver.toSourceURLName() + ')');
    }

    get url_file_info() {
        return this.resourceResolver?.url_file_info;
    }

    /**
     *
     * @param {GettingReadyCallbackCollector} gettingReadyCallbackCollector
     * @throws {error}
     */
    start_loading(gettingReadyCallbackCollector: GettingReadyCallbackCollector) {
        this.buffer_video_index = this.vis.webApplicationState.msePlayer.buffer_video_index;

        this.buffer_video_element = this.vis.webApplicationState.msePlayer.buffer_video_element;

        if (!this.buffer_video_element) {
            throw new Error(
                `missing video buffer: ${this.resourceResolver.toSourceURLName()} could not find VideoElement`,
            );
        }

        this.player = this.vis.webApplicationState.msePlayer.player;
        let self = this;

        const callback = new GettingReadyCallback("readyPlayer");

        const playbackReady = () => {
            return new Promise(async (resolve, reject) => {
                try {
                    const dataReady: any = this.resourceResolver.fetchResource(
                        this.resourceResolver.toSourceURL(),
                    );

                    const video = await dataReady;
                    this.videoData = this.resourceResolver.newVideoDataForVideo(video);
                    if (this.videoData.event_playedOnce) {
                        if (this.videoData.event_playedOnce.function) {
                            this.videoData.onPlayedOnce = this.videoData.event_playedOnce.function;
                        } else {
                            this.videoData.onPlayedOnce = () => {
                                self.vis.obj.runActionJson(this.videoData.event_playedOnce);
                            };
                        }
                    }
                    this.isError = this.resourceResolver.isError;
                    this.error = this.resourceResolver.error;
                    this.isReady = true;

                    resolve("");
                } catch (error) {
                    this.isError = this.resourceResolver.isError;
                    this.error = this.resourceResolver.error;
                    this.isReady = true;

                    console.error(TAG, "failure durring GetPlaybackReady()", error);
                    reject("");
                }
            });
        };
        const promise = playbackReady();
        callback.addPromise(promise);
        gettingReadyCallbackCollector.add(callback);
    }
    start() {
        this.isCanceled = false;
        let loaded = this.isLoadingSuccess;
        if (!loaded) {
            return false;
        }

        //console.log(`playing mse video ${this.videoData.name}`);
        if (this.player.isHidden) {
            this.videoData.isPlayImmediate = true;
        }
        this.player.play(this.videoData).then(() => {
            this.player.tryShow();
        });
        // await setTimeout(() => resolve(""), 500)

        return true;
    }
    on_buffer_play(index: number, buffer: ArrayBuffer) {
        this.vis.resource_displaying(this.resourceResolver, true);
    }
    on_buffer_playing(index: number, buffer: ArrayBuffer) {
        this.vis.resource_displayed(this.resourceResolver, true);
    }
    on_buffer_error(index: number, buffer: ArrayBuffer) {
        this.isError = true;
        this.error = "buffer error";
        this.resourceResolver.notifyError();
        this.vis.onResourceCanvasElementError(this);
    }

    detachFromVideoData() {
        this.videoData.onPlayedOnce = undefined;
    }

    stop(next_resource_canvas_element: ResourceCanvasElementInterface) {
        if (this.resourceResolver.isLoading()) {
            this.resourceResolver.cancelLoading();
            this.isCanceled = true;
        } else {
            this.resourceResolver.stop();
        }
        if (this.videoData) {
            this.detachFromVideoData();
            //console.log(`stop mse video ${this.videoData.name}`);
        }

        if (next_resource_canvas_element?.isPauseMSEOnStart()) {
            this.player.hide();
            this.player.pause();
            //console.log(`pause mse`);
        }

        // this.clearFromBuffer(next_resource_canvas_element);
    }
    isPauseMSEOnStart() {
        return false;
    }

    // clearFromBuffer(next_resource_canvas_element: ResourceCanvasElementInterface) {
    //     if (this.buffer_video_index == undefined) {
    //         return;
    //     }

    //     if (this.vis.icanvas.is_registered_video_buffer_index_as(this, this.buffer_video_index)) {
    //         this.resourceResolver.unapply_event_values_to_resource_element();

    //         this.vis.icanvas.clear_buffer_by_index(this.buffer_video_index);

    //         this.vis.icanvas.unregister_video_buffer_index(this, this.buffer_video_index);
    //     } else {
    //         console.warn(
    //             `video not registered on clear from buffer ${this.resourceResolver.toSourceURLName()}`,
    //         );
    //     }

    //     this.buffer_video_element = undefined;
    //     this.buffer_video_index = undefined;
    // }

    collectCanvasElements(result: any) {}
    setHidden(value: any) {}
    setDrawOrder(value: any) {}
    removeCanvasElement() {}

    get isLoadingSuccess() {
        if (this.resourceResolver === undefined) {
            return false;
        }
        if (this.resourceResolver.isLoaded !== true) {
            return false;
        }
        this.resourceResolver.checkForErrors();
        if (this.resourceResolver.isError) {
            return false;
        }
        return true;
    }
    toSourceURLName() {
        return this.resourceResolver.toSourceURLName();
    }
    toSourceURLNameExt() {
        return this.resourceResolver.resource_request.toURLNameAndExtension();
    }
    toSourceURL() {
        return this.resourceResolver.toSourceURL();
    }
    get url() {
        return this.toSourceURL();
    }
    isLoading() {
        return this.resourceResolver?.isLoading();
    }
    update() {}
    onDrawing() {}
}
