import { SceneGraphAudioVisualStateComponent } from "./SceneGraphAudioVisualStateComponent";

export class SceneGraphAudioVisualStateBaseComponent extends SceneGraphAudioVisualStateComponent {
    static QueueItemType = "component";
    name?: string;

    constructor(json: any, name?: string) {
        super(json);
        this.name = name;
    }

    initializeFromJson(): void {
        super.initializeFromJson();
    }

    get short(): string {
        return this.json.short;
    }

    get medium(): string {
        return this.json.medium;
    }

    get long(): string {
        return this.json.long;
    }
}
