// @ts-check

/* context

https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Using_Web_Audio_API
https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Migrating_from_webkitAudioContext
https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Web_audio_spatialization_basics
https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Best_practices

https://developer.chrome.com/blog/autoplay/#webaudio

*/

import { CustomerAccount } from "../../customerAccount.ts";
import { Resources } from "../../resources.ts";
import { AuthorInterfaceComponentInterface } from "../../sceneAuthorInterface/authorInterface.ts";
import { InteractiveEvent } from "../../sceneGraph/InteractiveEvent.ts";
import { Treeview } from "../../view/treeview.ts";
import { InteractiveCanvas, InteractiveCanvasComponentInterface } from "../interactive_canvas.ts";
import { NullWebAudio } from "./NullWebAudio.ts";
import { AudioPlaylistManager, PlaylistManager } from "./playlistAPI/playlistManager.ts";
import { Audio, WebAudio } from "./webAudio.ts";

export type actionFunction = () => any; // TODO: do we need this? can it be typed better?

export interface WebAudioInterface
    extends InteractiveCanvasComponentInterface,
        AuthorInterfaceComponentInterface {
    player: Audio | NullWebAudio;
    playlistManager: PlaylistManager;
    initialize(): void;
    saveState(): void;
    onStartAudioOnGesture(): void;
    update(): void;
}

export class WebAudioComponent implements WebAudioInterface {
    account: CustomerAccount;
    isWaitingForGesture: boolean;
    onStartAudioOnGesture: actionFunction;
    player: Audio;
    playlistManager = new AudioPlaylistManager();

    constructor(resources: Resources, account: CustomerAccount) {
        this.player = new WebAudio(resources, account);
    }

    initialize() {
        this.player.tryStartAudio();
        this.playlistManager.loadPlaylists();

        if (!this.player.playlistPlayer) {
            return;
        }
        this.playlistManager.registerObserver(this.player?.playlistPlayer);
    }

    saveState() {
        //this.resources.setLocalStorageItemAsJson(this.stroageItemName(), this.json);
    }

    start() {}

    drawFrame(icanvas: InteractiveCanvas) {}

    mousedown(icanvas: InteractiveCanvas, e: InteractiveEvent) {
        this.player.startAudioOnGesture();
    }

    mouseup(icanvas: InteractiveCanvas, e: InteractiveEvent) {}

    mousemove(icanvas: InteractiveCanvas, e: InteractiveEvent) {}

    keydown(icanvas: InteractiveCanvas, ievent: InteractiveEvent) {
        this.player.startAudioOnGesture();
    }

    onTouchTap(e: any) {
        this.player.startAudioOnGesture();
    }

    onTouchPan(e: any) {}

    onTouchSwipe(e: any) {
        this.player.startAudioOnGesture();
    }

    onTouchDistance(e: any) {}

    onTouchRotate(e: any) {}

    onTouchGesture(e: any) {}

    onCanvasResized(): void {}

    update(): void {}

    onActivity(): void {}

    activate(event: InteractiveEvent): void {}

    addAuthorInterfaceElementToTreeview(treeview: Treeview): void {}

    createAuthorInterfaceElement() {
        return undefined;
    }

    file_dropped(e: DragEvent, files?: any[]): void {}

    drag_file(e: DragEvent, files?: any[]): void {}

    keyup(icanvas: InteractiveCanvas, ievent: InteractiveEvent): void {}

    getAuthorInterfaceName() {
        return "";
    }
}
