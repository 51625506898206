import * as htmlHelper from "./../htmlHelper.ts";

export class Treeview {
    static indentAmount = 12;

    static addStyleToListElement(element: HTMLElement) {
        element.style.listStyleType = "none";
        element.style.margin = "0";
        element.style.padding = "0";
    }
    static addIndentStyle(element: HTMLElement, depth: number, isChildItem: boolean) {
        let amount = depth * Treeview.indentAmount;
        if (isChildItem) {
            amount += 20;
        }
        element.style.marginLeft = `${amount}px`;
    }
    root: HTMLElement;
    element: HTMLElement;
    element_json: any;

    constructor(create_empty_element = false) {
        if (create_empty_element) {
            this.buildEmptyElement();
        }
    }

    addListItemParent(
        name: string,
        list_element: HTMLElement,
        depth: number,
        isExpanded = true,
        content?: HTMLElement,
    ) {
        let li = document.createElement("li");

        let label = htmlHelper.htmlToElement(`<span class="caret"></span>`);

        Treeview.addIndentStyle(label as HTMLElement, depth, false);

        if (label) {
            label.addEventListener("click", function (event: Event) {
                const target = event.target as HTMLElement;
                target.parentElement!.querySelector(".nested")!.classList.toggle("active");
                target.classList.toggle("caret-down");
            });

            li.appendChild(label);
        }

        let labelHeader = htmlHelper.htmlToElement(`<span >${name}</span>`);
        if (labelHeader) {
            li.appendChild(labelHeader);
        }

        let ul = document.createElement("ul");

        Treeview.addStyleToListElement(ul);
        ul.classList.add("nested");
        if (isExpanded) {
            ul.classList.add("active");
        }

        if (content) {
            Treeview.addIndentStyle(content, depth, true);
            ul.appendChild(content);
        }

        li.appendChild(ul);
        list_element.appendChild(li);
        return ul;
    }

    addItem(name: string, content?: HTMLElement, isExpanded = true) {
        let item;
        if (this.root) {
            item = this.addListItemParent(name, this.root, 1, isExpanded, content);
        } else {
            item = this.addListItemParent(name, this.element, 0, isExpanded, content);
        }
        if (item.parentElement) {
            item.parentElement.style.marginTop = "24px";
        }
    }
    /**
     *
     * @param {object} json
     * @param {HTMLElement} list_element
     * @param {number} depth
     * @param {number|undefined} expandedToDepth
     */
    populateTreeWithJsonData(
        json: any,
        list_element: HTMLElement,
        depth: number,
        expandedToDepth?: number,
    ) {
        for (const [key, value] of Object.entries(json)) {
            if (value instanceof Object) {
                let obj_item = this.addListItemParent(
                    key,
                    list_element,
                    depth,
                    expandedToDepth !== undefined ? expandedToDepth !== 0 : true,
                );
                this.populateTreeWithJsonData(
                    value,
                    obj_item,
                    depth + 1,
                    expandedToDepth !== undefined ? expandedToDepth - 1 : undefined,
                );
            } else {
                let item = htmlHelper.htmlToElement(`<li>${key} : ${value}</li>`);
                if (item) {
                    Treeview.addIndentStyle(item as HTMLElement, depth, true);
                    list_element.appendChild(item);
                }
            }
        }
    }
    /**
     *
     */
    buildEmptyElement() {
        this.element = document.createElement("ul");
        Treeview.addStyleToListElement(this.element);

        //let root_name =  "data";
        //this.root = this.addListItemParent(root_name, this.element, 0);
        //this.populateTreeWithJsonData(json, root, 1, expandedToDepth);
    }
    /**
     *
     * @param {object} json
     * @param {number|undefined} expandedToDepth
     */
    buildElementFromJson(json: any, expandedToDepth?: number) {
        this.element_json = json;
        this.element = document.createElement("ul");
        Treeview.addStyleToListElement(this.element);

        let root_name = json["name"] || "data";
        this.root = this.addListItemParent(root_name, this.element, 0);
        this.populateTreeWithJsonData(json, this.root, 1, expandedToDepth);
    }
}
