import { ActionWithValue } from "../actions/action";
import { SceneObjectInterface } from "../SceneObjectInterface";

export class NavigateBrowserAction extends ActionWithValue {
    constructor(verb: string, name: string | undefined, json?: any) {
        super(verb, name, json);
    }

    run(interactive: SceneObjectInterface | undefined) {
        console.log("action:NavigateBrowserAction");
        window.location.href = this.value;
    }
}
