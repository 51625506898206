export class UpdateContext {
    static OneSecondMS = 1000;
    static FiveSecondMS = UpdateContext.OneSecondMS * 5;
    static TenSecondMS = UpdateContext.OneSecondMS * 10;
    interval: number;
    start_time: number;
    time: number;
    deltaTime: number;
    isDrawFrame: boolean;
}
