export const AUTH_CONSTANTS = Object.freeze({
    TENANT_URL: "dev-pq2xfncchz7mcccr.us.auth0.com",
    RESPONSE_TYPE: "code",
    CLIENT_ID: "2eX15mFUhPsuLzAZN9nC3vamuaNUXhm3",
    SCOPE: "openid profile email offline_access",
    GRANT_TYPE: "urn:ietf:params:oauth:grant-type:device_code",
    GUEST_REFRESH_TOKEN: "guest",
    MAX_NUM_POLLS: 100,
    CHECK_SESSION_OPTIONS: {
        usePostMessage: true,
        responseType: "token id_token",
        redirectUri: window.location.origin,
        audience: `https://dev-pq2xfncchz7mcccr.us.auth0.com/userinfo`,
        scope: "openid profile email",
    },
});

export const AUTH_PAGE_TYPES = Object.freeze({
    LOGIN_MODAL: "login-modal",
});

export const AUTH_PAGES = Object.freeze({
    LOGIN_PENDING: `<div class="login-page">
        <h1>Sign in to Lakeside Cabin</h1>
        <div class="rows">
            <div>
                <h3>Step 1: Scan the QR code or go to the URL</h3>
                <h4 id="device-url"></h4>
                <h3>Step 2: Enter the code</h3>
                <h4 id="device-code"></h4>
                <h3>Step 3: Sign in on your device</h3>
            </div>
            <canvas id="qr-code"></canvas>
        </div>
        <button id="cancel-button">Cancel</button></div>`,
});

export const QR_CODE_CONSTANTS = Object.freeze({
    STYLES: {
        color: { dark: "#230c01", light: "#d9b996" },
        width: 400,
        margin: 2,
    },
    ERR_FN: function (error: Error | null | undefined) {
        if (error) {
            console.error(error);
        }
    },
});
